import React from "react";

var storyContent = [
	[
		{
			story_id: 1,
			header: 'Wer heilt hat recht',
			content: [
				[{subheader1: 'Ein Ausflug in die Salzburger Regionalmuseen auf den Spuren der Medizingeschichte'}],
				[{extImage: 'I_E.jpg'}],
				[{audio: 'I_E.mp3'}],
				[{
					par: 'Magie? Alchemie? Aberglaube? Glaube, der Berge versetzen kann? Erfahrung? Medizinisches Wissen? Immer schon galt: ' +
						'Wer heilt, hat recht! Begleiten Sie uns in die Salzburger Regionalmuseen auf den Spuren der Medizingeschichte!'
				}],
				[{
					jumpto: {
						refid: 0,
						year: 1680,
						jumptext: 'um 1680',
						item: 246,
					}
				}],
				[{dbImage: '28-bild1639.jpg'}],
				[{caption: 'Herzförmiges Amulett aus Steinbockhorn.'}],
				[{museum: '28'}],
				
				[{audio: 'I_1.mp3'}],
				[{
					par: '<b>Amulette</b>, denen man medizinische Wirksamkeit (oder sogar magische Kräfte!) zuschrieb, wurden oft als Schutz vor ' +
						'oder bei Krankheit am Körper getragen.'
				}],
				[{
					par: 'Von Steinen oder anderen Materialien mit außergewöhnlicher Form oder Farbe oder von Teilen besonderer ' +
						'Tiere versprach man sich Hilfe. So verwendete man etwa Amulette aus Steinbockhorn gegen Schwäche, ' +
						'Herzbeschwerden und Fallsucht, also Epilepsie.'
				}],
				[{
					jumpto: {
						refid: 1,
						year: 1841,
						jumptext: '1841',
						item: 246,
					}
				}],
				[{dbImage: '50-bild197.jpg'}],
				[{caption: 'Votivbild aus der Wallfahrtskirche Zellhof '}],
				[{museum: '50'}],
				[{audio: 'I_2.mp3'}],
				
				[{
					par:
						'In schwierigen Lebenssituationen, bei schweren Unfällen oder langen Krankheiten, wenn oft nur mehr beten half, ' +
						'suchte man Hilfe bei bestimmten Heiligen oder <b>Wallfahrtsorten</b>. Zu diesen verlobte man sich, indem als Dank ' +
						'für die erfolgte Rettung oder Heilung die Stiftung eines <b>Votivbildes</b> oder einer <b>Votivgabe</b> versprochen wurde. ' +
						'Die Aufschrift Ex Voto (lateinisch: aus dem Gelübde) am Votivbild zeigt die Einlösung dieses Versprechens.'
				}],
				[{
					par: '\tHier ein Beispiel: <i>„Ex Voto 1841. Gertrud Ibertspergerin, verlobt sich zur Hl. Mutter Gottes, wegen ihren bössen ' +
						'Fuß (…)“</i>. Wie die Inschrift verrät, litt sie sechs Jahre lang unter großen Schmerzen und wurde durch die Fürbitte ' +
						'der Mutter Gottes schließlich geheilt. In der oberen Bildhälfte ist die junge Frau dargestellt, sie kniet zu Füßen ' +
						'der Madonna. Unten befinden sich einige Knochensplitter.'
				}],
				[{
					jumpto: {
						refid: 2,
						year: 1706,
						jumptext: '1706',
						item: 246,
					}
				}],
				[{dbImage: '18-bild774.jpg'}],
				[{caption: 'Gussform für Wachsvotive'}],
				[{museum: '18'}],
				[{audio: 'I_3.mp3'}],
				
				[{
					par:
						'<b>Wachsvotive</b> wurden im Zuge einer Wallfahrt in den Wallfahrtskirchen niedergelegt, als Bitte um oder Dank für ' +
						'Heilung und Hilfe. Sie symbolisierten verletzte oder erkrankte Gliedmaßen, ganze menschliche Figuren oder ' +
						'verschiedene Haustiere. '
				}],
				[{
					par:
						'Wachsvotive wurden von Wachsziehern und Lebzeltern in eigenen Gussformen, sogenannten Modeln erzeugt. ' +
						'Nur diese Berufsgruppe durfte den teuren Honig und seine Nebenprodukte verarbeiten: Honig war Hauptzutat für ' +
						'Lebkuchen und Met, aus dem Bienenwachs wurden Kerzen, Wachsstöcke und Wachsvotive erzeugt.' +
						''
				}],
				[{
					jumpto: {
						refid: 3,
						year: 1700,
						jumptext: 'um 1700',
						item: 246,
					}
				}],
				
				[{dbImage: '4-bild1019.jpg'}],
				[{caption: 'Breverl'}],
				[{museum: '4'}],
				[{audio: 'I_4.mp3'}],
				
				[{
					par:
						'<b>Breverl</b> dienten als Schutz, Talisman und Heilmittel. Breverl waren vierfach zusammengefaltete Zettel, ' +
						'beklebt mit neun kleinen Kupferstichen, Heiligenbildchen und Segenssprüchen. Hinter dem mittleren Bild ' +
						'befand sich die <b>„Breverlmasse“</b>, die oft mit kleinen Amuletten, religiösen Symbolen, Heilsteinchen, ' +
						'Pflanzenteilen oder ähnlichem bestückt sein konnte. \n' +
						''
				}],
				[{
					par:
						'Ein Breverl war meist kissenartig verschlossen. Es wurde in einem verzierten Behälter aus Stoff oder ' +
						'Leder aufbewahrt und an einem Band um den Hals getragen. Nur in Lebensgefahr durfte es geöffnet werden.'
				}],
				[{
					jumpto: {
						refid: 4,
						year: 1800,
						jumptext: '18. Jahrhundert',
						item: 246,
					}
				}],
				
				[{dbImage: '18-bild766.jpg'}],
				[{caption: 'Spanschachtel mit Breverlmasse'}],
				[{museum: '18'}],
				[{audio: 'I_5.mp3'}],
				
				[{
					par:
						'<b>Breverlmasse</b>, ein kompaktes, dunkelbraunes Gemenge, wurde nach einer geheimen Rezeptur aus bis zu zweihundertfünfzig Zutaten zubereitet. Wie der Name andeutet, diente sie als Füllung für Breverl, wurde aber auch bei unterschiedlichsten Gebrechen zur Einnahme empfohlen.' +
						''
				}],
				[{
					par:
						'In der Barockzeit waren die Tamsweger Kapuzinermönche auf die Herstellung spezialisiert. Die Erzeugung und überregionale Vermarktung dieses Produkts war ein absolutes Erfolgskonzept!'
				}],
				[{
					par:
						'In der Spanschachtel liegen einige braune Klumpen – vermutlich aus Breverlmasse. Am Deckel befindet sich folgende Beschreibung des ' +
						'Inhaltes mit Dosierungsangabe: <i>„Lebenspulver. Dient dem Menschen zur Reinigung des Geblütes, ist gut für Hauptweh, Schlag- und Steckflüsse, auch denen, die mit kurzem Athem und ' +
						'Engbrüstigkeit beladen, sehr nützlich, reinigt einen verschleimt und verstopften Magen, stärket das Herz, ist gut für die Gelb- und Lungensucht, ' +
						'Milzbeschwärung, erhält Lunge und Leber in guter Gesundheit, u. treibt allen Lend- und Nirnstein. Abends und Morgens 2 oder 3 Messerspitzvoll davon ' +
						'trocken eingenommen.“</i>'
				}],
				[{
					jumpto: {
						refid: 5,
						year: 1700,
						jumptext: 'um 1700',
						item: 246,
					}
				}],
				[{dbImage: '4-bild893.jpg'}],
				//				[{dbImage: '4-bild893a.jpg'}],
				[{caption: 'Pestsegen'}],
				[{museum: '4'}],
				[{audio: 'I_6.mp3'}],
				
				[{
					par:
						'Eine besondere Herausforderung waren <b>Epidemien</b>, vor allem die <b>Pest</b>, die Europa seit dem Mittelalter in mehreren ' +
						'Wellen heimsuchte. Mit einem sogenannten <b><i>Pestsegen</i></b> versuchte man sich zu schützen.'
				}],
				[{
					par:
						'Der hier gezeigte Pestsegen ist wie ein überdimensionales Medaillon gestaltet. An der Vorderseite wurden mehrere Medaillen, ' +
						'Kreuzchen und Heiligenattribute um eine zentrale Madonnenfigur angeordnet, eingebettet in Breverlmasse. Auf der Rückseite befindet ' +
						'sich ein eng-spiralförmig geschriebener Text mit verschiedenen Gebeten und Anrufungen.' +
						''
				}],
				[{dbImage: '4-bild894.jpg'}],
				[{caption: 'Pestlöffel'}],
				[{museum: '4'}],
				[{audio: 'I_7.mp3'}],
				
				[{
					par:
						'War jemand an der Pest oder einer anderen schweren Infektionskrankheit erkrankt, so konnte die Kommunion – berührungsfrei ' +
						'und kontaktlos – mit einem <b><i>Pestlöffel</i></b> gereicht werden.' +
						''
				}],
				[{
					par:
						'Unser Pestlöffel mit verlängertem Griff und runder Laffe ist aus verzinntem Metall gefertigt und knapp 30 Zentimeter lang.'
				}],
				[{
					jumpto: {
						refid: 6,
						year: 1800,
						jumptext: 'um 1800',
						item: 246,
					}
				}],
				[{dbImage: '28-bild2286.jpg'}],
				[{caption: 'Baderschere'}],
				[{museum: '28'}],
				[{audio: 'I_8.mp3'}],
				
				
				[{
					par:
						'Seit dem Mittelalter gab es den <b>Beruf des Baders</b>. In fast jedem größeren Ort gab es einen oder mehrere Bader. ' +
						'Die Tätigkeitsbereiche waren breit gefächert und umfassten <b>Zahnbehandlungen</b>, <b>kleinere chirurgische Eingriffe, ' +
						'Schröpfen</b> und <b>Aderlass</b> sowie das (namensgebende) <b>Badewesen</b>.'
				}],
				[{
					par:
						'Die ebenfalls von ihnen betriebenen Badestuben dienten sowohl der Körperpflege, wie Haarschnitt oder Rasur, als auch der Behandlung von Krankheiten.'
				}],
				[{dbImage: '35-bild2508.jpg'}],
				[{caption: 'Schröpfschnepper mit 16 Klingen'}],
				[{museum: '35'}],
				[{audio: 'I_9.mp3'}],
				
				[{
					par:
						'<b>Schröpfen</b> wurde als ausleitendes Therapieverfahren angewendet. Dazu wurde die oberste Hautschicht mit einem meist ' +
						'aus Messing gefertigten <b>Schröpfapparat</b>, dem <b><i>„Schröpfschnepper“</i></b> angeritzt, indem man mit einer Feder vorgespannte, ' +
						'feine, parallele Klingen durch einen Mechanismus auslöste.'
				}],
				[{dbImage: '4-bild902.jpg'}],
				[{caption: 'Mundgeblasenes Schröpfglas'}],
				[{museum: '4'}],
				[{audio: 'I_10.mp3'}],
				
				[{
					par:
						'Hierauf wurden die mit einer Flamme erhitzten <b>Schröpfgläser</b> aufgesetzt. Durch den so entstandenen Unterdruck hafteten sie an der jeweiligen Hautpartie. Damit erfolgte das Ausleiten aller „Schlacken“.'
				}],
				[{dbImage: '33-bild1200.jpg'}],
				[{caption: 'Flierl'}],
				[{museum: '33'}],
				[{audio: 'I_11.mp3'}],
				
				[{
					par:
						'<b>Aderlass</b> diente als therapeutisches Verfahren, um Krankheiten sowie „unreine Säfte“ aus dem Körper auszuleiten. ' +
						'Dazu wurde mittels einer speziellen <b>Klinge</b>, dem <b><i>„Flierl“</i></b> eine Ader geöffnet und eine bestimmte Menge ' +
						'Blut abgelassen.'
				}],
				[{
					par:
						'Anfang des 16. Jahrhunderts empfahl der Salzburger Arzt, Naturphilosoph und Alchemist Paracelsus dringend, dieser Behandlung ' +
						'eine individuelle Diagnostik vorangehen zu lassen.'
				}],
				[{dbImage: '4-bild898.jpg'}],
				[{caption: 'Aderlass-Schüssel'}],
				[{museum: '4'}],
				[{audio: 'I_12.mp3'}],
				
				[{
					par:
						'Das beim Aderlass abgelassene Blut wurde in einer <b>Schüssel</b> aufgefangen und untersucht. Mit der <b><i>Blutschau</i></b> konnten ' +
						'erfahrene Bader und Ärzte Hinweise auf verschiedene Krankheiten finden.'
				}],
				[{dbImage: '28-bild940.jpg'}],
				[{caption: 'Klistier'}],
				[{museum: '28'}],
				[{audio: 'I_13.mp3'}],
				
				
				[{
					par:
						'Für Einläufe, zur Darmreinigung und bei Verstopfung kamen mit warmem Wasser gefüllte <b>Klistiere</b> zum Einsatz.'
				}],
				
				[{dbImage: '4-bild941.jpg'}],
				[{caption: 'Goldegger Rezeptbuch'}],
				[{museum: '4'}],
				[{audio: 'I_14.mp3'}],
				
				[{
					par:
						'Es ist anzunehmen, dass Bader ihre Erfahrungen auch niederschrieben. Ein besonderes, handgeschriebenes <b>Rezeptbuch</b> befindet ' +
						'sich im Pongauer Heimatmuseum Schloss Goldegg. Darin sind Rezepturen gegen verschiedene Krankheiten aufgezeichnet.'
				}],
				[{
					par:
						'Auch Pflanzen mit magischen Kräften, wie der <b><i>Alraun</i></b>, werden ausführlich beschrieben. Zudem finden sich Angaben, ' +
						'bei welchem Kaufmann in Salzburg die entsprechenden Zutaten erhältlich sind.'
				}],
				[{
					jumpto: {
						refid: 7,
						year: 1557,
						jumptext: '1557',
						item: 246,
					}
				}],
				
				[{dbImage: '27-bild1490.jpg'}],
				[{caption: '„Kreuterbuch“ von Adam Lonitzer 1557'}],
				[{museum: '27'}],
				[{audio: 'I_15.mp3'}],
				
				
				[{
					par:
						'Auch Fachliteratur in gedruckter Form hat sich in den Salzburger Regionalmuseen erhalten, wie beispielsweise dieses ' +
						'<i><b>„Kreuterbuch“</b></i> von Adam Lonitzer, erschienen in Frankfurt am Main 1557. Reich illustriert sind darin die wichtigsten damals bekannten Heil- und Nutzpflanzen beschrieben, deren Vorkommen, Wirksamkeit und Verarbeitung -  ein beeindruckendes Werk am Beginn der naturwissenschaftlichen Forschung! '
				}],
				[{
					par:
						'Aus dem Berufsbild des Baders entwickelten sich letztlich sowohl allgemeinmedizinische als auch verschiedene fachärztliche Richtungen. Seit dem 18. Jahrhundert gewann die universitäre Ausbildung immer mehr an Bedeutung.'
				}],
				[{
					jumpto: {
						refid: 8,
						year: 1810,
						jumptext: 'nach 1810',
						item: 246,
					}
				}],
				
				[{dbImage: '28-bild62.jpg'}],
				[{caption: '„Scene aus den Tyroler Kriegen“, kolorierte Lithographie'}],
				[{museum: '28'}],
				[{audio: 'I_16.mp3'}],
				
				
				[{
					par:
						'Die <b>Chirurgie</b> stellte immer schon eine eigene Disziplin dar. Vor allem auf Kriegsschauplätzen fand der ' +
						'sogenannte <b><i>„Feldscher“</i></b> ein weites Betätigungsfeld. Besonders seit dem Dreißigjährigen Krieg konnte das Ausbrennen von ' +
						'Wunden, Einrenken oder Amputieren von Gliedmaßen, Entfernen von Geschoßen und dergleichen ausgiebig geübt werden. Der Beruf basierte ' +
						'ausschließlich auf Erfahrungswerten.'
				}],
				[{
					jumpto: {
						refid: 9,
						year: 1849,
						jumptext: '1849',
						item: 246,
					}
				}],
				[{dbImage: '19-bild1562.jpg'}],
				[{caption: 'Vorlesungsmitschrift'}],
				[{museum: '19'}],
				[{audio: 'I_17.mp3'}],
				
				[{
					par:
						'Seit Gründung der <b>Salzburger Universität</b> 1622 gab es Bestrebungen, auch eine medizinische Fakultät einzurichten. ' +
						'Erst 1803, nach dem Ende des geistlichen Fürstentums, konnte dies verwirklicht werden. Nach Schließung der Universität unter bayerischer ' +
						'Herrschaft erfolgte 1811 die Umwandlung in eine <i>„Landärztliche Schule“</i> nach bayerischer Norm. Salzburg kam 1816 endgültig zu Österreich, ' +
						'1818 wurde die Schule in eine <b><i>„Niedere medizinisch-chirurgische Lehranstalt“</i></b> umgewandelt, zur Ausbildung von Wundärzten und Hebammen.'
				}],
				[{
					par:
						'Das Museum Gererhof in Annaberg verwahrt diese Mitschrift der Vorlesungen über Augenheilkunde aus dem Jahr 1849.'
				}],
				[{
					jumpto: {
						refid: 10,
						year: 1833,
						jumptext: '1833',
						item: 246,
					}
				}],
				
				[{dbImage: '50-bild633.jpg'}],
				[{caption: 'Abschlussurkunde für Maria Haug'}],
				[{museum: '50'}],
				[{audio: 'I_18.mp3'}],
				
				[{
					par:
						'Das Studium erstreckte sich über drei Jahrgänge mit verschiedenen ein- und weiterführenden Lehrveranstaltungen: Physik, Chemie, Botanik, Anatomie, Physiologie, Pathologie, Arzneimittellehre, Rezeptierkunst, Diätetik, Geburtshilfe, Operationslehre und Augenheilkunde, ergänzt von praktischen Übungen. Danach musste ein mehrmonatiges Praktikum absolviert werden. Nun erst erfolgte die Zulassung zur Abschlussprüfung. Die Auflösung der medizinischen Lehranstalt wurde 1871 beschlossen, die letzten Rigorosen konnten noch 1875 abgehalten werden.'
				}],
				[{
					par:
						'Die hier gezeigte Urkunde wurde 1833 an Maria Haug zum Abschluss ihrer Hebammen-Ausbildung verliehen. Maria Haug war die Urgroßmutter von Burghard Breitner, von dem gleich die Rede sein wird. Maria war mit dem Mattseer Chyrurgus und Arzt Anton Haug verheiratet und arbeitete als Hebamme. Im Winter 1869 starb sie am Weg zu einer "Kindbetterin".'
				}],
				[{
					jumpto: {
						refid: 11,
						year: 1908,
						jumptext: '1908',
						item: 246,
					}
				}],
				[{dbImage: '50-bild631.jpg'}],
				[{caption: 'Promotionsurkunde für Burghard Breitner'}],
				[{museum: '50'}],
				[{audio: 'I_19.mp3'}],
				
				[{
					par:
						'Der gebürtige Mattseer <b>Burghard Breitner</b> schwankte zunächst zwischen den Berufen Schriftsteller oder Arzt. ' +
						'Schließlich studierte er Medizin und promovierte 1908 in Wien <i>„Sub auspiciis Imperatoris“</i>, also im <i>Beisein des Kaisers</i>. '
				}],
				[{
					par:
						'Seine Ausbildung zum Chirurgen begann er bei Anton von Eiselsberg. Im Ersten Weltkrieg geriet Breitner bald in russische Kriegsgefangenschaft ' +
						'und kam in ein sibirisches Lager nördlich von Wladiwostok. Dort wirkte er sechs Jahre lang als Lagerarzt und wurde als <i>„Engel von Sibirien“</i> ' +
						'bezeichnet. '
				}],
				[{
					par:
						'Nach seiner Heimkehr konnte er bei von Eiselsberg weiterarbeiten und sich bei ihm habilitieren. 1932 wurde er Vorstand der Chirurgischen Universitätsklinik Innsbruck und fungierte in den 1950ern als Rektor der Universität. Er verfasste zahlreiche wissenschaftliche Arbeiten und etliche einschlägige Lehrbücher für Studierende. Er genoss internationale Anerkennung.'
				}],
				[{
					par:
						'Breitner wurde 1951 als Kandidat für die Bundespräsidenten-Wahl aufgestellt, erzielte jedoch nur einen geringen Prozentsatz der Wählerstimmen.'
				}],
				[{
					jumpto: {
						refid: 12,
						year: 1970,
						jumptext: 'Mitte 20. Jahrhundert',
						item: 246,
					}
				}],
				
				[{dbImage: '36-bild1478.jpg'}],
				[{caption: 'Chirurgische Pinzette'}],
				[{museum: '36'}],
				[{audio: 'I_20.mp3'}],
				
				
				[{
					par:
						'Wie sahen nun chirurgische Geräte zu Burghard Breitners Zeiten aus und wie wurden sie eingesetzt?'
				}],
				[{
					par:
						'Die <b>Chirurgische Pinzette</b> diente zum Halten der Wundränder bei der Versorgung.'
				}],
				[{dbImage: '36-bild1589.jpg'}],
				[{caption: 'Steriler Behälter für Operationsnadeln'}],
				[{museum: '36'}],
				[{audio: 'I_21.mp3'}],
				
				
				[{
					par:
						'Im <b>sterilen Behälter</b> wurden Operationsnadeln in Carbol aufbewahrt.'
				}],
				[{dbImage: '36-bild1538.jpg'}],
				[{caption: 'Wundklammern'}],
				[{museum: '36'}],
				[{audio: 'I_22.mp3'}],
				
				[{
					par:
						'<b>Wundklammern System Petz</b>: Diese russische Erfindung aus den 1940ern zum Verschließen von Wundrändern erwies sich als überaus praktisch und setzte sich bald europaweit durch. Die Wundklammern wurden mit Hilfe eines Klammergerätes angebracht.'
				}],
				[{dbImage: '36-bild1555.jpg'}],
				[{caption: 'Scharfer Löffel'}],
				[{museum: '36'}],
				[{audio: 'I_23.mp3'}],
				
				[{
					par:
						'Der <b>Scharfe Löffel</b> hat einen scharf geschliffenen Rand zum Ausschaben von Wunden oder Reinigen von Belägen.'
				}],
				[{
					jumpto: {
						refid: 13,
						year: 1930,
						jumptext: '1930er Jahre',
						item: 246,
					}
				}],
				
				[{dbImage: '36-bild1548.jpg'}],
				[{caption: 'Wundhäkchen'}],
				[{museum: '36'}],
				[{audio: 'I_24.mp3'}],
				
				[{
					par:
						'Wundhäkchen zum Halten kleiner Wunden bei der Versorgung.'
				}],
				[{dbImage: '14-bild3780.jpg'}],
				[{caption: 'Wundhaken'}],
				[{museum: '14'}],
				[{audio: 'I_25.mp3'}],
				
				[{
					par:
						'Mit dem <b>Wundhaken</b> konnte die Wunde bei einer größeren Operation offengehalten werden.'
				}],
				[{dbImage: '14-bild3781.jpg'}],
				[{caption: 'Gefäßhaken'}],
				[{museum: '14'}],
				[{audio: 'I_26.mp3'}],
				
				[{
					par:
						'Der <b>Gefäßhaken</b> mit kleiner Öse an der gebogenen Spitze diente zum Unterbinden von Gefäßen.'
				}],
				[{dbImage: '14-bild3790.jpg'}],
				[{caption: 'Punktionsnadel'}],
				[{museum: '14'}],
				[{audio: 'I_27.mp3'}],
				
				[{
					par:
						'Die <b>Punktionsnadel</b> wurde zum Punktieren und – mit aufgesetztem Ansatz – zum Abziehen des Sekrets bei Abszessen verwendet.'
				}],
				[{
					par:
						'Vor allem in kleineren Spitälern in ländlichen Gegenden wurden solche oder so ähnliche Geräte noch bis in die 1980er Jahre verwendet!'
				}],
				[{
					jumpto: {
						refid: 14,
						year: 1900,
						jumptext: '19. Jahrhundert',
						item: 246,
					}
				}],
				
				[{dbImage: '35-bild1569.jpg'}],
				[{caption: 'Apothekenschrank'}],
				[{museum: '35'}],
				[{audio: 'I_28.mp3'}],
				
				[{
					par:
						'Werfen wir noch einen Blick in die <b>Apotheken</b>. Apotheker stellten ihre Arzneimittel oft selbst her, verkauften aber auch Heilpflanzen und Gewürze sowie nicht nur zur Heilkunde verwendete Substanzen, beispielsweise zur Herstellung von Schießpulver.'
				}],
				[{
					par:
						'Mit dem Aufschwung der Naturwissenschaften im 17. und 18. Jahrhundert – vor allem der Chemie – setzte die pharmazeutisch-chemische Forschung ein, die schließlich zur Wegbereiterin der pharmazeutischen Industrie wurde. '
				}],
				[{
					par:
						'Seit der Wende zum 20. Jahrhundert verkaufen Apotheken fast ausschließlich industriell produzierte Medikamente und sehen ihre Hauptaufgabe in der Beratung ihrer Kundinnen und Kunden.'
				}],
				[{
					jumpto: {
						refid: 15,
						year: 1960,
						jumptext: '1960er Jahre',
						item: 246,
					}
				}],
				[{dbImage: '47-bild266.jpg'}],
				[{caption: 'Professor Otto Stöber´s Neydhartinger Moor Trink-Kur'}],
				[{museum: '47'}],
				[{audio: 'I_29.mp3'}],
				
				[{
					par:
						'Diese Packung <b>Neydhartinger Moor-Trinkkur</b>, empfohlen bei Magen- und Darmbeschwerden sowie Sodbrennen und Eisenmangel, hat sich im Museum Torf-Glas-Ziegel in Bürmoos erhalten.'
				}],
				[{
					jumpto: {
						refid: 16,
						year: 1950,
						jumptext: 'um 1950',
						item: 246,
					}
				}],
				
				[{dbImage: '28-bild2728.jpg'}],
				[{caption: 'Hühneraugentinktur'}],
				[{museum: '28'}],
				[{audio: 'I_30.mp3'}],
				
				[{
					par:
						'Lästigen Hühneraugen konnte mit dieser <b>Hühneraugen-Tinktur</b> zu Leibe gerückt werden. Gefunden im Museum Burg Golling.'
				}],
				[{
					jumpto: {
						refid: 17,
						year: 1700,
						jumptext: 'um 1700',
						item: 246,
					}
				}],
				
				[{dbImage: '4-bild976.jpg'}],
				[{caption: 'Reiseapotheke'}],
				[{museum: '4'}],
				[{audio: 'I_31.mp3'}],
				
				[{
					par:
						'Um auch auf Reisen gegen alle Eventualitäten gewappnet zu sein, gab es <b>Reiseapotheken</b>. Meist als kleine Truhen oder aufklappbare Kästchen gefertigt, oft kunstvoll gestaltet, beinhalteten sie in ihren zahlreichen Fächern und Laden verschiedenste Heilmittel und Substanzen in Fläschchen, Tiegeln und Näpfchen.'
				}],
				[{
					jumpto: {
						refid: 18,
						year: 1850,
						jumptext: 'um 1850',
						item: 246,
					}
				}],
				
				[{dbImage: '32-bild649.jpg'}],
				[{caption: 'Homöopathische Haus- (oder Reise-?) Apotheke'}],
				[{museum: '32'}],
				[{audio: 'I_32.mp3'}],
				
				[{
					par:
						'Um 1800 entdeckte der deutsche Arzt Samuel Hahnemann die Homöopathie als ganzheitliche Heilmethode nach dem „Ähnlichkeitsprinzip“. ' +
						'Er schuf damit zudem eine sanftere Alternative zu den damals noch gebräuchlichen, teils recht drastischen Therapien wie Aderlass, ' +
						'der Gabe von Brechmitteln oder dem Verschreiben quecksilber- oder arsenhaltiger Präparate.'
				}],
				[{
					par:
						'Die <b>Homöopathische Haus- (oder Reise-?) Apotheke</b>, eine Blechschatulle mit 27 Fächern, ist mit kleinen, jeweils beschrifteten ' +
						'Glasphiolen befüllt. Diese Rarität befindet sich im Museum in Bramberg.'
				}],
				[{
					jumpto: {
						refid: 19,
						year: 1829,
						jumptext: '1829',
						item: 246,
					}
				}],
				
				[{dbImage: '32-bild664.jpg'}],
				[{caption: 'Impfzeugnis'}],
				[{museum: '32'}],
				[{audio: 'I_33.mp3'}],
				
				
				[{
					par:
						'Zu guter Letzt…'
				}],
				[{
					par:
						'Die erste Schutzimpfung gegen eine Infektionskrankheit war die <b>Kuhpockenimpfung</b>, als deren Entdecker der englische Landarzt ' +
						'Edward Jenner gilt. Er wusste, dass Kuhpocken beim Menschen nur leichte Infektionen verursachen, zugleich aber gegen die gefährlichen ' +
						'echten Pocken, die <i>„Blattern“</i> immunisieren.'
				}],
				[{
					par:
						'1796 führte er seine erste, erfolgreiche Impfung mit dem <i>„Vakzin“</i> (lateinisch: <i>vacca</i> – Kuh) durch. Die Bevölkerung war lange Zeit skeptisch und es ' +
						'bedurfte großer Mühen, bis ganz Europa durchgeimpft war. Laut WHO gelten die echten Pocken seit 1980 als ausgerottet.'
				}],
				[{
					par:
						'(Hemma Ebner, 2022)'
				}],
			
			],
		}
	],
	[
		{
			story_id: 2,
			header: 'Was Steine erzählen ...',
			content:
				[
					[{extImage: 'II_E.jpg'}],
					[{audio: 'II_E.mp3'}],
					[{
						par: 'Vielleicht kennen Sie das: an einem besonderen Ort, vielleicht bei einem historischen Bauwerk, taucht der Gedanke auf: ' +
							'Was mag hier alles geschehen sein? Wenn doch die Steine sprechen könnten! Lassen wir sie zu Wort kommen! Begleiten Sie uns in die Salzburger Regionalmuseen und hören wir, was Steine erzählen…'
					}],
					[{
						jumpto: {
							refid: 0,
							year: 1,
							jumptext: '1. Jahrhundert',
							item: 246,
						}
					}],
					[{dbImage: '35-bild412.jpg'}],
					[{caption: 'Römischer Meilenstein.'}],
					[{museum: '35'}],
					
					[{audio: 'II_1.mp3'}],
					[{
						par: 'Als Meilenstein entlang der römischen Reichsstraße zwischen Virunum und Iuvavum habe ich viel gesehen und erlebt. ' +
							'Bevor ich nach dem Fall des römischen Reiches in Vergessenheit geraten bin und lange Zeit später beim Anichhof in Altenmarkt gefunden wurde, ' +
							'habe ich viele Kuriere, Soldaten und Reisende gesehen, die sich auf dem Weg zur nächsten Siedlung oder auch Raststation befunden haben. '
					}],
					[{
						par: 'Mein Platz war am Straßenrand, denn meine Funktion war es, die Entfernung zum nächsten Ort anzuzeigen. ' +
							'Oft hörte ich Stimmen aus den Kutschen oder von vorbeigehenden Reisenden, die sich schon auf ihr Ziel oder die nächste Raststation freuten, ' +
							'um endlich eine vernünftige Mahlzeit oder ein Bad zu nehmen. Manche galoppierten in Windeseile an mir vorbei – ich vermute, dass dies Kuriere waren, ' +
							'die gerade dabei waren, eine wichtige Nachricht zu übermitteln.'
					}],
					[{
						par: 'Als die römische Herrschaft in Noricum ihr Ende genommen hat, sind die Straßen stetig verfallen und verloren ihren einstigen Glanz.'
					}],
					[{
						jumpto: {
							refid: 1,
							year: 200,
							jumptext: '2. Jahrhundert',
							item: 246,
						}
					}],
					[{dbImage: '12-bild850.jpg'}],
					[{caption: 'Römerstein'}],
					[{museum: '12'}],
					[{audio: 'II_2.mp3'}],
					
					[{
						par:
							'Meine Existenz als Grabstein verbinde ich sowohl mit negativen, aber auch positiven Gefühlen. Einerseits bedeutet mein ' +
							'Bestehen, dass eine Person verstorben ist… andererseits trage ich dazu bei, dass diese Person nicht in Vergessenheit gerät.'
					}],
					[{
						par: 'Ich stamme aus einer Zeit, in der das Land Salzburg – und damit Elsbethen – zur römischen Provinz Noricum gehörte. Ich wurde gefertigt, ' +
							'um an den Sklaven Dacus und seine Familie zu erinnern, der hier am Gutshof des Herrn Coponius Lucianus tätig war und dort mit seiner Frau ' +
							'Matrona lebte. Es könnte sein, dass ich damals auf dem Friedhof dieses Gutshofes, welcher vermutlich beim heutigen ' +
							'Schloss Goldenstein lag, platziert wurde.'
					}],
					[{
						par: 'Über Dacus weiß ich, dass er ursprünglich aus Dakien, im heutigen Rumänien, stammte und nach den Dakerkriegen als Gefangener nach Noricum gelangte. ' +
							'Ich glaube, Dacus hat sich an sein neues Umfeld angepasst und die lateinische Sprache erlernt. Heute erinnere ich im Museum zum Pulvermacher ' +
							'in Elsbethen an Dacus und seine Familie.'
					}],
					
					[{
						jumpto: {
							refid: 2,
							year: 1,
							jumptext: '1. Jahrhundert',
							item: 246,
						}
					}],
					[{dbImage: '15-bild648.jpg'}],
					[{caption: 'Kopf einer Mithras-Statue'}],
					[{museum: '15'}],
					[{audio: 'II_3.mp3'}],
					
					[{
						par:
							'Bevor ich in einzelne Teile zerbrochen bin, war ich eine Mithrasstatue und befand mich in einem sogenannten Mithräum, ' +
							'einem Tempel, in dem der Gott Mithras verehrt wurde.'
					}],
					[{
						par:
							'Der Tempel stand in einem Ort namens Immurium. Er befindet sich heute im Lungau, beim Schloss Moosham in der Gemeinde Unternberg. ' +
							'Zu Römerzeiten entstand hier eine Siedlung mit mehreren Gebäuden, wie Wohnhäusern und einer Herberge. Immurium war nämlich ein wichtiger ' +
							'Verkehrsknotenpunkt auf der Strecke von Virunum nach Iuvavum.'
					}],
					[{
						par:
							'Viele Reisende, wie zum Beispiel Soldaten, Beamte, Kuriere, aber auch Sklaven legten in Immurium eine Rast ein. ' +
							'Sicher besuchten viele von ihnen das Mithräum. Dank ihnen verbreitete sich mein Kult schließlich auch in den nördlicheren Provinzen. ' +
							'Im Gegensatz zum Christentum, welches zu meiner Entstehungszeit noch nicht in diese Region vorgedrungen war, war der Mithras-Kult im ' +
							'gesamten römischen Reich bekannt. Mit der Etablierung des Christentums geriet der Mithras-Kult, und somit auch mein Tempel und ich selbst, ' +
							'leider in Vergessenheit.'
					}],
					
					
					[{
						jumpto: {
							refid: 3,
							year: 1300,
							jumptext: 'um 1300',
							item: 246,
						}
					}],
					[{dbImage: '28-bild841.jpg'}],
					[{caption: 'Felsbild „Jagdfries“'}],
					[{museum: '28'}],
					[{audio: 'II_4.mp3'}],
					
					[{
						par:
							'Lange ist es her… Immer wieder ist er damals zu mir gekommen, in den hintersten Winkel des Tales, dort, wo sich die schroffen, ' +
							'schattenfeuchten, dunklen Felswände über dem Dickicht erheben. Durch die steile Rinne mit dem abschüssigen Geröll kletterte er empor und ' +
							'arbeitete unermüdlich weiter. Mit einem kleinen Messer ritzte er Linien in meine Oberfläche.'
					}],
					[{
						par:
							'Lange konnte ich mir keinen Reim darauf machen. Erst als er sein Werk vollendet hatte, konnte ich es erkennen: Ein Steinbock, von einem ' +
							'Hund verfolgt, ist mit den Vorderläufen in einem geschlitzten Dreieck verfangen. Ein Jäger tritt auf ihn zu. Nein, er erlegt ihn nicht, ' +
							'ihm dient der lange Jagdspieß nur als Stütze. Aber die Brust des Mannes und das Blatt, das Herz des Steinbocks verbindet eine starke Linie. ' +
							'Hinter dem Jäger ist ein Pferdchen zu erkennen und einige andere Formen. '
					}],
					[{
						par:
							'Über Jahrhunderte hütete ich diesen Schatz, erst 1968 durfte ihn ein junger Mann entdecken. Vielleicht bin ich ja ein wenig mitverantwortlich, ' +
							'dass mein Finder bald darauf das Museum Burg Golling gründete? Bis heute verbindet uns eine tiefe Freundschaft. Für meine Geschichte hat er mir ' +
							'seine Stimme geliehen. '
					}],
					
					
					[{
						jumpto: {
							refid: 4,
							year: 1400,
							jumptext: '15. Jahrhundert',
							item: 246,
						}
					}],
					[{dbImage: '9-bild170.jpg'}],
					[{caption: 'Mühlstein einer Goldmühle'}],
					[{museum: '9'}],
					[{audio: 'II_5.mp3'}],
					
					[{
						par:
							'Für euch Menschen ist Gold scheinbar ein besonders wichtiges Gut. Ihr zahlt damit, ihr trägt es als Schmuck an eurem Körper … mir ist zu ' +
							'Ohren gekommen, dass manche es sogar in Form von Blattgold verzehren!'
					}],
					[{
						par:
							'Auch ich hegte einst eine ganz spezielle Beziehung zum Gold, allerdings eine deutlich andere als ihr. Ich kam im späten Mittelalter mit dem noch ' +
							'unverarbeiteten Golderz in Berührung. Als Teil einer Goldmühle hatte ich als Mühlstein nämlich eine ganz zentrale Funktion. Die Aufgabe von mir und einem ' +
							'zweiten Mühlstein war es, das gepochte, also bereits stark zerkleinerte Golderz, ganz fein zu mahlen, bevor es im nächsten Schritt gewaschen wurde. '
					}],
					[{
						par:
							'Leider ging der Mahlprozess nicht spurlos an mir vorbei, denn das Gold ist meist an hartes Gestein gebunden. Durch das ständige Mahlen entstanden ' +
							'an mir kreisförmige Ausschleifungen, die dazu führten, dass ich unbrauchbar wurde und man mich wegwarf.'
					}],
					[{
						par:
							'Durch Überschwemmungen im Gasteiner Nassfeld war ich bis vor nicht allzu langer Zeit unter dem Geröll vergraben. Ein Forschungsteam hat mich geborgen ' +
							'und ins Museum nach Böckstein gebracht, wo ich an den Goldbergbau im Gasteinertal erinnere.'
					}],
					
					
					[{
						jumpto: {
							refid: 5,
							year: 1500,
							jumptext: 'um 1500',
							item: 246,
						}
					}],
					[{dbImage: '17-bild1207.jpg'}],
					[{caption: 'Bildstock'}],
					[{museum: '17'}],
					[{audio: 'II_6.mp3'}],
					
					[{
						par:
							'Dunkel erinnere ich mich an meinen Entstehungsort, das flache tropische Urmeer, mit dem Sand und den Sedimenten, den Korallenriffen, Schwämmen, ' +
							'Seelilienwäldern und Ammoniten. Das Meer verging und ich wurde zu Stein verdichtet, emporgehoben und mit den neu entstandenen Alpen weit nach Norden ' +
							'verschoben.'
					}],
					[{
						par:
							'Über Jahrmillionen gehörte ich zu den sogenannten Adneter Schichten. Von uns Gesteinen gibt es unterschiedliche Sorten und Färbungen, vom Beige ' +
							'über Grau zum dunklen Rotbraun, wobei die kräftigen Rottöne überwiegen. Zu letzteren gehöre auch ich, ein echter Adneter Rotscheck! Auf meine ' +
							'weiße Zeichnung bin ich übrigens besonders stolz!'
					}],
					[{
						par:
							'Im späten Mittelalter wurde unsereins gern für Steinmetz- oder Bildhauerarbeiten verwendet. Während meine Geschwister in die weite Welt ' +
							'hinauszogen und Grabmäler berühmter Leute schmückten, oder als zierlich gedrehte Säulen ihren Dienst versahen, wurde aus mir ein mächtiger ' +
							'Bildstock auf einem starken Pfeiler.'
					}],
					[{
						par:
							'Jahrhundertelang stand ich an einer Straße an der Lammer. Unzählige Leute sind an mir vorbeigekommen. Manche verweilten bei mir, sprachen ' +
							'ein kurzes Gebet oder hielten Rast, bevor sie weiterzogen. Dann kam das große Hochwasser. Aus dem smaragdfarbenen Gewässer wurde ein ' +
							'schlammiger, reißender Strom, der alles mit sich riss – auch mich! Und so blieb ich im Flussbett liegen. Die Lammer ging unbarmherzig ' +
							'mit mir um, rollte, rundete und zerkratzte mich, schliff meine Inschrift und die schönen Ornamente ab. Erst 2010 fand mich ein Kunstkenner ' +
							'nahe Scheffau und gab mir einen neuen Platz im Museum in Kuchl.'
					}],
					
					
					[{
						jumpto: {
							refid: 6,
							year: 1507,
							jumptext: '1507',
							item: 246,
						}
					}],
					[{dbImage: '28-bild930.jpg'}],
					[{caption: 'Epitaph Wolfgang Panichner zu Wolkersdorf'}],
					[{museum: '28'}],
					[{audio: 'II_7.mp3'}],
					
					[{
						par:
							'Seid gegrüßt! Ich bin ein Epitaph. Das klingt feierlich, nicht wahr? Tatsächlich erinnere ich als Gedenkstein an einen Ritter, Wolfgang Panichner ' +
							'zu Wolkersdorf. Mein Original befindet sich in der Pfarrkirche in Kuchl, wo mein Herr begraben liegt. '
					}],
					[{
						par:
							'Mein Abguss hat im Burghof von Golling seinen Platz. Vielleicht fragt ihr euch: zweimal der gleiche Stein, an zwei verschiedenen Orten? ' +
							'Nun, mein Ritter war da wie dort bedeutsam! Und dem Gründer des Museum Burg Golling war wichtig, die Erinnerung präsent und den Gedenkstein auch ' +
							'an Ort und Stelle zu haben.'
					}],
					[{
						par:
							'Die Panichner stammten ursprünglich aus dem Rupertigau und hatten im Gebiet um Kuchl reiche Besitzungen. Als Lehensmänner, später als ' +
							'Ministerialen dienten sie den Salzburger Erzbischöfen. Einige von ihnen bekleideten das Amt des Pflegers in Golling, also des obersten ' +
							'Beamten des Pfleggerichts, so auch Wolfgang Panichner, der zugleich Hofmarschall zu Salzburg war.  '
					}],
					[{
						par:
							'Das Epitaph wurde 1507 von Hans Valkenauer, einem der besten Bildhauer der Spätgotik geschaffen. Meisterhaft – handwerklich und künstlerisch – ' +
							'ist der feierliche, rote Adneter Marmor bearbeitet. Unter einer Kreuzigungsgruppe kniet Ritter Wolfgang Panichner zu Wolkersdorf, ' +
							'in voller Rüstung. Den reich verzierten Helm hat er abgelegt. Dieser ruht auf seinem Wappenschild. Am Kopf trägt er eine Tuchhaube. ' +
							'Seine Hände sind betend gefaltet und der Blick ist emporgerichtet. Ob er tatsächlich so oder so ähnlich ausgesehen hat? Es bleibt mein Geheimnis…'
					}],
					
					
					[{
						jumpto: {
							refid: 7,
							year: 1572,
							jumptext: '1572',
							item: 246,
						}
					}],
					[{dbImage: '28-bild927.jpg'}],
					[{caption: 'Wappenstein'}],
					[{museum: '28'}],
					[{audio: 'II_8.mp3'}],
					
					[{
						par:
							'Seit ältesten Zeiten führte ein wichtiger Handelsweg über den Pass Lueg und durch die enge, von der wilden Salzach durchflossene ' +
							'Schlucht zwischen Tennen- und Hagengebirge. Doch im Mittelalter verfiel die Straße, schließlich bestand nur noch ein schmaler Saumpfad.'
					}],
					[{
						par:
							'In den 1560er Jahren, zur Blütezeit des Pongauer Bergbaues, versuchte man mit großem Aufwand, die Salzach in diesem Bereich schiffbar zu ' +
							'machen, um Erze und andere Güter leichter nach Norden transportieren zu können. Ein Ding der Unmöglichkeit! Stattdessen ließ unser Fürst ' +
							'und Erzbischof, Johann Jakob Khuen von Belasi, die Straße zwischen Werfen und Golling wieder ausbauen. Zur Erinnerung daran wurde ich ' +
							'dort aufgestellt.'
					}],
					[{
						par:
							'Zudem gemahne ich an das verheerende Hochwasser im Frühsommer 1572, bei dem die Salzach am Pass Lueg die Straße überflutete, und das sogar ' +
							'noch in der Stadt Salzburg etliche Häuser und die Brücke zerstörte! Überhaupt waren diese Jahre geprägt von unterschiedlichen Katastrophen. ' +
							'Hochwässer, Stürme, Brände, Teuerung, Hunger und sogar die Pest suchten unser Land heim. Schließlich erschien noch ein ' +
							'„erschröcklicher Cometstern“, der uns alle in Angst und Schrecken versetzte!'
					}],
					[{
						par:
							'Wie es mit mir weiterging? Ich sah die Jahrhunderte kommen und gehen, irgendwann ratterten die ersten Automobile über den Pass Lueg. ' +
							'In den 1960ern, bei der Neutrassierung der Bundesstraße, wurde ich aus meiner Nische genommen. Jahrzehntelang lag ich bei Wind und Wetter ' +
							'im Freien, Inschrift und Wappenschilde verwitterten. Glücklicherweise wurde ich schließlich gerettet. Im Torbogen der Burg Golling habe ' +
							'ich eine neue Heimat gefunden.'
					}],
					
					
					[{
						jumpto: {
							refid: 8,
							year: 1580,
							jumptext: 'um 1580',
							item: 246,
						}
					}],
					[{dbImage: '13-bild736.jpg'}],
					[{caption: 'Hexenstein'}],
					[{museum: '13'}],
					[{audio: 'II_9.mp3'}],
					
					[{
						par:
							'Meine Erinnerungen an meine Entstehungszeit sind verschwommen. Vielleicht stamme ich sogar aus grauer Vorzeit. Worüber ich allerdings ' +
							'Bescheid weiß, ist, dass ich zu Zeiten aufgestellt worden bin, als im Pinzgau – hier wurde ich nämlich gefunden – aber auch andernorts, ' +
							'Menschen verfolgt, verurteilt und hingerichtet worden sind, die mutmaßlich der Hexerei beschuldigt wurden. Die Rechtsprechung dieser Zeit ' +
							'oblag dem „Pfleger“, der einem Pfleggericht bzw. einem Verwaltungsbezirk zugeteilt war. Direkt neben mir wurden die Gerichtsurteile verkündet.'
					}],
					[{
						par:
							'Ich erinnere an einen besonders tragischen Fall. Er spielte sich im Pfleg- und Landgericht Mittersill ab, zu dem der gesamte Oberpinzgau ' +
							'bis nach Niedernsill zählte. Ich soll an jenen Gerichtsfall erinnern, in dem der Bramberger Pfarrer und die Pfarrersköchin der Wettermacherei ' +
							'bezichtigt wurden. Leider hat die Geschichte für die beiden kein gutes Ende genommen, denn sie starben 1575 auf dem Scheiterhaufen ' +
							'am Galgenrain.'
					}],
					
					
					[{
						jumpto: {
							refid: 9,
							year: 1752,
							jumptext: '1752',
							item: 246,
						}
					}],
					[{dbImage: '28-bild1031.jpg'}],
					[{caption: 'Weihwasserbecken'}],
					[{museum: '28'}],
					[{audio: 'II_10.mp3'}],
					
					[{
						par:
							'Halt! Hier bin ich! Fast hättet Ihr mich übersehen! Ja genau, hier, rechts, das Weihwasserbecken! Ich weiß, Ihr müsst aufpassen, ' +
							'dass Ihr nicht über die beiden Stufen fällt, die in die Burgkapelle hinunterführen. Trotzdem. Seht mich nur genau an! Wie harmonisch ' +
							'sich das Farbenspiel meines Adneter Marmors in Rot und Ocker verbindet, in welcher Meisterschaft und Vollendung ich zur Muschelform ' +
							'geschliffen wurde, sogar an der Innenseite! Freilich, das muss ich leider zugeben, hat das Weihwasser im Laufe der Jahrhunderte eine dicke ' +
							'Sinterschicht in meinem Inneren gebildet. Aber ich sehe es als Spuren, die die Zeit an mir hinterlassen hat.'
					}],
					[{
						par:
							'Die Zeit, ach, wo ist sie hingekommen? Fast ist es mir wie gestern, dass ich in der Burgkapelle eingebaut wurde. Mein Auftraggeber, der ' +
							'Hochlöbliche und Hochwohlgeborene Herr Pfleger Franz Ferdinand von Pichl hat anno 1752 nicht nur meine Anfertigung, sondern auch gleich ' +
							'die ganze neue Ausstattung der Burgkapelle gestiftet. Als oberster Beamter des Pfleggerichts Golling hatte er hier, auf der Burg, seinen ' +
							'Amtssitz und wohnte da auch mit seiner ganzen Familie.'
					}],
					[{
						par:
							'Von der früheren Einrichtung blieb übrigens nur der Altartisch aus rotem Adneter Stein. Alles andere wurde nach dem modernen Gusto ' +
							'im Rokokostil mit viel Weiß und Gold gestaltet. Vom Altarbild lächelt nun die Hl. Familie, vor ihr kniet der Kirchenpatron, der Hl. Florian, ' +
							'als Schutzherr gegen Feuer und Brandgefahren. Im Hintergrund ist meine Burg zu sehen. Einige heilige Kaiser und Könige flankieren den Altar.'
					}],
					[{
						par:
							'Am Abend, wenn die Letzten die Burg verlassen haben, wenn es still wird und sich dämmrig der Abend über meine Burgkapelle herniedersenkt, ' +
							'plaudern wir über alte Zeiten und vergangene Tage.'
					}],
					
					
					[{
						jumpto: {
							refid: 10,
							year: 1890,
							jumptext: 'um 1890',
							item: 246,
						}
					}],
					[{dbImage: '56-bild326.jpg'}],
					[{caption: 'Ehemalige Kanzel-Stiege aus der Pfarrkirche Adnet'}],
					[{museum: '56'}],
					[{audio: 'II_11.mp3'}],
					
					[{
						par:
							'Darf ich mich vorstellen? Ich bin die Stiege. Heute stehe ich im Außenbereich, im kleinen Skulpturenpark beim Gemeindeamt in Adnet. ' +
							'Im gleichen Gebäude befindet sich übrigens auch das Marmormuseum, zu dem ich jetzt gehöre. Praktisch und vorausschauend denkende Leute ' +
							'haben mir eine Glasscheibe und ein Schmiedeeisengeländer anmontiert, damit niemand auf die Idee kommt, auf mich raufzuklettern und runterzufallen. ' +
							'Denn heutzutage ende ich nach sechs Stufen – im Nichts.'
					}],
					[{
						par:
							'Früher war ich fast doppelt so hoch und stand in der altehrwürdigen Pfarrkirche zum Hl. Stephanus. Mein Beruf, ja meine Berufung war, zur Kanzel ' +
							'hinaufzuführen. Oh, ich könnte euch vieles erzählen, von gewichtigen Pfarrern und wortgewaltigen Predigten! Uralt bin ich dennoch nicht, erst so um ' +
							'1890 bin ich entstanden. Ach so, werdet ihr sagen, Neugotisch, und womöglich verächtlich ein wenig die Nase rümpfen? Lasst euch nicht täuschen. ' +
							'Seht mich nur genau an! In mir steckt jahrhundertealte Steinmetzkunst! Als elegante Spindeltreppe schwinge ich mich empor: Meine Stufen sind exakt ' +
							'um eine achteckige Mittelsäule angeordnet und meine glatt behauene Unterseite zeigt eine sphärische Spirale.'
					}],
					[{
						par:
							'Rund achtzig Jahre lang habe ich meinen Dienst in der Kirche versehen und dabei die Zeitläufe der jüngeren Geschichte miterlebt. 1970 dann, bei ' +
							'der großen Kirchenrenovierung, wurde ich abmontiert. Kein Priester predigt heute mehr von der Kanzel.'
					}],
					
					
					[{
						jumpto: {
							refid: 11,
							year: 1892,
							jumptext: '1892',
							item: 246,
						}
					}],
					[{dbImage: '74-bild2653.jpg'}],
					[{caption: 'Gedenktafel der Familie Kastner'}],
					[{museum: '74'}],
					[{audio: 'II_12.mp3'}],
					
					[{
						par:
							'Woran denken Sie bei „Zell am See“? Höchstwahrscheinlich an Tourismus? An den sommerlich blau glitzernden Zellersee mit den Segelbooten vor der Altstadt ' +
							'und an dunkelgrüne Bergwälder? Oder an Winterurlaub mit tiefverschneiten Skihängen? Vielleicht denken manche sogar an die sportbegeisterte Kaiserin ' +
							'Sisi, die sich zum Sonnenaufgang auf die Schmittenhöhe führen ließ?'
					}],
					[{
						par:
							'Lange vor alldem war der Ort bereits eine Art Verkehrsknotenpunkt, Start und Ziel der Säumer über die Hohen Tauern und somit wirtschaftliches ' +
							'Zentrum des Nah- und Fernhandels. Die Säumer lieferten vor allem Salz in den Süden und brachten Wein, Öl, Südfrüchte, Venezianisches Glas, Tabak, ' +
							'Seidenstoffe und Brokat hierher. Manche Waren wurden dann erst im Chiemseegebiet umgeschlagen, und für diese Weiterreise mussten die Säumer und ' +
							'ihre Tragtiere verköstigt und versorgt werden. Gastwirte und Kaufleute gelangten zu Wohlstand.'
					}],
					[{
						par:
							'Die wohl bedeutendste Zeller Kaufmannsdynastie war die Familie Kastner, an die ich hier erinnern darf. Fast 150 Jahre lang, bis 1951 besaß und ' +
							'bewohnte die Familie den sogenannten „Vogtturm“ im Stadtzentrum, der deshalb auch als „Kastnerturm“ bezeichnet wird. Seine Geschichte reicht ' +
							'tief ins Mittelalter zurück, wo der schlanke, sechsgeschossige Turm als Teil einer Burganlage errichtet wurde. Heute beherbergt er das Museum ' +
							'im Vogtturm.'
					}],
					
					
					[{
						jumpto: {
							refid: 12,
							year: 1901,
							jumptext: '1901',
							item: 246,
						}
					}],
					[{dbImage: '8-bild947.jpg'}],
					[{caption: 'Erinnerungsstein an den 1. Sprengschuss'}],
					[{museum: '8'}],
					[{audio: 'II_13.mp3'}],
					
					[{
						par:
							'Die längste Zeit meines Lebens verbrachte ich als Teil der Hohen Tauern. Bis auf gelegentliche Unwetter, die ich von außen hören konnte, ' +
							'ist mein Leben recht ruhig verlaufen – zumindest bis zu jenem denkwürdigen Tag am 24. Juni 1901.'
					}],
					[{
						par:
							'Ich hörte einen lauten Knall, und so schnell konnte ich gar nicht schauen, war ich aus dem Gebirge herausgebrochen! Nun war ich auf mich ' +
							'selbst gestellt. Existenzielle Fragen nach dem Weitergang meines Lebens erübrigten sich schnell, als mich kurze Zeit später jemand aufhob, ' +
							'um aus mir einen Erinnerungsstein zu machen. An mir wurde eine Messingplatte mit einer Inschrift befestigt, die an den ersten Sprengschuss ' +
							'am Tauerntunnel erinnern soll. Hinter mir befindet sich ein kleines Männchen. Es stemmt mich hoch und soll die beschwerlichen Aufgaben der ' +
							'Arbeiter ins Bewusstsein rufen.'
					}],
					[{
						par:
							'Der Ort, aus dem ich stamme, verbindet bereits seit 1907 die Bundesländer Salzburg und Kärnten, genauer gesagt das Salzburger Böckstein ' +
							'und das kärntnerische Mallnitz.'
					}],
					[{
						par:
							'(Hemma Ebner u. Sandra Gauß-Haarich, 2022)'
					}],
				
				],
		}
	],
	[
		{
			story_id: 3,
			header: 'Von A nach B',
			content: [
				//				[{subheader1: 'Ein Ausflug in die Salzburger Regionalmuseen auf den Spuren der Medizingeschichte'}],
				[{extImage: 'III_E1.jpg'}],
				[{audio: 'III_00.mp3'}],
				[{
					par: 'Heutzutage ist es ganz normal, dass zahllose LKWs auf unseren Straßen unterwegs sind, dass Güterzüge auf allen Bahnstrecken verkehren. Große Maschinen und schweres Gerät erleichtern die Transportarbeiten in Wirtschaft und Gewerbe. Was heute so selbstverständlich ist, war es die längste Zeit nicht: Es bedeutete Mühe und Krafteinsatz, Strapazen für Menschen, Tiere und Material, um Waren und Güter von A nach B zu bringen. Begleiten Sie uns in die Salzburger Regionalmuseen auf den Spuren des Transports – anno dazumal!'
				}],
				[{
					jumpto: {
						refid: 0,
						year: 1600,
						jumptext: '16. Jahrhundert',
						item: 246,
					}
				}],
				[{dbImage: '9-bild100.jpg'}],
				[{caption: 'Sackziehersack'}],
				[{museum: '9'}],
				
				[{audio: 'III_01.mp3'}],
				[{
					par: 'Der Sackzug ist womöglich nicht das Erste, das einem in den Sinn kommt, wenn man an Fuhrwerke, Schlitten und Züge denkt. Auf Umwegen könnte man den Sackzug aber als eine Art Schlitten interpretieren.'
				}],
				[{
					par: 'Der sogenannte „Sackzug“ kam im alpenländischen Raum in den Wintermonaten zum Einsatz, um das in den Gruben gewonnene Erz, vom Berg ins Tal zu transportieren. So konnte es dann in die Erzbearbeitungsanlagen zur weiteren Verarbeitung gebracht werden. Diese Fördermethode löste im Spätmittelalter den schwierigen Erztransport über Saumfuhren mit Pferden ab und bewährte sich bis ins 19. Jahrhundert. Auch in Böckstein transportierte man das abgebaute Erz vom Radhausberg über den Sackzug ins Tal.'
				}],
				[{
					par: 'Doch wie kann man sich so einen Sackzug vorstellen?'
				}],
				[{
					par: 'Man knotete bis zu 25 mit Erz geladene Säcke zu einem Zug zusammen. Diese Säcke waren schwer - sie konnten ein Gewicht von 50 bis 60 kg erreichen! Der Lenker des Sackzugs war der Sackzieher. Für ihn fungierten die aneinandergeknoteten Säcke als eine Art Schlitten, mit dem er über eine bobbahnartige Schneebahn hinabglitt. Er saß auf dem vordersten Sack und hatte während der Fahrt einen sogenannten „Stachel“, also einen mit einer Eisenspitze versehenen Bergstock in der Hand, mit dem er den Sackzug lenken und die Geschwindigkeit regulieren konnte. Auch ein Hund war immer mit von der Partie. Dieser saß während der Fahrt auf dem letzten Sack und brachte nach der Ankunft im Tal, die leeren Erzsäcke wieder auf den Berg hinauf.'
				}],
				[{
					jumpto: {
						refid: 1,
						year: 1780,
						jumptext: '1780',
						item: 246,
					}
				}],
				
				
				[{dbImage: '9-bild76.jpg'}],
				[{caption: 'Grubenhunt'}],
				[{museum: '9'}],
				
				[{audio: 'III_02.mp3'}],
				[{
					par: 'Bevor das Erz überhaupt vom Sackzieher ins Tal befördert werden konnte, musste man das Erz natürlich zuerst abbauen und zu Tage befördern. Der Transport von Erzen unter Tage war ohne Förderwägen ein kräftezehrendes Unterfangen, weshalb man einfachere und effizientere Wege finden musste.  Durch die Erfindung des Grubenhunts – also einem oben offenen, viereckigen Kasten aus Holz, der auf vier hölzernen Rädern auflag – konnte man das Erz auf einfachere, weniger Menschenkraft aufwendende Weise aus dem Stollen transportieren. Sie entstanden im Spätmittelalter und waren ungefähr bis ins 19. Jahrhundert in Verwendung.'
				}],
				[{
					par: 'Es gab unterschiedliche Arten von Grubenhunten: dieser Grubenhunt aus Böckstein wurde 1780 hergestellt und war ein Grubenhunt ungarischer Art. Da die Vorderräder kleiner waren als die Hinterräder, und diese mittig befestigt waren, konnte man ungarische Grubenhunte leichter schieben und lenken.'
				}],
				[{
					jumpto: {
						refid: 2,
						year: 1920,
						jumptext: '1920er Jahre',
						item: 246,
					}
				}],
				
				
				[{dbImage: '2-bild1121.jpg'}],
				[{caption: 'Marmortransport'}],
				[{museum: '2'}],
				
				[{audio: 'III_03.mp3'}],
				[{
					par: 'Erze waren nicht das einzige, dass in bzw. aus dem Salzburger Raum transportiert wurde. Der Untersberger Marmor erfreute sich großer Beliebtheit und war im mitteleuropäischen Raum weit verbreitet. Funde aus dem Veitlbruch in Fürstenbrunn bezeugen, dass der Marmorabbau dieserorts bereits auf die Römerzeit zurückgeht.'
				}],
				[{
					par: 'Um die schweren Marmorblöcke aus dem Steinbruch zu transportieren, musste man sie, nachdem man sie zuerst per Schrägzug zum Fuß des Marmorbruchs gebracht hat, auf einen Wagen laden. Dieser wurde von mehreren Pferdegespannen gezogen. Aufgrund der Größe und des Gewichts der Marmorblöcke war der Bedarf an Zugtieren immens: ein 100 Tonnen schwerer Marmorblock zum Beispiel, benötigte mehr als zwei Dutzend Pferde!'
				}],
				[{
					jumpto: {
						refid: 3,
						year: 1900,
						jumptext: 'um 1900',
						item: 246,
					}
				}],
				
				[{dbImage: '38-bild287.jpg'}],
				[{caption: 'Holzzugschlitten'}],
				[{museum: '38'}],
				
				[{audio: 'III_04.mp3'}],
				[{
					par: 'Holz war im Gegensatz zum Marmor weniger ein beliebtes Gut, sondern eine Notwendigkeit. Bevor die Wälder durch Forststraßen befahrbar wurden und der Transport in den Sommermonaten möglich war, waren es die Wintermonate, in denen die Holzknechte dem Holztransport nachgingen.'
				}],
				[{
					par: 'Damals wurde das Holz bei Schneelage mit einem Holzzugschlitten ins Tal transportiert. Die leeren Schlitten wurden zu Fuß nach oben gezogen. Dafür hatten die Holzknechte ein rucksackähnliches Geschirr, mit dem sie den Schlitten hinten nachziehen konnten. Der Holzknecht saß bei Fahrt in Richtung Tal vorne auf dem Holz, um den Schlitten mit „Krallen“ zu lenken und zu bremsen. Die Arbeit der Holzknechte war aber mit sehr vielen Gefahren verbunden, denn bei der Talfahrt hatten sie oft mehrere Tonnen Holz im Rücken. Ein kleiner Moment der Unachtsamkeit konnte dem Holzknecht sein Leben kosten.'
				}],
				
				[{
					jumpto: {
						refid: 4,
						year: 1949,
						jumptext: '1949',
						item: 246,
					}
				}],
				[{dbImage: '57-bild655.jpg'}],
				[{caption: 'Ziachschlitten'}],
				[{museum: '57'}],
				
				[{audio: 'III_05.mp3'}],
				[{
					par: 'In den Gebirgsregionen des Pinzgaus nutzte man die sogenannten „Ziachschlitten“ in den Wintermonaten nicht nur für den Transport von Holz. Auch Heu und Einstreu für die Ställe wurden mit ihnen ins Tal befördert. Hinauf wurde er nicht von Nutztieren wie Pferden oder Rindern, sondern von den Lenkern selbst gezogen. Bei manchen kam auch die Ästhetik nicht zu kurz: bei diesem Ziachschlitten aus Kaprun wurden auf den Außenseiten der Kufen zum Beispiel mehrere Tulpen und das Herstellungsjahr „1949“ eingeschnitzt.'
				}],
				
				
				[{
					jumpto: {
						refid: 5,
						year: 1900,
						jumptext: 'um 1900',
						item: 246,
					}
				}],
				[{dbImage: '38-bild220.jpg'}],
				[{caption: 'Bremsschuh für Radwagen'}],
				[{museum: '38'}],
				
				[{audio: 'III_06.mp3'}],
				[{
					par: 'Während bei der Talfahrt mit dem Schlitten die „Krallen“ ausreichten, um die Geschwindigkeit zu drosseln, benötigte man beim Bergabfahren mit Fuhrwerken andere Möglichkeiten, um zu Bremsen. Dazu bediente man sich sogenannter „Radschuhe“ bzw. „Bremsschuhe“. Die kufenförmigen und mit Ketten versehenen Bremsschuhe wurden an den Rädern angebracht. Musste man bergab fahren, machte man vor dem Gefälle halt und zog die Kette vorne hoch, damit der Radschuh sich in den Boden drückt und der Wagen mit gedrosselter Geschwindigkeit hinunterfahren kann.'
				}],
				
				
				[{
					jumpto: {
						refid: 6,
						year: 1930,
						jumptext: '1930er Jahre',
						item: 246,
					}
				}],
				[{dbImage: '35-bild1244.jpg'}],
				[{caption: 'Leiterwagen'}],
				[{museum: '35'}],
				[{audio: 'III_07.mp3'}],
				[{
					par: 'Damit Fuhrwerke, Schlitten, usw. ohne größere Probleme fahren konnten, mussten natürlich auch die Straßen, auf denen sie fuhren, gepflegt und ausgebessert werden. Darum kümmerten sich in Salzburg bis ca. in die 1970er Jahre die sogenannten „Wegmacher“. Sie hatten die Aufgabe, Schlaglöcher auszugleichen, Wasserabläufe offenzuhalten aber auch tierische Exkremente von der Straße zu entfernen.'
				}],
				[{
					par: 'Leiterwägen, wie dieser aus Altenmarkt, dienten dem Transport von Werkzeug, Schotter und Mist – eben allem, was man für die Instandhaltung der Straßen benötigte. Im Dialekt bezeichnet man sie auch als „Wegmacherwagl“ und „Wegmachergaschei“.'
				}],
				
				
				[{
					jumpto: {
						refid: 7,
						year: 1937,
						jumptext: '1937/1938',
						item: 246,
					}
				}],
				[{dbImage: '8-bild2.jpg'}],
				[{caption: 'Lokomotive 1245.514'}],
				[{museum: '8'}],
				[{audio: 'III_08.mp3'}],
				[{
					par: 'Denken wir an den modernen Transport von Gütern, kommt den meisten von uns vermutlich die Eisenbahn in den Sinn. Das Aufkommen der Eisenbahn und die Ausweitung des Eisenbahnnetzes löste alte Transportarten, die Menschenkraft oder tierische Zugkraft benötigten, ab.'
				}],
				[{
					par: 'Der Bau der Tauernbahn, der im Jahr 1901 startete und 1909 mit der Fertigstellung der Südrampe endete, verband Schwarzach und Villach. Viele Züge befuhren damals wie heute die Tauernbahn, um Personen sowie Güter zu transportieren. Eine notwendige Komponente für den Personen-, und Gütertransport ist eine Lokomotive, die die Waggons zieht.'
				}],
				[{
					par: 'Diese Lokomotive, aus der Baureihe 1245.514, wurde 1938 hergestellt und hat 57 Jahre Dienst geleistet. Die Lok bringt ein Gewicht von 83 Tonnen auf die Waage und erreicht eine Höchstgeschwindigkeit von 80km/h. Sie ist ideal für die Befahrung der Hügellandschaft auf der Tauernbahnstrecke. In ihr findet man den Führerstand und den Maschinenraum, der auch heute noch im Tauernbahnmuseum in Schwarzach besichtigt werden kann.'
				}],
				
				
				[{
					jumpto: {
						refid: 8,
						year: 1942,
						jumptext: '1942',
						item: 246,
					}
				}],
				[{dbImage: '8-bild3.jpg'}],
				[{caption: 'Führerstand-Tauernkrokodil'}],
				[{museum: '8'}],
				[{audio: 'III_09.mp3'}],
				[{
					par: 'Wusstet ihr, dass von 1942 bis 1995 ein Krokodil durch Salzburg zog? Keine Sorge, es war kein Krokodil der gefährlichen Sorte, sondern das sogenannte „Tauernkrokodil“! Es stammt aus der ab 1940 gebauten Elektrolok-Baureihe E94 und wurde in Österreich 1954 zur 1020er Reihe umbenannt. Die Bezeichnung „Tauernkrokodil“ erhielt die Lok wohl aufgrund ihrer Schnauze und ihrer ursprünglich tannengrünen Farbe.'
				}],
				[{
					par: 'Die Güterlokomotive wurde 1942 hergestellt und ist 18,6 Meter lang, wiegt satte 120 Tonnen und erreichte eine Geschwindigkeit von bis zu 90 km/h. Über diese Lokreihe wurde in der Nachkriegszeit – im Besonderen in den 1950er Jahren – die Hauptlast des elektrischen Zugverkehrs, nicht nur auf der Tauernbahnstrecke, sondern in ganz Österreich und über dessen Grenzen hinaus abgewickelt.'
				}],
				
				
				[{
					jumpto: {
						refid: 9,
						year: 1900,
						jumptext: 'Angang 20. Jahrhundert',
						item: 246,
					}
				}],
				[{dbImage: '35-bild144.jpg'}],
				[{caption: 'Kraxe'}],
				[{museum: '35'}],
				[{audio: 'III_10.mp3'}],
				[{
					par: 'Kleinere Lasten und Waren, die keiner Zugtiere oder Fahrzeuge bedurften, wurden durch Tragbehelfe von Menschenhand oder auf Menschenrücken transportiert. Abhängig von der Art der Lasten bzw. der Funktion des Hilfsmittels, entstand eine Vielfalt von Tragbehelfen, die an die speziellen Gegebenheiten und Bedürfnisse angepasst wurden. Dem Einfallsreichtum waren dabei keine Grenzen gesetzt.'
				}],
				[{
					par: 'Die gängigste Tragehilfe war die sogenannte „Kraxe“, ein Rückentragegestell, das für den Transport unterschiedlichster Lasten benutzt wurde. Auf ihnen transportierte man Waren, Lebensmittel, Werkzeuge und Ausrüstung, um ein paar Beispiele zu nennen.'
				}],
				[{
					par: 'Diese Kraxe aus Altenmarkt wurde einst von einem ehemaligen Holzknecht für seine Aufenthalte im Holzschlag verwendet. Er transportierte damit seine Ausrüstung, wie Werkzeug, Bettzeug, Beleuchtung und Kochgeschirr, als auch Proviant zu den Holzknechthütten oder Holzknechtsöldn, in denen sie während des Holzschlags untergebracht waren.'
				}],
				
				
				[{
					jumpto: {
						refid: 10,
						year: 1875,
						jumptext: '2. Hälfte 19. Jahrhundert',
						item: 246,
					}
				}],
				[{dbImage: '19-bild450.jpg'}],
				[{caption: 'Schmalzbehälter-Spadel'}],
				[{museum: '19'}],
				[{audio: 'III_11.mp3'}],
				[{
					par: 'Apropos Proviant: für die Zeit im Holzschlag mussten die Holzknechte einen Vorrat an Lebensmitteln mitnehmen. Diesen mussten sie heil zu ihrem Ziel bringen. Dieses runde Holzgefäß ist ein Schmalzbehälter und wurde verwendet, um Butterschmalz zu befördern. Im Dialekt bezeichnet man ihn auch als „Spadel“.'
				}],
				[{
					par: 'Man wählte ein Holzgebinde, anstatt von Ton oder Metall, da das Schmalz so besser isoliert war und das Material wesentlich leichter war.'
				}],
				
				
				[{
					jumpto: {
						refid: 11,
						year: 1800,
						jumptext: '19. Jahrhundert',
						item: 246,
					}
				}],
				[{dbImage: '17-bild217.jpg'}],
				[{caption: 'Kraxen-Millibutschn'}],
				[{museum: '17'}],
				[{audio: 'III_12.mp3'}],
				[{
					par: 'Die Kraxe in ihrer Grundform bot sich für den Transport unterschiedlichster Dinge an. Auch im Kontext des Almbetriebs und des Transports von Milch, Butter und Käse von der Alm nutzte man Kraxen: auf diesem Tragegestell, dass sich heute im Museum in Kuchl befindet, wurde zum Beispiel eine Milchkanne, die man im Dialekt auch als „Millibutschn“ bezeichnet, mit einem Fassungsvermögen von 20 Litern, platziert und befestigt.'
				}],
				
				
				[{
					jumpto: {
						refid: 12,
						year: 1800,
						jumptext: '19. Jahrhundert',
						item: 246,
					}
				}],
				[{dbImage: '13-bild1539.jpg'}],
				[{caption: 'Kopfkraxe'}],
				[{museum: '13'}],
				[{audio: 'III_13.mp3'}],
				[{
					par: 'Manchmal musste man so viel wie möglich, so effizient wie möglich transportieren. Eine Kopfkraxe wie diese aus Mittersill ermöglichte dies. Sie ist ein Rückentragegestell, dass neben dem klassischen Rückenteil, auch ein Kopfteil hat, sodass man die Lasten sowohl auf dem Rücken als auch auf dem Kopf befördern konnte und die Last somit besser verteilt war. Damit das Kopfteil mit den schweren Lasten nicht direkt auf dem Kopf auflag, benutzte man einen kleinen Polster, den sogenannten „Kopfriedl“. Diesen platzierte man zwischen Kopf und dem Kopfteil. Auch auf dem unteren Rückenteil befand sich so ein Polster.'
				}],
				
				
				[{
					jumpto: {
						refid: 13,
						year: 1950,
						jumptext: '20. Jahrhundert',
						item: 246,
					}
				}],
				[{dbImage: '20-bild1568.jpg'}],
				[{caption: 'Bimperling'}],
				[{museum: '20'}],
				[{audio: 'III_14.mp3'}],
				[{
					par: 'Auch damals schon lautete die Devise, ausreichend zu trinken. Um während der Arbeit auf dem Feld alle mit genügend Wasser versorgen zu können, nutzte man unter anderem dieses Trinkgefäß – den sogenannten „Bimperling“.'
				}],
				[{
					par: 'Der Bimperling ist ein rundes Holzgefäß mit zwei Öffnungen. Die eine Öffnung dient dem Befüllen des Trinkgefäßes und kann mit einem Stöpsel verschlossen werden. Die zweite Öffnung, aus der ein Zapfen herausragt, wurde zum Trinken verwendet. Transportieren konnte man ihn anhand zweier Hanfseile.'
				}],
				[{
					par: 'Dieser Bimperling versorgte die Arbeitskräfte auf der steilen Bergmahd mit ausreichend Wasser. Er erwies sich in vielerlei Hinsicht als praktisch: er hatte ein größeres Volumen und das Trinkwasser blieb wegen des Holzes länger gekühlt.'
				}],
				
				
				[{
					jumpto: {
						refid: 14,
						year: 1920,
						jumptext: '1920er Jahre',
						item: 246,
					}
				}],
				[{dbImage: '13-bild1723.jpg'}],
				[{caption: 'Schusterkraxe'}],
				[{museum: '13'}],
				[{audio: 'III_15.mp3'}],
				[{
					par: 'Dienstleister aus den Gewerben der Schuster, Schneider, Weber u.a. begaben sich bis in die 1950er Jahre auf die sogenannte „Stör“. Dies bedeutete, dass sie ihr Handwerk entweder zur Gänze oder für bestimmte Zeitspannen auf fremden Bauernhöfen ausübten. Für die Dauer des Auftrags erhielten sie Kost und Logis und nach Beendigung den festgelegten Lohn. Wenn sie aus ihren Werkstätten loszogen, mussten sie ihr Werkzeug und gegebenenfalls auch Materialien mitnehmen. Dazu nutzte man oft Kraxen.'
				}],
				[{
					par: 'Diese Kraxe zum Beispiel gehörte einem Schuster. Er benutzte sie, um sein Werkzeug von A nach B zu bringen. Diese Schusterkraxe erinnert an einen tragbaren Werkzeugkasten. Öffnet man diesen seitlich, erscheinen mehrere Schubladenflächen.'
				}],
				
				
				[{
					jumpto: {
						refid: 15,
						year: 1810,
						jumptext: '1810',
						item: 246,
					}
				}],
				[{dbImage: '28-bild1816.jpg'}],
				[{caption: 'Diorama Kraxen- und Bandlkramer'}],
				[{museum: '28'}],
				[{audio: 'III_16.mp3'}],
				[{
					par: 'Oft verbinden wir mit Berufen auch bestimmte Gegenstände oder Eigenschaften. Bei Kraxenkramern und Bandlkramern zum Beispiel – wie in diesem Diorama auf der rechten Seite zu sehen – war ein Tragbehelf wie die Kraxe oder ein Bauchladen nicht wegzudenken. Sie waren Händler bzw. Hausierer, die in Salzburg und andernorts zu Fuß über die gebirgigen Regionen von Ort zu Ort wanderten, um ihre Waren zu verkaufen.'
				}],
				[{
					par: 'Bevor die Industrialisierung eintrat und die Menschen mobiler wurden, war man vor allem an abgelegeneren Orten auf wandernde Händler angewiesen. Sie verkauften vor allem Kurzwaren, damit sind vorwiegend Nähutensilien, wie Nadeln, Zwirn und Textilien, aber auch Haushaltswaren, Geschirr, Spielzeug u.a. gemeint. Es war eine schwierige und wenig ertragreiche Arbeit, die meistens von Personen aus eher einkommensschwachen Schichten ausgeübt wurde. Des Weiteren waren Hausierer, abgesehen bei ihren Stammkunden, vor allem bei den sesshaften Händlern unbeliebt.'
				}],
			
			
			],
		}
	],
	[
		{
			story_id: 4,
			header: '... und zurück!',
			content: [
				
				[{extImage: 'III_E2.JPG'}],
				
				[{caption: 'Teil 2: Zugtiere und Schiffe'}],
				[{audio: 'III_17.mp3'}],
				[{
					par: 'Im zweiten Teil unserer Reise durch die Salzburger Regionalmuseen wollen wir erkunden, wie große, sperrige oder schwere Lasten transportiert wurden. Vorweg: In Salzburg spielte jahrhundertelang auch der Wasserweg eine wichtige Rolle! Doch dazu später mehr…'
				}],
				[{
					par: 'Zunächst wollen wir uns mit den verschiedenen kleineren und größeren tierischen Helfern beschäftigen.'
				}],
				
				
				[{
					jumpto: {
						refid: 0,
						year: 1900,
						jumptext: 'um 1900',
						item: 246,
					}
				}],
				[{dbImage: '38-bild297.jpg'}],
				[{caption: 'Ochsenjoch'}],
				[{museum: '38'}],
				[{audio: 'III_18.mp3'}],
				[{
					par: 'Wussten Sie, dass Ochsen bereits in der Jungsteinzeit als Arbeitstiere genutzt wurden?'
				}],
				[{
					par: 'Im Gegensatz zu Stieren lassen sich Ochsen gut zur Arbeit abrichten. Durch ihr ruhiges und friedliches Wesen und ihre große Kraft eignen sie sich beispielsweise zum Ziehen schwerer Lasten.'
				}],
				[{
					par: 'Mit einem solchen Joch konnten zwei Ochsen vor einen Wagen oder Schlitten gespannt werden. Zwischen Joch und Nacken der Tiere kamen feste, mit Leder bezogene Pölster aus Leinen. Eigene Stirnpolster, sogenannte „Bagel“, schützten die empfindliche Haut der Tiere vor Druckstellen.'
				}],
				[{
					par: 'Hier, im südlichen Lungau wurde Eisenerz von den Bergwerken in Schönfeld sicherlich mit solchen Ochsengespannen zur Verhüttung nach Bundschuh gebracht.'
				}],
				
				
				[{
					jumpto: {
						refid: 1,
						year: 1940,
						jumptext: '1940er Jahre',
						item: 246,
					}
				}],
				[{dbImage: '3-bild1638.jpg'}],
				[{caption: 'Kuhkummet'}],
				[{museum: '3'}],
				[{audio: 'III_19.mp3'}],
				[{
					par: 'Dieses Kummet kam aufgrund seiner Größe sicher auch bei Ochsen zum Einsatz. Es erinnert stark an ein Pferdekummet, ist jedoch an der Unterseite geöffnet. Nur so konnte es einem Rind, das ja Hörner trägt, angelegt werden. Verschlossen wurde es mit einer kurzen Kette an der Unterseite. Wie ein Pferdekummet ist auch dieses Kummet stark ausgepolstert und mit verschiedenen Anhängeringen versehen.'
				}],
				
				
				[{
					jumpto: {
						refid: 2,
						year: 1950,
						jumptext: '1950er Jahre',
						item: 246,
					}
				}],
				[{dbImage: '3-bild205.jpg'}],
				[{caption: 'Kuhjoch'}],
				[{museum: '3'}],
				[{audio: 'III_20.mp3'}],
				[{
					par: 'Wer glaubt, dass Kühe früher ausschließlich Milchlieferantinnen waren, hat vermutlich noch nie etwas von Zugkühen gehört? Bevor sich in den 1960ern Traktoren durchsetzten, gab es auch bei uns manchmal noch Zugkühe, die, genau wie Ochsen, zum Ziehen von verschiedensten Lasten eingesetzt wurden.'
				}],
				[{
					par: 'Nicht jede Kuh eignete sich dafür: man suchte robuste, stämmige und gutmütige Tiere aus, die schon als Kalbin nach und nach abgerichtet, also speziell trainiert wurden. Dass eine solche „Kraftsportlerin“ später weniger Milch gab als ihre Kolleginnen, wurde gern in Kauf genommen.'
				}],
				[{
					par: 'Es erforderte viel Zeit und Geduld, bis eine Kuh an das Halfter und das Führen, das Jochkissen und Joch, das Anhängen und Ziehen von Lasten gewöhnt war und – besonders wichtig! – die Kommandos zum Stehenbleiben und Weitergehen erlernt hatte.'
				}],
				[{
					par: 'Besonderes Augenmerk wurde auf die Hörner gelegt: Diese sollten kräftig und gerade gewachsen sein und möglichst mit den Enden nach oben weisen. Manchmal musste beim Jungvieh das Hornwachstum sozusagen „orthopädisch“, mit einer Hornkluppe korrigiert werden.'
				}],
				[{
					par: 'An den Hörnern wurde nämlich das Kuhjoch mit Lederriemen angeschnallt. Das Joch mit den beiden langen Kummethölzern saß im Nacken der Kuh, sodass sich die Zugkraft auf den ganzen Bereich von Stirn und Nacken verteilte. Um ein Wundscheuern zu verhindern, lag das sogenannte Jochkissen längs unter dem Joch.'
				}],
				[{
					par: 'Bei der Heuernte, wenn die Kuh den Wagen immer nur Stück für Stück weiterziehen sollte, bekam sie beim Stehenbleiben immer etwas vom frischen Heu, das sie sich schmecken ließ, während die Bauersleute das Heu am Wagen auflegten.'
				}],
				
				
				[{
					jumpto: {
						refid: 3,
						year: 1950,
						jumptext: '1950er Jahre',
						item: 246,
					}
				}],
				[{dbImage: '3-bild883.jpg'}],
				[{caption: 'Jochkissen'}],
				[{museum: '3'}],
				[{audio: 'III_21.mp3'}],
				[{
					par: 'Das vorhin erwähnte Jochkissen ist ein länglicher, mehrere Zentimeter dicker, fest ausgestopfter Leinenpolster mit Lederbezug an der Oberseite. Es wurde einer Zugkuh oder einem Ochsen als Unterlage unter dem Nackenjoch längs über Stirn, Hornansätze und Nacken gelegt.'
				}],
				
				
				[{
					jumpto: {
						refid: 4,
						year: 1900,
						jumptext: 'um 1900',
						item: 246,
					}
				}],
				[{dbImage: '53-bild27.jpg'}],
				[{caption: 'Stirnjoch'}],
				[{museum: '53'}],
				[{audio: 'III_22.mp3'}],
				[{
					par: 'Solche gut gepolsterten, mondsichelförmigen Joche fanden bei Zugkühen oder Ochsen Verwendung. Wie der Name Stirnjoch verrät, wurden sie an der Stirn, direkt unter dem Hornansatz festgeschnallt.'
				}],
				[{
					par: 'Bei unserem Stirnjoch ist die Vorderseite mit einem Band aus Schmiedeeisen verstärkt und mit dem Polster verschraubt. An den beiden spitz zulaufenden, eingerollten Enden sind Ringe eingehängt, in denen das Zuggeschirr befestigt wurde.'
				}],
				
				
				[{
					jumpto: {
						refid: 5,
						year: 1850,
						jumptext: '19. Jahrhundert',
						item: 246,
					}
				}],
				[{dbImage: '53-bild232.jpg'}],
				[{caption: 'Rindereisen'}],
				[{museum: '53'}],
				[{audio: 'III_23.mp3'}],
				[{
					par: 'Wenn schwere Lasten über weitere Strecken transportiert werden sollten, wurden Ochsen, ähnlich wie Pferde, mit Eisen beschlagen, damit sich die Klauen nicht übermäßig abrieben und vor allem, um die empfindlichen Ballen vor Verletzungen zu schützen.'
				}],
				[{
					par: 'Im Gegensatz zu Pferde-Hufeisen, die aus einem Stück bestehen, müssen Rindereisen immer zweiteilig und gegengleich sein, da Rinder bekanntlich Paarhufer sind. Während bei Pferden die Hufeisen zum Anpassen zumeist aufgebrannt wurden, erfolgte der Rinderbeschlag auf kaltem Wege. Deshalb mussten Rindereisen vor dem Aufnageln individuell und ganz exakt angepasst werden.'
				}],
				[{
					par: 'Das Aufnageln der Eisen erforderte großes Wissen, Gespür und viel Erfahrung, denn beim Rind reicht der gut durchblutete Teil der Klauen, das sogenannte „Leben“, bis fast zum Klauenrand.'
				}],
				[{
					par: 'Das hier gezeigte – einzelne – Rindereisen ist ein Bodenfund aus Wals.'
				}],
				
				
				[{
					jumpto: {
						refid: 6,
						year: -200,
						jumptext: 'um 200 n. Chr.',
						item: 246,
					}
				}],
				[{dbImage: '28-bild2405.jpg'}],
				[{caption: 'Hipposandale'}],
				[{museum: '28'}],
				[{audio: 'III_24.mp3'}],
				[{
					par: 'Apropos Hufeisen…'
				}],
				[{
					par: 'Wussten Sie, dass Hufeisen bei den Römern noch nicht bekannt oder zumindest nicht üblich waren? Um Pferdehufe vor starken Beanspruchungen zu schützen, beispielsweise auf steilen Gebirgsstrecken oder beim Ziehen schwerer Lasten, wurden sogenannte Hipposandalen verwendet.'
				}],
				[{
					par: 'Hipposandalen (von griech. hippos – Pferd) bestanden meist aus Bronze oder (wie hier) aus Schmiedeeisen. Abhängig von den Bodenverhältnissen konnten sie mit unterschiedlichen Stollen bestückt sein. Hipposandalen wurden mit Lederriemen an den Fesseln des Pferdes festgebunden.'
				}],
				[{
					par: 'Sie eigneten sich aber nur für langsame Gangarten. Beim Trab oder gar beim Galopp hätten sie leicht abfallen können. Zudem boten die Metallsohlen dann nur wenig Halt.'
				}],
				[{
					par: 'Vielleicht ist genau dieses passiert? Diese Hipposandale wurde nämlich in einem Waldstück in der Nähe der Römerstraße über den Pass Lueg gefunden. Sie war von einer Baumwurzel durchwachsen.'
				}],
				
				
				[{
					jumpto: {
						refid: 7,
						year: 1500,
						jumptext: 'um 1500',
						item: 246,
					}
				}],
				[{dbImage: '53-bild230.jpg'}],
				[{caption: 'Hufeisen'}],
				[{museum: '53'}],
				[{audio: 'III_25.mp3'}],
				[{
					par: 'In Mitteleuropa werden Pferde-Hufeisen erst seit dem Frühmittelalter verwendet. Hufeisen schützen die Pferdehufe vor Beanspruchung und Abrieb, können aber auch bei Fehlstellungen des Hufes korrigierend eingesetzt werden.'
				}],
				[{
					par: 'Für einen guten Hufbeschlag muss der Hufschmied die Vorarbeiten, nämlich die Hufpflege und das neu Anpassen des Eisens, präzise ausführen. Beim eigentlichen Beschlag werden die Hufnägel nur entlang der sogenannten weißen Linie eingeschlagen, um das Pferd nicht zu verletzen. Alle diese Arbeiten erfordern große Kunstfertigkeit, Geschick, Erfahrung aber auch einiges an körperlicher Kraft.'
				}],
				[{
					par: 'Das zierliche Hufeisen aus der Bachschmiede in Wals ist ein Bodenfund. Es ist handgeschmiedet, hat eine schlanke Form und je drei Nagellöcher auf jeder Seite. Die beiden Enden laufen zu Stollen aus. Es könnte auch von einem Esel oder ein Maultier stammen.'
				}],
				
				
				[{
					jumpto: {
						refid: 8,
						year: 1900,
						jumptext: 'um 1900',
						item: 246,
					}
				}],
				[{dbImage: '47-bild54.jpg'}],
				[{caption: 'Holzschuh für Pferd'}],
				[{museum: '47'}],
				[{audio: 'III_26.mp3'}],
				[{
					par: 'Manchmal passierte es, dass Pferde bei der Arbeit im Moor einsanken. Und es war gar nicht leicht, so ein im Moor steckendes, vielleicht verschrecktes Pferd wieder heil aus dem Morast zu bekommen. Da war guter Rat oft teuer. So stellte man Überlegungen an, wie die Trittfläche der Pferdehufe vergrößert werden könnte, um das Einsinken zu verhindern, ohne das Pferd dabei allzu sehr zu behindern. So wurden schließlich „Moorschuhe“ entwickelt. Das waren im Prinzip Holzbretter zum Anmontieren an die Pferdehufe.'
				}],
				[{
					par: 'Anfang des 20. Jahrhunderts kamen in Bürmoos solche speziell angefertigten Holzschuhe beim Torfabbau zum Einsatz. Dabei wurden die Pferdehufe in eine Eisenform am Holzbrett eingehakt und anschließend verschraubt. Zur zusätzlichen Befestigung diente ein Lederriemen. Das Anlegen und Befestigen der Schuhe nahm sicher viel Zeit in Anspruch. Mit etwas Geduld und der richtigen Handhabung brachte dies jedoch große Erleichterungen für die Arbeit im Moor.'
				}],
				
				
				[{
					jumpto: {
						refid: 9,
						year: 1960,
						jumptext: '1960er Jahre',
						item: 246,
					}
				}],
				[{dbImage: '3-bild1637.jpg'}],
				[{caption: 'Halfter'}],
				[{museum: '3'}],
				[{audio: 'III_27.mp3'}],
				[{
					par: 'Ein schön verziertes Pferdehalfter war der Stolz jedes Bauern. Es diente zum Führen des Pferdes. Dazu konnte auch ein eigener Führstrick eingehängt werden. Zum Einspannen wurde meist ein zusätzliches Zaumzeug angelegt.'
				}],
				[{
					par: 'Dieses Halfter aus dem Museum Arlerhof in Abtenau ist aus Leder gefertigt und mit zwei fein geschwungenen Messingschnallen verschlossen. Der Stirnriemen zeigt die typische Dreieck-Bordüre aus weißem und rotem Leder. Starke Gebrauchsspuren und einige Flickstellen lassen vermuten, dass dieses Halfter lange und gerne in Verwendung stand.'
				}],
				
				
				[{
					jumpto: {
						refid: 10,
						year: 1950,
						jumptext: '1950er Jahre',
						item: 246,
					}
				}],
				[{dbImage: '3-bild202.jpg'}],
				[{caption: 'Pferdekummet'}],
				[{museum: '3'}],
				[{audio: 'III_28.mp3'}],
				[{
					par: 'Das Kummet war (und ist) ein wesentlicher Teil des Zuggeschirrs, das beim Einspannen von Pferden zum Lastenziehen, beispielsweise bei der Holzarbeit, Verwendung fand. Kummete wurden vom Sattler für jedes Pferd maßgefertigt.'
				}],
				[{
					par: 'Beim Einspannen wurde das Kummet um den Hals des Pferdes gelegt und mit verschiedenen Gurten und Riemen mit dem übrigen Zuggeschirr verbunden. Durch das Kummet konnte sich die Zuglast optimal auf Brust, Schultern und Widerrist verteilen.'
				}],
				[{
					par: 'Unser Kummet aus dem Museum Arlerhof in Abtenau ist aus Holz gefertigt und mit Leder und Leinen gut gepolstert. Die Schutzkappe an der Oberseite, die „Kummetspitze“, verhindert das Eindringen von Wasser oder Staub. Anhängeringe und Ketten sind aus Eisen geschmiedet. Die schönen Messingverzierungen, Ziernägel und Schnallen lassen den Stolz des damaligen Besitzers erahnen und seine Freude an der Pferdearbeit. Für heutige Pferde wäre dieses Kummet allerdings zu klein.'
				}],
				
				
				[{
					jumpto: {
						refid: 11,
						year: 1900,
						jumptext: 'um 1900',
						item: 246,
					}
				}],
				[{dbImage: '9-bild191.jpg'}],
				[{caption: 'Pferdegeschirr'}],
				[{museum: '9'}],
				[{audio: 'III_29.mp3'}],
				[{
					par: 'Im Montanmuseum Altböckstein hat sich ein komplettes, sehr stark ausgelegtes Pferde-Zuggeschirr erhalten. Es stammt vermutlich aus der Zeit Ende des 19. Jahrhunderts, als man versuchte, den Goldbergbau am Radhausberg wiederaufleben zu lassen.'
				}],
				[{
					par: 'Der Goldbergbau im Gasteinertal hatte seinen Höhepunkt bis etwa 1550 unter den Gewerkenfamilien der Weitmoser, Strasser, Strochner und Zott erreicht. Damals wurden bis zu 650 kg Gold und 2.000 kg Silber jährlich gefördert. Wenn man bedenkt, dass bei einer optimalen Ausbeute pro Tonne Gestein gerade einmal 28 Gramm (!) Gold gewonnen werden konnten, kann man sich vorstellen, welche Unmengen an Gestein hier bewegt und bearbeitet wurden!'
				}],
				[{
					par: 'An diese Erfolge konnte nur später mehr teilweise angeschlossen werden. Aber auch jetzt wurde das gepochte, auf Kieselsteingröße zerkleinerte Golderz vom Radhausberg nach Böckstein transportiert. Im Winter geschah dies mit Sackzügen auf Eis- oder Schneebahnen, wie es sich seit dem Mittelalter bewährt hatte. Wir haben am Anfang der Führung schon davon gehört. Während der schneefreien Zeit des Jahres erfolgte der Transport mit Pferdefuhrwerken. Möglicherweise wurde dieses Zuggeschirr genau dazu eingesetzt.'
				}],
				
				
				[{
					jumpto: {
						refid: 12,
						year: 1850,
						jumptext: '19. Jahrhundert',
						item: 246,
					}
				}],
				[{dbImage: '13-bild1538.jpg'}],
				[{caption: 'Doppellagel mit Tragsattel'}],
				[{museum: '13'}],
				[{audio: 'III_30.mp3'}],
				[{
					par: 'Bis zum Ausbau der Straßen und Bahnverbindungen brachten Säumer mit ihren Tragtieren Waren über die Alpen: Wein und Olivenöl, Gewürze, feine Stoffe, Venezianisches Glas und vieles mehr. Im Gegenzug transportierten sie Salz in den Süden.'
				}],
				[{
					par: 'Die Arbeit war nicht nur anstrengend, sondern auch gefährlich, denn im Hochgebirge kann das Wetter rasch umschlagen – dann gibt es selbst mitten im Sommer heftige Schneestürme! Daher gingen die Säumer stets in Gruppen, meist wurden die Saumpferde sogar zusammengehängt. Schutz, Unterkunft und Stärkung für Menschen und Tiere boten die Tauernhäuser, die in gewissen Abständen entlang der Alpenübergänge standen.'
				}],
				[{
					par: 'Dieser Tragsattel befindet sich im Felberturmmuseum in Mittersill. Die beiden massiven, aus Holz gefertigten Transportfässer werden als „Lageln“ bezeichnet. Vollgefüllt wogen sie jeweils rund 80 Kilogramm, zusammen mit dem Traggestell und dem Zaumzeug ergab dies sicher ein Gewicht von gut 180 Kilogramm – eine echte Herausforderung, selbst für ein kräftiges Tragtier!'
				}],
				[{
					par: 'Das wohl beliebteste und bewährteste Pferd für den Saumhandel war der Noriker. Dieses Kaltblut zeichnet sich nicht nur durch Stärke, Widerstandsfähigkeit und Trittsicherheit aus, Noriker sind darüber hinaus gutmütig und arbeitswillig. Also perfekte Arbeitspartner für die Säumer!'
				}],
				
				
				[{
					jumpto: {
						refid: 13,
						year: 1900,
						jumptext: 'um 1900',
						item: 246,
					}
				}],
				[{dbImage: '9-bild224.jpg'}],
				[{caption: 'Tragkörbe für Pferde'}],
				[{museum: '9'}],
				[{audio: 'III_31.mp3'}],
				[{
					par: 'Seit frühesten Zeiten verlief ein wichtiger Handelsweg durch das Gasteinertal und weiter über den Alpenhauptkamm nach Süden – lange bevor 1909 die Tauernbahn eröffnet wurde.'
				}],
				[{
					par: 'Der Weg, den hauptsächlich Säumer nützten, führte von Böckstein durch das Anlauftal über den Korntauern oder Hohen Tauern auf knapp 2.500 m Höhe hinunter in das Kärntnernische Mallnitz. Ein anderer Weg ging vom Nassfeld aus über den Mallnitzer oder Niederen Tauern ebenfalls nach Kärnten. Zumindest einer der beiden Pässe wurde im Winter meist schneefrei gehalten.'
				}],
				[{
					par: 'Die beiden großen, geflochtenen Transportkörbe wurden von einem Pferd oder einem Maultier getragen. Der Tradition zufolge dienten sie zum Transportieren von Salz nach Mallnitz.'
				}],
				
				
				[{
					jumpto: {
						refid: 14,
						year: 1920,
						jumptext: 'um 1920',
						item: 246,
					}
				}],
				[{dbImage: '3-bild804.jpg'}],
				[{caption: 'Hundekummet'}],
				[{museum: '3'}],
				[{audio: 'III_32.mp3'}],
				[{
					par: 'Nicht nur Pferde, Ochsen oder auch Kühe wurden zum Lastenziehen eingesetzt: Sogar große Hunde ließen sich vor ein „Leiterwagerl“ spannen und konnten leichtere Lasten ziehen, etwa Milchkannen und landwirtschaftliche Produkte, wenn die Bäuerin zum Markt fuhr oder Wasserkrüge und Jause, die den Arbeitern aufs Feld gebracht wurden.'
				}],
				[{
					par: 'Am Bauernhof war das Einspannen und Fahren mit einem solchen Hundegespann meist Sache der Kinder, die auf diese Weise bereits den Umgang mit Zugtieren und die Handhabung von Fuhrwerken erlernten. Zum Einspannen eines Hundes genügte oft ein Brustgeschirr oder ein Zaumzeug – so wie es unser Titelbild zeigt.'
				}],
				[{
					par: 'Im Museum Arlerhof in Abtenau hat sich eine Besonderheit erhalten, nämlich ein Hundekummet. Es ist sozusagen die Miniaturausgabe eines Pferdekummets, ein ovaler, gut ausgepolsterter Lederring, mit Kummetspitze und Messingzier. Das übrige Zuggeschirr wurde in den Ringen befestigt und der Wagen angehängt. Schon konnte es losgehen! Wau!'
				}],
				
				
				[{
					jumpto: {
						refid: 15,
						year: 1800,
						jumptext: 'um 1800',
						item: 246,
					}
				}],
				[{dbImage: '40-bild731.jpg'}],
				[{caption: 'Hallasch'}],
				[{museum: '40'}],
				[{audio: 'III_33.mp3'}],
				[{
					par: 'Zu guter Letzt…'
				}],
				[{
					par: 'Wie vorhin erwähnt, wurde die Salzach, die ab Hallein schiffbar ist, viele Jahrhunderte lang als wichtiger Transportweg genutzt, vor allem zur Beförderung schwerer oder sperriger Waren. Salz aus Hallein, Adneter Marmor, Brennholz oder Kalk wurde flussabwärts transportiert. Oft wurden auf der Salzach auch Waren weiterbefördert, die die Säumer über die Alpen gebracht hatten.'
				}],
				[{
					par: 'In Oberndorf oder Laufen musste alles ab- und auf andere, meist größere Schiffe umgeladen werden. Grund dafür war der „Nocken“, ein Felsen in der Salzachschlinge, der ein gefährliches Hindernis darstellte.'
				}],
				[{
					par: 'Seit der frühen Neuzeit war der Hallasch ein wichtiges Salzach-Transportschiff. Das massiv gebaute, an Bug und Heck spitz zulaufende Schiff war mit Innenrippen verstärkt, hatte einen flachen Boden und verschiedene Aufbauten. Mit einer Länge von bis zu 15 Metern und einer Breite zwischen 2 und 3 Metern konnten etwa 14 Tonnen an Fracht zugeladen werden. Die Besatzung bestand meist aus 6 Mann, die auf zwei gerüstartigen Aufbauten mit langen Rudern das Schiff abwechselnd steuerten. Ein lebensgefährlicher Arbeitsplatz, denn die Schiffer standen ohne Reling einige Meter über dem Wasser und konnten nicht schwimmen – damit sie bei einem Unglück am Schiff blieben!'
				}],
				[{
					par: 'Erst mit dem Ausbau des Eisenbahnnetzes in den 1860ern endete die Salzachschifffahrt, die zahllosen Menschen aus unterschiedlichsten Berufsgruppen Broterwerb geboten hatte.'
				}],
				
				
				[{
					jumpto: {
						refid: 16,
						year: 1800,
						jumptext: 'um 1800',
						item: 246,
					}
				}],
				[{dbImage: '40-bild742.jpg'}],
				[{caption: 'Modell Gegenzug'}],
				[{museum: '40'}],
				[{audio: 'III_34.mp3'}],
				[{
					par: 'War ein Salzachschiff in Oberdorf oder Laufen entladen, so musste es wieder flussaufwärts bis Hallein gezogen werden. Dazu nutzte man den unmittelbar am Flussufer verlaufenden Treppelweg. Dieser Vorgang wurde als „Gegenzug“ bezeichnet. Oft wurden auf diese Weise auch Waren Salzachaufwärts transportiert.'
				}],
				[{
					par: 'Bis zum späten Mittelalter erfolgte der Gegenzug sozusagen in Handarbeit mit Muskelkraft: arme Leute konnten sich mit dem Schiffziehen ihr Brot verdienen. Erst ab etwa 1430 kamen Pferde zum Einsatz, die mit starken Zuggeschirren und an langen Seilen dem Schiff vorgespannt waren. Unter den Rossleuten gab es eine strenge Hierarchie, angeführt vom Vorreiter, der immer ein Stück vorausritt und dafür sorgte, dass der Gegenzug gefahrlos vorankam.'
				}],
				[{
					par: 'Die ungefähr 80 Flusskilometer lange Strecke Laufen – Hallein – Laufen wurde, inklusive erneuter Beladung in Hallein, meist in drei Tagen bewältigt. Während der Schifffahrtssaison, die vom Frühjahr bis zum Spätherbst reichte, verließen über 2.000 Schiffsladungen Salz die Salinenstadt. Auf der Salzach muss also reger Schiffsverkehr geherrscht haben!'
				}],
				
				
				[{
					par:
						'(Sandra Gauß-Haarich und Hemma Ebner, 2023)'
				}],
			],
		}
	],
	[
		{
			story_id: 5,
			header: 'Lieblingsstücke',
			content: [
				[{extImage: 'V_Titelbild_und_Einleitung.jpg'}],
				[{audio: 'V-0_Einleitung.mp3'}],
				[{
					par: 'In dieser Geschichte kommen jene zu Wort, die in den Salzburger Regionalmuseen unmittelbar am Ort des Geschehens sind: Museumsleiterinnen und Museumsleiter, Kustodinnen und Kustoden. Sie alle haben ihr ganz persönliches Lieblingsstück, das sie uns hier vorstellen. Die Auswahl ist so vielfältig wie die Salzburger Museumslandschaft – lassen Sie sich überraschen!'
				}],

				[{ par: '&nbsp;'}],
				[{subheader1: 'Gondel der Stubnerkogelbahn'}],
				[{
					par: 'Siegfried Moser ist der Obmann des Gasteiner Museums in Bad Gastein (Pongau). Sein Lieblingsstück ist diese besondere Seilbahngondel. Warum sie jedoch ein Prototyp bleiben musste, erzählt er hier.'
				}],
				[{audio: 'V-1_37-4019_Stubnerkogelbahn.mp3'}],
				[{extImage: 'V_1.JPG'}],
				[{
					jumpto: {
						refid: 0,
						year: 1951,
						jumptext: '1951',
						item: 246,
					}
				}],
				[{dbImage: '37-bild4019.jpg'}],
				[{dbImage: '37-bild4019a.jpg'}],
				[{caption: 'Gondel Stubnerkogelbahn'}],
				[{museum: '37'}],
				
				[{ par: '&nbsp;'}],
				[{subheader1: 'Marmor-Jause'}],
				[{
					par: 'Heinz Schierhuber leitet das Untersbergmuseum in Grödig-Fürstenbrunn (Flachgau). Für uns hält er diese „kalorienarme" Jause bereit.'
				}],
				[{audio: 'V-2_2-1120_Marmor-Jause.mp3'}],
				[{extImage: 'V_2.JPG'}],
				[{
					jumpto: {
						refid: 1,
						year: 1990,
						jumptext: '1990',
						item: 246,
					}
				}],
				[{dbImage: '2-bild1120.jpg'}],
				[{caption: 'Marmor-Jause'}],
				[{museum: '2'}],
				
				
				[{ par: '&nbsp;'}],
				[{subheader1: 'Modell der Pulverstampfe'}],
				[{
					par: 'Sepp Grabner ist Kustos im Museum Zum Pulvermacher in Elsbethen (Flachgau). Ihn fasziniert die einfache, aber effiziente Technik der Schießpulvererzeugung, die noch völlig ohne Strom auskam.'
				}],
				[{audio: 'V-3_12-0_Pulverstampfe.mp3'}],
				[{extImage: 'V_3.JPG'}],
				[{
					jumpto: {
						refid: 2,
						year: 2003,
						jumptext: '2003',
						item: 246,
					}
				}],
				[{dbImage: '12-bild0.jpg'}],
				[{caption: 'Modell der Pulverstampfe'}],
				[{museum: '12'}],
				
				[{ par: '&nbsp;'}],
				[{subheader1: 'Messingsieb'}],
				[{
					par: 'Wilhelm Leitner leitet das Museum im Fürstenstöckl in Ebenau (Flachgau), dessen Schwerpunkt auf der hiesigen vorindustriellen Messingproduktion und -Verarbeitung liegt. Sein Lieblingsstück ist dieses außergewöhnliche Küchengerät.'
				}],
				[{audio: 'V-4_33-634_Messingsieb.mp3'}],
				[{extImage: 'V_4.JPG'}],
				[{
					jumpto: {
						refid: 3,
						year: 1800,
						jumptext: '1800',
						item: 246,
					}
				}],
				[{dbImage: '33-bild634.jpg'}],
				[{dbImage: '33-bild634a.jpg'}],
				[{caption: 'Messingsieb'}],
				[{museum: '33'}],
				
				[{ par: '&nbsp;'}],
				[{subheader1: 'Herzog Tassilo III.'}],
				[{
					par: 'Josef Sturm ist Leiter des Stiftsmuseums in Mattsee (Flachgau). Beim Barockgemälde mit Darstellung des Mattseer Gründervaters Tassilo III. erinnert er an die Verdienste dieses „vergessenen" und doch so bedeutenden letzten Baiernherzogs.'
				}],
				[{audio: 'V-5_50-35_Tassilo.mp3'}],
				[{extImage: 'V_5.JPG'}],
				[{
					jumpto: {
						refid: 4,
						year: 1700,
						jumptext: '1700',
						item: 246,
					}
				}],
				[{dbImage: '50-bild35.jpg'}],
				[{caption: 'Herzog Tassilo III.'}],
				[{museum: '50'}],
				
				[{ par: '&nbsp;'}],
				[{subheader1: 'Unterkiefer eines Höhlenlöwen'}],
				[{
					par: 'Sebastian Krutter leitet das Museum Burg Golling (Tennengau). Sein Lieblingsstück ist zugleich ein wichtiger Beleg für die eiszeitliche Tierwelt des Gebietes rund um Golling.'
				}],
				[{audio: 'V-6_28-850_Höhlenlöwe.mp3'}],
				[{extImage: 'V_6.JPG'}],
				[{
					jumpto: {
						refid: 5,
						year: -50000,
						jumptext: '-50.000',
						item: 246,
					}
				}],
				[{dbImage: '28-bild850.jpg'}],
				[{caption: 'Unterkiefer eines Höhlenlöwen'}],
				[{museum: '28'}],
				
				[{ par: '&nbsp;'}],
				[{subheader1: 'Gewölbefragment'}],
				[{
					par: 'Erich Erker, bis 2024 Leiter des Museums Vogtturm in Zell am See (Pinzgau) zeigt uns sein Lieblingsstück, das erst durch eine Ausgrabung entdeckt wurde und wichtige Rückschlüsse auf die mittelalterliche Baugeschichte der Stadtpfarrkirche Zell am See ermöglichte.'
				}],
				[{extImage: 'V_7.JPG'}],
				[{audio: 'V-7_74-2802_Gewölbefragment.mp3'}],
				[{
					jumpto: {
						refid: 6,
						year: 1100,
						jumptext: '1100',
						item: 246,
					}
				}],
				[{dbImage: '74-bild2802.jpg'}],
				[{caption: 'Gewölbefragment'}],
				[{museum: '74'}],
				
				
				[{ par: '&nbsp;'}],
				[{subheader1: 'Schnapsbrennerei'}],
				[{
					par: 'Ingrid Thurner ist Kustodin im Museum Zum Pulvermacher in Elsbethen (Flachgau) und stammt selbst aus einer Pulvermacherfamilie. Das Museum, untergebracht im ehemaligen Pulvermacher-Zuhaus, weckt ihre Kindheitserinnerungen.'
				}],
				[{audio: 'V-8_12-834_Schnapsbrennerei.mp3'}],
				[{extImage: 'V_8.JPG'}],
				[{
					jumpto: {
						refid: 7,
						year: 1950,
						jumptext: '1950',
						item: 246,
					}
				}],
				[{dbImage: '12-bild834.jpg'}],
				[{caption: 'Schnapsbrennerei'}],
				[{museum: '12'}],
				
				[{ par: '&nbsp;'}],
				[{subheader1: 'Bleibehälter mit Brevemassa'}],
				[{
					par: 'Klaus Heitzmann leitet das Lungauer Heimatmuseum in Tamsweg (Lungau). Ein seltsamer Dachbodenfund führt weit zurück in die Geschichte des südlichsten Salzburger Bezirks.'
				}],
				[{audio: 'V-9_15-955_Brevemassa.mp3'}],
				[{extImage: 'V_9.jpg'}],
				[{
					jumpto: {
						refid: 8,
						year: 1682,
						jumptext: '1682',
						item: 246,
					}
				}],
				[{dbImage: '15-bild955.jpg'}],
				[{dbImage: '15-bild955a.jpg'}],
				[{caption: 'Bleibehälter mit Brevemassa'}],
				[{museum: '15'}],
				
				[{ par: '&nbsp;'}],
				[{subheader1: 'Hieronymushaus-Modell'}],
				[{
					par: 'Elisabeth Pohl ist Vizeobfrau des Montandenkmals Altböckstein (Pongau). Die Bergbau- und Sozialgeschichte des Gasteinertals ist eines ihrer großen Interessensgebiete.'
				}],
				[{audio: 'V-10_9-283_Hieronymushaus.mp3'}],
				[{extImage: 'V_10.jpg'}],
				[{
					jumpto: {
						refid: 9,
						year: 2012,
						jumptext: '2012',
						item: 246,
					}
				}],
				[{dbImage: '9-bild283.jpg'}],
				[{dbImage: '9-bild283a.jpg'}],
				[{dbImage: '9-bild283b.jpg'}],
				[{caption: 'Hieronymushaus-Modell'}],
				[{museum: '9'}],
				
				[{ par: '&nbsp;'}],
				[{subheader1: 'Biblische Kastenkrippe'}],
				[{
					par: 'Traudi Pirnbacher ist Kustodin im Seelackenmuseum in St. Veit (Pongau). Ihr Lieblingsstück ist diese umfangreiche Kastenkrippe und deren bewegte Geschichte.'
				}],
				[{audio: 'V-11_20-1857_Kastenkrippe.mp3'}],
				[{extImage: 'V_11.JPG'}],
				[{
					jumpto: {
						refid: 10,
						year: 1837,
						jumptext: '1837',
						item: 246,
					}
				}],
				[{dbImage: '20-bild1857.jpg'}],
				[{dbImage: '20-bild1857a.jpg'}],
				[{caption: 'Biblische Kastenkrippe'}],
				[{museum: '20'}],
				
				[{ par: '&nbsp;'}],
				[{subheader1: 'Ein Maurer als Krippenfigur'}],
				[{
					par: 'Andrea Dillinger leitet das Museum Schloss Ritzen in Saalfelden (Pinzgau) und ist Landesobfrau des Landesverbandes Salzburger Museen und Sammlungen. Sie begeistert sich für diese originelle Krippenfigur.'
				}],
				[{audio: 'V-12_11-13469_MaurerKrippe.mp3'}],
				[{extImage: 'V_12.JPG'}],
				[{
					jumpto: {
						refid: 11,
						year: 1953,
						jumptext: '1953',
						item: 246,
					}
				}],
				[{dbImage: '11-bild13469.jpg'}],
				[{caption: 'Ein Maurer als Krippenfigur'}],
				[{museum: '11'}],
				[{
					par: '(Sandra Gauß-Haarich, Vlad Cotuna und Hemma Ebner, 2023/2024)'
				}],
			],
		}
	],

	
	
	
	
	
	
	
	
	
	
	

		[
			{
				story_id: 6,
				header: "Ab in den Schnee - Ski Alpin!",
				content: [
					[{extImage: "VI_E.jpg"}],
					[{caption: "Wilhelm Ritter von Arlt (©Via Aurea/Rauriser Talmuseum)"}],
					[{audio: "VI_Begruessung.mp3"}],
					[{
						par: "Willkommen im Winterland! Berge und Täler sind tief verschneit, die weiße Pracht glitzert im Sonnenschein oder fällt in dicken Flocken sachte vom Himmel. Anlässlich der Ski-WM in Saalbach begeben wir uns auf Zeitreise: Begleiten Sie uns in die Salzburger Regionalmuseen auf den Spuren des Skisports im Land Salzburg: Ab in den Schnee – Ski Alpin!"
					}],
					[{
						jumpto: {
							refid: 0,
							year: 1995,
							jumptext: "1995",
							item: 246
						}
					}],
					[{dbImage: "44-bild1.jpg"}],
					[{caption: "Der erste Skifahrer"}],
					[{museum: '44'}],
					[{audio: "VI_Einleitung.mp3"}],
					[{
						par: "Dass zwei an die Schuhe geschnallte, vorne zugespitzte Bretter die Fortbewegung im Schnee wesentlich erleichtern, weil sie das Einsinken verhindern und sogar das rasche Gleiten ermöglichen, war sicher allen klar, die in schneereichen, abgelegenen Gegenden wohnten. Skandinavien wurde als das fast „sagenumwobene\" Geburtsland des Skifahrens gesehen und, spätestens seit Mitte des 19. Jahrhunderts, als großes Vorbild dargestellt."
					}],
					[{
						par: "Die älteste Darstellung eines Skifahrers findet sich auf einer Felsgravierung aus aus Rødøy in Norwegen. Sie ist etwa 5.000 Jahre alt und zeigt vermutlich einen Jäger, getarnt mit einem Hasenfell, auf Skiern. Die langen Skier sind an den vorderen Enden wie Schlittenkufen aufgebogen."
					}],
					[{subheader: "Kapitel 1: Wilhelm Ritter von Arlt: Der unbekannte Skipionier"}],
					[{extImage: "VI_E.jpg"}],
					[{caption: "Wilhelm Ritter von Arlt (©Via Aurea/Rauriser Talmuseum)"}],
					[{audio: "VI_Kapitel1_Text1.mp3"}],
					[{
						par: "Wilhelm Ritter von Arlt, der bergbauaffine Agraringenieur aus Prag und sein Rauriser Freund Ignaz Rojacher, Leiter des Goldbergbaus, besuchten 1885 Schweden, um neue Metallverarbeitungsverfahren kennenzulernen. Von dieser Reise brachte Arlt ein Paar Ski mit, mit denen er autodidaktisch das Fahren erlernte und die ihn viele Jahre begleiten sollten. Im Februar 1894 gelang ihm mit genau diesen Skiern, gemeinsam mit drei Knappenrössl-„Reitern\", die Erstbesteigung des 3.106 m hohen Sonnblick. Die über 1.500 Höhenmeter ins Tal nach Kolm-Saigurn schafften alle in etwa einer halben Stunde. Bis heute ist diese Pionierleitung zu bewundern! Das Foto zeigt Wilhelm Ritter von Arlt mit genau diesen Skiern."
					}],

					[{
						jumpto: {
							refid: 1,
							year: 1885,
							jumptext: "1885",
							item: 246
						}
					}],
					[{dbImage: "44-bild17.jpg"}],
					[{caption: "Eschenski mit Holzbindung"}],
					[{museum: '44'}],

					[{audio: "VI_Kapitel1_Text2.mp3"}],
					[{
						par: "Diese Skier sind übrigens bis heute erhalten und befinden sich im Salzburger FIS-Landesskimuseum Werfenweng. Unglaublich, dass mit den langen, schweren Skiern mit den einfachen Bindungen aus gebogenem Rohr derartige Leistungen möglich waren!"
					}],

					[{
						jumpto: {
							refid: 2,
							year: 1900,
							jumptext: "Anfang 20. Jahrhundert",
							item: 246
						}
					}],


					[{dbImage: "44-bild186.jpg"}],
					[{caption: "Knappenrössl"}],
					[{museum: '44'}],
					[{audio: "VI_Kapitel1_Text3.mp3"}],
					[{
						par: "Für Skier dürfte sich Ignaz Rojacher weniger begeistert haben. Auf seinem Knappenrössl „ritt\" er meist sogar schneller bergab als sein Freund Arlt. Das Knappenrössl war seit dem 16. Jahrhundert ein beliebtes Transport- und Fortbewegungsmittel der Bergknappen, vor allem, um im Winter nach Schichtende von den Stollen rasch ins Tal zu gelangen. Normalerweise saß, also „ritt\" man darauf und lenkte mit den Fersen und dem am Brett befestigten Strick. Nur die Verwegensten standen bei der Abfahrt am Knappenrössl, dem Urahnen des heutigen Snowboards!"
					}],
					[{subheader: "Kapitel 2: Zdarsky vs Bilgeri: Duell der Skipädagogen"}],
					[{audio: "VI_Kapitel2_Zitat1.mp3"}],
					[{
						par: "<i>Um die Städter massenhaft aus ihren Höhlen im Winter herauszulocken, sie vor Nervosität und Degeneration zu bewahren, muss man ihnen Mittel bieten, sich wirklich in der freien Natur zu bewegen.</i> (Mathias Zdarsky 1896)"
					}],
					
					
					
					[{
						jumpto: {
							refid: 3,
							year: 1896,
							jumptext: "1896",
							item: 246
						}
					}],
					[{dbImage: "44-bild43.jpg"}],
					[{caption: "Zdarsky-Bindung"}],
					[{museum: '44'}],
					[{audio: "VI_Kapitel2_Text1.mp3"}],
					[{
						par: "Der Lehrer, Künstler und skibegeisterte Erfinder Mathias Zdarsky stammte aus Mähren, verlegte seinen Lebensmittelpunkt jedoch nach Lilienfeld in Niederösterreich. Inspiriert durch Fridtjof Nansens Buch „Auf Schneeschuhen durch Grönland\" nach dessen Expedition 1888/89, bestellte Zdarsky Ski aus Norwegen, musste aber bald feststellen, dass die fast 3 Meter langen Bretteln im alpinen Gelände nicht verwendbar waren. Gemeinsam mit dem örtlichen Wagner baute er etwas kürzere Ski. Gleichzeitig konstruierte er eine Stahlsohlenbindung, die für stabilen Halt sorgte und die Skier erstmals auch im Tiefschnee lenkbar machte. Zudem ermöglichte eine spezielle Feder das Heben der Ferse beim Aufsteigen. 1896 meldet Zdarsky seine „Lilienfelder Bindung\" zum Patent an."
					}],

					[{extImage: "VI_mathias_zdarsky_1905.jpg"}],
					[{caption: "Mathias Zdarsky demonstriert seinen Fahrstil 1905 (© Museum Lilienfeld/ Zdarsky-Archiv)"}],
					[{audio: "VI_Kapitel2_Text2.mp3"}],
					[{
						par: "In der Folge entwickelte Zdarsky einen eigenen Fahrstil, bei dem er auf die Einstock-Technik setzte. Er gilt als Erfinder des Stemmbogens und damit als Vater des alpinen Skilaufs. Als Lehrbuch erlebte Zdarskys „Alpine (Lilienfelder) Skifahr-Technik\" insgesamt 17 Auflagen. Er gründete mehrere Skivereine, unterrichtete im Lauf der Zeit tausende Skilernwillige kostenlos, auch Soldaten, und organisierte 1905 den ersten Riesentorlauf der Skigeschichte, dem viele weitere „Wettfahrten\" folgen sollten."
					}],
					[{
						jumpto: {
							refid: 4,
							year: 1910,
							jumptext: "1910",
							item: 246
						}
					}],
					[{dbImage: "44-bild41.jpg"}],
					[{caption: "Oberst Georg Bilgeri"}],
					[{museum: '44'}],
					[{audio: "VI_Kapitel2_Text3.mp3"}],
					[{
						par: "Als Offizier der k. u. k. Armee war Oberst Georg Bilgeri, der 1902 nach Salzburg versetzt wurde, für die Ski-Ausbildung von tausenden Rekruten zuständig, und hier vor allem für die Fortbewegung mit Skiern im alpinen Gelände, verbunden mit oft anspruchsvollen Skitouren."
					}],
					[{extImage: "VI_skiwerkstaette1.jpg"}],
					[{extImage: "VI_skiwerkstaette2.jpg"}],
					[{caption: "k.u.k. Skiwerkstätte Salzburg, um 1910 (©Werfenweng, Salzburger FIS Landesskimuseum)"}],
					[{audio: "VI_Kapitel2_Text4.mp3"}],
					[{
						par: "Zudem leitete Bilgeri von 1906 bis 1910 die k. u. k. Skiwerkstätte in Salzburg, die dem Militärkommando Innsbruck unterstellt war. Hier wurden Skier, Skistöcke und verschiedene Winter-Ausrüstungsgegenstände für die Armee der gesamten Monarchie sowie Alpinuniformen gefertigt."
					}],
					[{
						jumpto: {
							refid: 5,
							year: 1906,
							jumptext: "1906-1910",
							item: 246
						}
					}],

					[{dbImage: "44-bild24.jpg"}],
					[{caption: "Ski aus der k. u. k Skiwerkstätte"}],
					[{museum: '44'}],
					[{audio: "VI_Kapitel2_Text5.mp3"}],
					[{
						par: "In seinem Fahrstil kombinierte Bilgeri die Norwegische mit der Lilienfelder Technik, wobei er ein ausgewiesener Verfechter der norwegischen Doppel- oder Zweistocktechnik war, die sich letztlich durchsetzen sollte. Ähnlich wie Mathias Zdarsky entwickelte auch Georg Bilgeri eine eigene Skibindung, die den Stemmbogen und das Abschwingen ermöglichte und veröffentlichte ein Lehrbuch über den alpinen Skilauf – ausreichend Konfliktpotenzial und Zündstoff für wiederholte Auseinandersetzungen mit Zdarsky, der Bilgeri des Plagiierens beschuldigte. Einmal kam es sogar zu einer Aufforderung zum Duell, das aber zum Glück nicht stattfand!"
					}],
					[{extImage: "VI_skifahrer.jpg"}],
					[{caption: "Skifahrer, Postkarte um 1910 (privat)"}],
					[{audio: "VI_Kapitel2_Text6.mp3"}],
					[{
						par: "Wie schon für Zdarsky war auch Bilgeris großes Anliegen, Skifahren zum Breitensport zu machen. Da Skier knapp und vor allem teuer waren und aus der k. u. k. Skiwerkstätte nicht an Zivilisten verkauft werden durften, verschenkte Bilgeri sie einfach an Interessierte, verbunden mit der Einladung zu einem seiner Grundkurse oder einer von ihm organisierten Skitour. Das Konzept ging auf und nach dem ersten Weltkrieg war Bilgeri als Skipädagoge nicht nur in Österreich, sondern auch in Schweden, der Schweiz und sogar in der Türkei tätig."
					}],
					[{subheader: "Kapitel 3: Rudolf Lettner und die Scharfkanteisen"}],
					[{extImage: "VI_skitour.jpg"}],
					[{caption: "Skitour, Postkarte um 1920 (privat)"}],
					[{audio: "VI_Kapitel3_Text1.mp3"}],
					[{
						par: "Rudolf Lettner wohnte und arbeitete in Hallein, verbrachte aber jede freie Minute in den Bergen und im Winter mit Skitouren, vor allem im Tennengebirge, wo es im hochalpinen Gelände immer wieder zu gefährlichen Situationen kommen konnte. Über ein besonders dramatisches Ereignis berichtete er später:"
					}],
					[{audio: "VI_Kapitel3_Zitat1.mp3"}],
					[{
						par: "<i>Es war im Dezember 1917, als ich an einen nebligen Sonntag von der Wengerau über die bekannte Söldenhütte auf das Plateau des Tennengebirges lossteuerte, und, den gebräuchlichen Weg über die Tauernscharte rechts lassend, in direktem Anstieg über die steilen und total verharschten Hänge den Napf mit 2167 Metern erreichte. Ein eiskalter Wind drängte mich sogleich zur Abfahrt über die steile Mulde der Tauernscharte, wo ich weicheren Schnee anzutreffen hoffte. Anfangs ging es auch ganz gut, unmittelbar an der Scharte aber war der Schnee fast zu Eis geworden, die Hickory-Kanten verloren den Halt und in einem Saus gings die glasigen Hänge hinunter, bald mit dem Kopf, bald mit den Beinen voran auf die Felsen des Mittersteines zu. Mit aller Kraft stemmte ich mich dagegen und konnte im letzten Moment vor dem Zusammenprall durch Einsetzen der Stockspitzen zum Halten kommen.</i> (Rudolf Lettner an Peter Radacher, 1932)"
					}],
					[{
						jumpto: {
							refid: 6,
							year: 1920,
							jumptext: "1920",
							item: 246
						}
					}],
					[{dbImage: "44-bild61.jpg"}],
					[{dbImage: "44-bild61a.jpg"}],
					[{caption: "Erster Eschenski mit Lettnerkante, ca. 1920"}],
					[{museum: '44'}],
					[{audio: "VI_Kapitel3_Text2.mp3"}],
					[{
						par: "Dieser noch glimpflich abgelaufene Unfall veranlasste Lettner, Überlegungen anzustellen, wie er die Holzkanten seiner Skier verstärken könnte, um die Stabilität zu verbessern: Die Geburtsstunde der sogenannten „Lettner-Kanten\", also der klassischen Stahlkanten war gekommen. Lettner experimentierte zunächst mit Uhrenfedern, die er an die Laufflächen seiner Skier schraubte und zudem schärfte, um das Abgleiten bei steilem Harsch und gleichzeitig das Ankleben des Schnees zu vermeiden."
					}],
					[{
						jumpto: {
							refid: 7,
							year: 1926,
							jumptext: "1926",
							item: 246
						}
					}],


					[{dbImage: "44-bild68.jpg"}],
					[{caption: "Schautafel Lettnerkanten zur Patentschrift"}],
					[{museum: '44'}],
					[{audio: "VI_Kapitel3_Text3.mp3"}],
					[{
						par: "1926 schließlich konnte Lettner zwei Patente anmelden, die dann 1928 genehmigt wurden. Das Echo aus der Fachwelt zu dieser genialen und bahnbrechenden Erfindung war zunächst, naja… Aber hören wir selbst:"
					}],
					[{audio: "VI_Kapitel3_Zitat2.mp3"}],
					[{
						par: '<i>Wie fast alle österreichischen Erfinder wurde auch ich von meinen Skikameraden wegen meiner \"Scharfkanteisen\", die noch dazu nicht einmal aus Norwegen stammten und schon darum nichts wert sein konnten, häufig bespöttelt, obwohl gar mancher Prominente bei Harschabfahrten jämmerlich auf der Strecke blieb und ich ihm lachend davonfuhr.</i> (Rudolf Lettner an Peter Radacher, 1932)'
					}],

					[{
						jumpto: {
							refid: 8,
							year: 1950,
							jumptext: "1950",
							item: 246
						}
					}],
					[{dbImage: "44-bild62.jpg"}],
					[{caption: "Original Lettner Skikanten"}],
					[{museum: '44'}],
					[{audio: "VI_Kapitel3_Text4.mp3"}],
					[{
						par: "Der endgültige Durchbruch der Stahlkante gelingt zwei Jahre später, bei den akademischen Winterspielen 1930 in Davos in der Schweiz: In allen alpinen Skibewerben belegen die österreichischen Rennläufer, die Skier mit „Lettnerkante\" fahren, die ersten Plätze am Podest - eine Sensation! Seit damals sind Stahlkanten aus der Skiwelt nicht mehr wegzudenken!"
					}],
					
					
					
					
					
					[{subheader: "Kapitel 4: Bestens ausgerüstet…"}],
					[{audio: "VI_Kapitel4_Text1.mp3"}],
					[{
						par: "Noch lange vor der Erfindung von ultraleichtem, strapazierfähigem Hightech-Material und Funktionswäsche nutzten Skibegeisterte genau das, was sie zur Verfügung hatten. Während man Städter schon einmal elegant mit Anzug und Krawatte auf der Piste antreffen konnte, trug die Landbevölkerung auch zum Skifahren das, was sich auch sonst in der kalten Jahreszeit bewährt hatte: Lodenbekleidung und fest gestrickte, kniehohe Wollgamaschen, Fäustlinge und Hüte – wie wir bereits auf den Fotos der Skipioniere Wilhelm von Arlt und Mathias Zdarsky sehen konnten."
					}],
					[{extImage: "VI_theresia_radacher.jpg"}],
					[{caption: "Theresia Radacher ca. 1910 (©Werfenweng, Salzburger FIS Landesskimuseum)"}],
					[{audio: "VI_Kapitel4_Text2.mp3"}],
					[{
						par: "Theresia Radacher aus Mühlbach am Hochkönig war die erste Frau, die beim Skifahren buchstäblich „die Hosen anhatte\". Sie erkannte, dass Beinkleider, im Gegensatz zu den bisher üblichen, knöchellangen, engen Röcken wesentlich zweckmäßiger waren und mehr Bewegungsfreiheit boten. Und genau diese Eigenschaften erleichterten ihre Berufsausübung: Von 1898 bis 1920 war sie Bergwirtin am Arthurhaus und zugleich am Kaiser-Franz-Joseph-Jubiläumsschutzhaus am Hochkönig, dem heutigen Matrashaus. Die knapp 1.500 Höhenmeter zwischen beiden Hütten bewältigte sie ein- bis zweimal täglich mit Skiern, bergaufsteigend oder zu Tal fahrend, und das meist mit Gepäck. Diese Leistung ist heute kaum mehr vorstellbar!"
					}],

					[{
						jumpto: {
							refid: 9,
							year: 1960,
							jumptext: "1960",
							item: 246
						}
					}],

					[{dbImage: "20-bild1249.jpg"}],
					[{dbImage: "20-bild1249a.jpg"}],
					[{caption: "Skischuhe mit Schuhstreckern"}],
					[{museum: '20'}],
					[{audio: "VI_Kapitel4_Text3.mp3"}],
					[{
						par: "Als Skischuhe verwendete man zunächst Bergschuhe aus festem Leder. Erst mit der Erfindung neuer Bindungstechnologien begann die Anpassung der Schuhe, etwa mit verstärkten, leicht vorgezogenen Sohlen, Eisenbeschlag und schrägen Rillen im Fersenbereich für den Seilzug. Nach Gebrauch mussten die Lederschuhe getrocknet und dabei wieder in Form gebracht werden, wofür man solche verstellbaren Schuhstrecker aus Holz verwendete. Nach dem Trocknen wurden die Schuhe sorgfältig gefettet und poliert, bevor sie erneut zum Einsatz kamen."
					}],

					[{
						jumpto: {
							refid: 10,
							year: 1917,
							jumptext: "1917",
							item: 246
						}
					}],


					[{dbImage: "14-bild2409.jpg"}],
					[{caption: "Skibrille"}],
					[{museum: '14'}],
					[{audio: "VI_Kapitel4_Text4.mp3"}],
					[{
						par: "Eine besondere, vermutlich militärische Skibrille aus der Zeit um den 1. Weltkrieg befindet sich im Museum Schloss Lerchen in Radstadt. Die beiden, den Augenpartien angepassten Teile sind aus dünnem Aluminiumblech gefertigt und mit Leder gepolstert, kleine ausgestanzte Schuppen ermöglichten die Luftzirkulation und verhinderten ein Beschlagen der Gläser. Diese wiederum konnten offensichtlich mit wenigen Handgriffen ausgetauscht werden und verwandelten die Schneebrille in eine Sonnenbrille. Mit einem Gummizug war die Brille am Hinterkopf verschlossen. Sie dürfte jedoch nie verwendet worden sein: Fabrikneu präsentiert sie sich mit dem originalen Aufkleber: „Splitterfrei\"."
					}],
					[{
						jumpto: {
							refid: 11,
							year: 1920,
							jumptext: "um 1920",
							item: 246
						}
					}],
					[{dbImage: "13-bild2671.jpg"}],
					[{dbImage: "13-bild2671a.jpg"}],
					[{caption: "Klappskier"}],
					[{museum: '13'}],
					[{audio: "VI_Kapitel4_Text5.mp3"}],
					[{
						par: "Bergwanderer, Jäger oder gar ein Wilderer? Wir wissen nicht, wer diese praktischen Klappskier nutzte. Die Skier sind ganz klassisch von Hand aus Eschenholz gefertigt und mit einer einfachen Metallbacken-Bindung mit Lederriemen ausgestattet. Etwa in der Mitte, vor der Bindung, sind die Skier geteilt und durch ein Scharnier zusammenklappbar. Zum Aufstieg verstaute man sie einfach im Rucksack. Oben angekommen, wurden die Skier ausgeklappt und mittels einer Flügelschraube fixiert. Der Abfahrt stand nun nichts mehr im Wege!"
					}],
						[{
						jumpto: {
							refid: 12,
							year: 1933,
							jumptext: "1933",
							item: 246
						}
					}],

					[{dbImage: "44-bild70.jpg"}],
					[{caption: "Kinderskier Peter Radacher jun."}],
					[{museum: '44'}],
					[{audio: "VI_Kapitel4_Text6.mp3"}],
					[{
						par: "Oft wird von Skilegenden erzählt, sie hätten Skifahren gelernt, noch bevor sie richtig laufen konnten. Ob das auch bei Peter Radacher jun. so war, wissen wir nicht. Wir wissen aber, dass er diese Kinderski 1933, im Alter von 3 Jahren bekam. An den „genagelten\", einfachen Bindungen sind mehrere Lederriemen fixiert, mit denen die Kinder-Bergschuhe festgeschnallt wurden. Dass die Skier augenscheinlich oft und gerne verwendet wurden, zeigen die starken Gebrauchsspuren."
					}],
					[{
						par: "In den 1950ern startete Peter Radacher eine aktive Skisportlaufbahn, die ihn sogar zu den Olympischen Spielen nach Helsinki führte. Ab 1960 übernahm er als Hüttenwirt das Arthurhaus am Hochkönig und trat damit in die Fußstapfen seiner Großmutter Theresia, die wir vorhin kennengelernt haben. Auch weiterhin blieb er eng mit dem Skisport verbunden, unter anderem durch seine Tätigkeit als Skischulleiter und Leiter der örtlichen Lawinenkommission."
					}],
					[{
						jumpto: {
							refid: 13,
							year: 1930,
							jumptext: "1930er",
							item: 246
						}
					}],

					[{dbImage: "44-bild15.jpg"}],
					[{caption: "Fassdaubenski"}],
					[{museum: '44'}],
					[{audio: "VI_Kapitel4_Text7.mp3"}],
					[{
						par: "Und was machte die arme Landbevölkerung, unternahmen die Knechte und Mägde, die sich weder Skier noch Ausrüstung leisten konnten, wenn sie in ihrer kargen Freizeit ebenfalls Skifahren oder sich einfach nur unterhalten wollten? Ganz einfach: Fassdauben-Skifahren! Fassdauben, also Seitenteile von ausrangierten Fässern, die durch ihre gebogene Form entfernt an Skier erinnerten, wurden gut gewachst und mit Lederriemen an die Schuhe geschnallt. Wobei genagelte Schuhe durchaus von Vorteil waren, sie boten besseren Halt. Einen langen Stachelstock noch in die Hand genommen und schon konnte es losgehen! Dabei war es gar nicht so einfach, mit den kurzen Skiern das Gleichgewicht zu halten und einigermaßen sturzfrei zu Tal zu kommen. Fassdaubenläufe galten als bäuerliches Freizeitvergnügen und waren eine beliebte Abwechslung im winterlichen Alltag."
					}],

					[{
						jumpto: {
							refid: 14,
							year: 1920,
							jumptext: "1920er",
							item: 246
						}
					}],

					[{dbImage: "41-bild130.jpg"}],
					[{caption: "Skiwachse"}],
					[{museum: '41'}],
					[{audio: "VI_Kapitel4_Text8.mp3"}],
					[{
						par: "Sicher war bereits in der Frühzeit des Skifahrens bekannt, dass das Auftragen von Wachs an den Laufflächen die Gleitfähigkeit im Schnee erhöht. Man verwendete dazu Bienenwachs, experimentierte aber auch mit verschiedenen Harzen und anderen Stoffen. Anfang der 1920er Jahre kamen die ersten industriell gefertigten Skiwachse auf den Markt. Inzwischen hatte man erkannt, dass Skiwachse passend zur Schneebeschaffenheit gewählt werden müssen. Bald gab es für alle Schneeverhältnisse eigene Wachse, die, zur besseren Unterscheidung, unterschiedlich gefärbt waren. Das Auftragen geschah allerdings bei allen Wachsen in ähnlicher Weise: Mit einem Bügeleisen wurde das Wachs aufgeschmolzen und mit einer Klinge abgezogen. Rennskier mussten zudem gebürstet werden, um winzige – aber Zeit kostende! – Unebenheiten auszugleichen. „Skiwachseln\" war also ein aufwendiges Unterfangen, das viel Erfahrung voraussetzte!"
					}],



					[{subheader: "Kapitel 5: Hilfe naht!"}],
					[{
						jumpto: {
							refid: 15,
							year: 1950,
							jumptext: "1950er",
							item: 246
						}
					}],

					[{dbImage: "44-bild93.jpg"}],
					[{caption: "Ski-Ersatzspitze"}],
					[{museum: '44'}],
					[{audio: "VI_Kapitel5_Text1.mp3"}],

					[{
						par: "Was passiert, wenn doch „etwas passiert\"? Trotz guter Ausrüstung konnte es zu Unfällen kommen und dann es war gut, für verschiedene Eventualitäten gerüstet zu sein. Nichts unangenehmer, als wenn im Tiefschnee oder an einer exponierten Stelle im Gelände der Ski zu Bruch ging. An ein Weiterfahren war dann nicht zu denken – es sei denn, man hatte eine Ersatzspitze im Gepäck! Das relativ kleine Aluminium-Utensil war gut zu transportieren und konnte im Fall der Fälle einfach aufgesteckt und unkompliziert mit zwei Flügelmuttern festgezogen werden. So manchem Skibegeisterten hat eine solche Ersatzspitze den Tag gerettet!"
					}],

		
					[{
						jumpto: {
							refid: 16,
							year: 1920,
							jumptext: "1920er",
							item: 246
						}
					}],

					
					[{dbImage: "44-bild87.jpg"}],
					[{dbImage: "44-bild87a.jpg"}],
					[{caption: "Ski-Rettungsschlitten"}],
					[{museum: '44'}],
					[{audio: "VI_Kapitel5_Text2.mp3"}],
					[{
						par: "Es gab aber auch schlimmere Unfälle, die den Einsatz von Rettungskräften erforderten. Genau dafür verwendete die Bergrettung in Werfen, sommers wie winters, einen zerlegbaren Rettungsschlitten, dessen Einzelteile auf die Mannschaft verteilt und gemeinsam zur Unglücksstelle getragen wurden. Mit wenigen Handgriffen war der Schlitten dann einsatzbereit und der Verletzte konnte zu Tal gebracht werden. Soweit die Theorie. In der Praxis war es oft so, dass, etwa nach einem Skiunfall am Hochkönig, die Bergrettung in Werfen erst verständigt werden musste, was, lange vor Erfindung des Mobiltelefons, einige Zeit in Anspruch nahm. Die rasch zusammengetrommelte Rettungsmannschaft musste dann erst zum Unglücksort aufsteigen, unter Mitnahme des vorhin erwähnten Schlittens, den Verunglückten erstversorgen und vorsichtig nach Werfen abfahren, was je nach Schwere der Verletzungen unterschiedlich lange dauern konnte. Im Tal angekommen, wurde der nächste Zug aufgehalten, um das Unfallopfer nach Salzburg ins Krankenhaus bringen zu können. Oft vergingen dabei bis zu 24 Stunden."
					}],

					[{
						jumpto: {
							refid: 17,
							year: 1950,
							jumptext: "1950er",
							item: 246
						}
					}],


				
					[{dbImage: "13-bild1255.jpg"}],
					[{dbImage: "13-bild1255a.jpg"}],
					[{caption: "Ullr-Amulett"}],
					[{museum: '13'}],
					[{audio: "VI_Kapitel5_Text3.mp3"}],

					[{
						par: "Vielleicht konnte auch ein besonderes Amulett vor Unfällen schützen? Ullr, eine mächtige Wintergottheit aus der nordischen Mythologie, wird auf Skiern, in Tierfelle gehüllt und mit einem Bogen aus Eibenholz dargestellt. Seit den 1950er Jahren galt er als Schutzpatron der Skifahrer und es gab eigene Talismane mit seiner Darstellung, die man einfach als „Ullr\" bezeichnete. Üblicherweise wurden diese Metallanhänger beim Skifahren am Hosenbund in einer Gürtelschlaufe befestigt. Sie konnten aber auch mehr oder weniger sichtbar am Skistock, am Rucksack oder in einer Tasche getragen werden. Bei unserem Ullr aus dem Felberturmmuseum in Mittersill ist das originale Lederbändchen noch erhalten."
					}],


					
					
					[{subheader: "Kapitel 6: Höher, schneller, weiter"}],

					[{
						jumpto: {
							refid: 18,
							year: 1913,
							jumptext: "1913",
							item: 246
						}
					}],

					[{dbImage: "44-bild59.jpg"}],
					[{caption: "Ö.S.V. Ehrenurkunde Rudolf Lettner"}],
					[{museum: '44'}],
					[{audio: "VI_Kapitel6_Text1.mp3"}],
					[{
						par: "Dass man auf Skiern bergab fahren oder sich im Gelände fortbewegen kann, ist allgemein bekannt. Auch, dass man sich mit ihnen, zumindest kurzzeitig, in die Lüfte erheben kann. Wussten Sie aber, dass bereits 1913 in Bad Ischl ein sogenannter „Sprunglauf\", also ein Skispringen stattfand? Das Bewertungssystem war bereits zukunftsweisend, mit 3 Dezimalstellen hinter dem Komma. Den Sieg mit 3 gestandenen Sprüngen, immerhin 16 ½ Meter (!) Weite und der Note 1.566 errang kein geringerer als Rudolf Lettner aus Hallein, der einige Jahre später die Stahlkante erfinden sollte und von dem wir schon gehört haben. Die schöne Ehrenurkunde im Jugendstildekor ist bis heute erhalten!"
					}],


					[{
						jumpto: {
							refid: 19,
							year: 1960,
							jumptext: "1960er",
							item: 246
						}
					}],


					[{dbImage: "13-bild1143.jpg"}],
					[{dbImage: "13-bild1143a.jpg"}],
					[{caption: "Blizzard Düsenski"}],
					[{museum: '13'}],
					[{audio: "VI_Kapitel6_Text2.mp3"}],
					[{
						par: "In den 1960ern bauten die Techniker des Skiherstellers Blizzard den Prototyp eines Rennsport- „Düsenskis\" aus flexiblem, lackiertem Hickoryholz, mit dem Ziel, die Skier durch einen Luftkisseneffekt zusätzlich zu beschleunigen. Es funktionierte vorzüglich, allerdings wurde damit der genau gegenteilige Effekt erreicht: Man musste nämlich feststellen, dass Skier ohne Bodenkontakt abbremsen. Einer dieser beiden ganz besonderen Skier hat sich im Felberturmmuseum Mittersill erhalten, der Verbleib des anderen ist unbekannt."
					}],

					[{
						jumpto: {
							refid: 20,
							year: 1991,
							jumptext: "1991",
							item: 246
						}
					}],


					[{dbImage: "13-bild1294.jpg"}],
					[{caption: "Torflagge WM 1991"}],
					[{museum: '13'}],
					[{audio: "VI_Kapitel6_Text3.mp3"}],
					[{
						par: "Nach 34 Jahren findet im Februar 2025 wieder eine Ski-WM in Saalbach statt, als „lässigste WM aller Zeiten\" und als Green Event. Zeit, zum Abschluss dieser Geschichte an die letzte WM 1991 zu erinnern. Die Voraussetzungen für das großzügig geplante Skiereignis schienen damals mehr als ungünstig. Einige Monate zuvor war der 2. Golfkrieg ausgebrochen und drohte nun zu eskalieren, sodass eine Absage der WM befürchtet werden musste. Die Eröffnungsfeier konnte dann doch, im Beisein des Bundespräsidenten stattfinden, aber in verkürzter Form und nur nach Protokoll. Dennoch wurde die Ski WM ein voller Erfolg. Traumhaftes Wetter begleitete die gesamte Veranstaltung, es gab es spannende Wettkämpfe und überragende Siege, nicht zuletzt durch das junge Ausnahmetalent Rudi Nierlich."
					}],
					[{
						par: "Die Torflagge erinnert an den geradezu legendären Riesentorlauf, den Rudi Nierlich trotz eines Innenskifehlers, bei dem jeder andere gestürzt wäre, überragend gewann. Nierlich war einfach viel schneller als alle anderen Läufer! Bei jeder Torflagge stand damals ein Torrichter, der darauf achtete, dass der Läufer das Tor korrekt passierte, der bei Bedarf Stangen und Flaggen wieder richtete und Schäden an der Piste sofort meldete. Nach Ende des Laufs wurden die Flaggen eingesammelt. Einer der Torrichter behielt „seine\" Torflagge zum Andenken und übergab sie viele Jahre später dem Felberturmmuseum in Mittersill."
					}],
					[{extImage: "VI_torrichter.jpg"}],
					[{caption: "Torrichter WM 1991 (© Heimathaus und Schimuseum Saalbach/Edith Danzer )"}],
					[{audio: "VI_Kapitel6_Text4.mp3"}],
					[{
						par: "Apropos Torrichter: Um auf der Piste gut sichtbar zu sein, waren sie einheitlich und vor allem entsprechend auffällig gekleidet. Im Heimathaus und Schimuseum Saalbach befindet sich diese Figurine mit Torrichter-Outfit von der WM 1991."
					}],
					[{
						par: "(Vlad Cotuna und Hemma Ebner, sowie Wolfgang Dreier-Andres, Benjamin Gauß und Sandra Gauß-Haarich, 2025)"
					}],

				]
			}
		]
]

export default storyContent;

/*
var storyContent = [

	// ... existing stories 1-4 remain unchanged ...
	[
		{
			story_id: 5,
			header: 'Lieblingsstücke',
			content: [
				[{extImage: 'V_Titelbild_und_Einleitung.jpg'}],
				[{audio: 'V-0_Einleitung.mp3'}],
				[{
					par: 'In dieser Geschichte kommen jene zu Wort, die in den Salzburger Regionalmuseen unmittelbar am Ort des Geschehens sind: Museumsleiterinnen und Museumsleiter, Kustodinnen und Kustoden. Sie alle haben ihr ganz persönliches Lieblingsstück, das sie uns hier vorstellen. Die Auswahl ist so vielfältig wie die Salzburger Museumslandschaft – lassen Sie sich überraschen!'
				}],
				
				[{subheader1: 'Gondel der Stubnerkogelbahn'}],
				[{dbImage: '37-4019_KGDB.jpg'}],
				[{dbImage: '37-4019a_KGDB.jpg'}],
				[{caption: 'Gondel Stubnerkogelbahn'}],
				[{museum: '37'}],
				[{audio: 'V-1_37-4019_Stubnerkogelbahn.mp3'}],
				[{
					par: 'Siegfried Moser ist der Obmann des Gasteiner Museums in Bad Gastein (Pongau). Sein Lieblingsstück ist diese besondere Seilbahngondel. Warum sie jedoch ein Prototyp bleiben musste, erzählt er hier.'
				}],
				
				[{
					jumpto: {
						refid: 0,
						year: 1951,
						jumptext: '1951',
						item: 246,
					}
				}],
				[{subheader1: 'Marmor-Jause'}],
				[{dbImage: '2-1120_KGDB.jpg'}],
				[{caption: 'Marmor-Jause'}],
				[{museum: '2'}],
				[{audio: 'V-2_2-1120_Marmor-Jause.mp3'}],
				[{
					par: 'Heinz Schierhuber leitet das Untersbergmuseum in Grödig-Fürstenbrunn (Flachgau). Für uns hält er diese „kalorienarme" Jause bereit.'
				}],
				[{
					jumpto: {
						refid: 1,
						year: 1990,
						jumptext: '1990',
						item: 246,
					}
				}],
				[{subheader1: 'Modell der Pulverstampfe'}],
				[{dbImage: '12-0_KGDB.jpg'}],
				[{caption: 'Modell der Pulverstampfe'}],
				[{museum: '12'}],
				[{audio: 'V-3_12-0_Pulverstampfe.mp3'}],
				[{
					par: 'Sepp Grabner ist Kustos im Museum Zum Pulvermacher in Elsbethen (Flachgau). Ihn fasziniert die einfache, aber effiziente Technik der Schießpulvererzeugung, die noch völlig ohne Strom auskam.'
				}],
				
				[{
					jumpto: {
						refid: 2,
						year: 2003,
						jumptext: '2003',
						item: 246,
					}
				}],
				[{subheader1: 'Messingsieb'}],
				[{dbImage: '33-634_KGDB.jpg'}],
				[{dbImage: '33-634a_KGDB.jpg'}],
				[{caption: 'Messingsieb'}],
				[{museum: '33'}],
				[{audio: 'V-4_33-634_Messingsieb.mp3'}],
				[{
					par: 'Wilhelm Leitner leitet das Museum im Fürstenstöckl in Ebenau (Flachgau), dessen Schwerpunkt auf der hiesigen vorindustriellen Messingproduktion und -Verarbeitung liegt. Sein Lieblingsstück ist dieses außergewöhnliche Küchengerät.'
				}],
				
				[{
					jumpto: {
						refid: 3,
						year: 1800,
						jumptext: '1800',
						item: 246,
					}
				}],
				[{subheader1: 'Herzog Tassilo III.'}],
				[{dbImage: '50-35_KGDB.jpg'}],
				[{caption: 'Herzog Tassilo III.'}],
				[{museum: '50'}],
				[{audio: 'V-5_50-35_Tassilo.mp3'}],
				[{
					par: 'Josef Sturm ist Leiter des Stiftsmuseums in Mattsee (Flachgau). Beim Barockgemälde mit Darstellung des Mattseer Gründervaters Tassilo III. erinnert er an die Verdienste dieses „vergessenen" und doch so bedeutenden letzten Baiernherzogs.'
				}],
				
				[{
					jumpto: {
						refid: 4,
						year: 1700,
						jumptext: '1700',
						item: 246,
					}
				}],
				[{subheader1: 'Unterkiefer eines Höhlenlöwen'}],
				[{dbImage: '28-850_KGDB.jpg'}],
				[{caption: 'Unterkiefer eines Höhlenlöwen'}],
				[{museum: '28'}],
				[{audio: 'V-6_28-850_Höhlenlöwe.mp3'}],
				[{
					par: 'Sebastian Krutter leitet das Museum Burg Golling (Tennengau). Sein Lieblingsstück ist zugleich ein wichtiger Beleg für die eiszeitliche Tierwelt des Gebietes rund um Golling.'
				}],
				
				[{
					jumpto: {
						refid: 5,
						year: -50000,
						jumptext: '-50.000',
						item: 246,
					}
				}],
				[{subheader1: 'Gewölbefragment'}],
				[{dbImage: '74-2802_KGDB.jpg'}],
				[{caption: 'Gewölbefragment'}],
				[{museum: '74'}],
				[{audio: 'V-7_74-2802_Gewölbefragment.mp3'}],
				[{
					par: 'Erich Erker, bis 2024 Leiter des Museums Vogtturm in Zell am See (Pinzgau) zeigt uns sein Lieblingsstück, das erst durch eine Ausgrabung entdeckt wurde und wichtige Rückschlüsse auf die mittelalterliche Baugeschichte der Stadtpfarrkirche Zell am See ermöglichte.'
				}],
				
				[{
					jumpto: {
						refid: 6,
						year: 1100,
						jumptext: '1100',
						item: 246,
					}
				}],
				
				[{subheader1: 'Schnapsbrennerei'}],
				[{dbImage: '12-834_KGDB.jpg'}],
				[{caption: 'Schnapsbrennerei'}],
				[{museum: '12'}],
				[{audio: 'V-8_12-834_Schnapsbrennerei.mp3'}],
				[{
					par: 'Ingrid Thurner ist Kustodin im Museum Zum Pulvermacher in Elsbethen (Flachgau) und stammt selbst aus einer Pulvermacherfamilie. Das Museum, untergebracht im ehemaligen Pulvermacher-Zuhaus, weckt ihre Kindheitserinnerungen.'
				}],
				[{
					jumpto: {
						refid: 7,
						year: 1950,
						jumptext: '1950',
						item: 246,
					}
				}],
				
				[{subheader1: 'Bleibehälter mit Brevemassa'}],
				[{dbImage: '15-955_KGDB.jpg'}],
				[{dbImage: '15-955a_KGDB.jpg'}],
				[{caption: 'Bleibehälter mit Brevemassa'}],
				[{museum: '15'}],
				[{audio: 'V-9_15-955_Brevemassa.mp3'}],
				[{
					par: 'Klaus Heitzmann leitet das Lungauer Heimatmuseum in Tamsweg (Lungau). Ein seltsamer Dachbodenfund führt weit zurück in die Geschichte des südlichsten Salzburger Bezirks.'
				}],
				[{
					jumpto: {
						refid: 8,
						year: 1682,
						jumptext: '1682',
						item: 246,
					}
				}],
				
				[{subheader1: 'Hieronymushaus-Modell'}],
				[{dbImage: '9-283_KGDB.jpg'}],
				[{dbImage: '9-283a_KGDB.jpg'}],
				[{dbImage: '9-283b_KGDB.jpg'}],
				[{caption: 'Hieronymushaus-Modell'}],
				[{museum: '9'}],
				[{audio: 'V-10_9-283_Hieronymushaus.mp3'}],
				[{
					par: 'Elisabeth Pohl ist Vizeobfrau des Montandenkmals Altböckstein (Pongau). Die Bergbau- und Sozialgeschichte des Gasteinertals ist eines ihrer großen Interessensgebiete.'
				}],
				[{
					jumpto: {
						refid: 9,
						year: 2012,
						jumptext: '2012',
						item: 246,
					}
				}],
				
				[{subheader1: 'Biblische Kastenkrippe'}],
				[{dbImage: '20-1857_KGDB.jpg'}],
				[{dbImage: '20-1857a_KGDB.jpg'}],
				[{dbImage: '20-1857b_KGDB.jpg'}],
				[{dbImage: '20-1857c_KGDB.jpg'}],
				[{caption: 'Biblische Kastenkrippe'}],
				[{museum: '20'}],
				[{audio: 'V-11_20-1857_Kastenkrippe.mp3'}],
				[{
					par: 'Traudi Pirnbacher ist Kustodin im Seelackenmuseum in St. Veit (Pongau). Ihr Lieblingsstück ist diese umfangreiche Kastenkrippe und deren bewegte Geschichte.'
				}],
				[{
					jumpto: {
						refid: 10,
						year: 1837,
						jumptext: '1837',
						item: 246,
					}
				}],
				
				[{subheader1: 'Ein Maurer als Krippenfigur'}],
				[{dbImage: '11-13469_KGDB.jpg'}],
				[{caption: 'Ein Maurer als Krippenfigur'}],
				[{museum: '11'}],
				[{audio: 'V-12_11-13469_MaurerKrippe.mp3'}],
				[{
					par: 'Andrea Dillinger leitet das Museum Schloss Ritzen in Saalfelden (Pinzgau) und ist Landesobfrau des Landesverbandes Salzburger Museen und Sammlungen. Sie begeistert sich für diese originelle Krippenfigur.'
				}],
				[{
					jumpto: {
						refid: 11,
						year: 1953,
						jumptext: '1953',
						item: 246,
					}
				}],
			],
		}
	]
];
*/


