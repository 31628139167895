import React, {Suspense, useState, useRef, useEffect, useLayoutEffect, useCallback, useMemo} from 'react'
import "../css/story.css";
import extLink from '../assets/extlink.png';
import {useStore} from './store';
import {Html, Line} from "@react-three/drei";
import {getItemsImages, hexToRgbA, mtrack} from "../utils/helpers";
import {rescaleYears} from "../utils/helpers";
import {useInterval, useWindowDimensions} from "../utils/hooks";
import {useFrame} from '@react-three/fiber';
import * as THREE from "three";
import ItemDescription from "./description";
import Lightbox from 'react-image-lightbox';
import storyContent from '../resource/storycontent';
import '../css/lightbox.css';
import {getElement} from "bootstrap/js/src/util";
import ShowAudio from "./audio"; // This only needs to be imported once in your app
import useInView from "react-cool-inview";
import _ from 'lodash';

//import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

//import throttle from 'lodash-es/throttle'

export function Story() {
//export const Story = React.memo(() => {

//	console.log("------------- story started ----------");

	const {height, width} = useWindowDimensions();

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);
	const isSafari = useStore(state => state.isSafari);

	const showTour = useStore(state => state.showTour);
	const setShowTour = useStore(state => state.setShowTour);

	const itemsList = useStore(state => state.items);
	const museumsList = useStore(state => state.museums);

	const setShowLightbox = useStore(state => state.setShowLightbox);
	const imageIndex = useStore(state => state.imageIndex); // lightbox
	const setImageIndex = useStore(state => state.setImageIndex); // lightbox
	const setImageIndexPos = useStore(state => state.setImageIndexPos); // lightbox

	const overlay = useStore(state => state.overlay);
	const overlayBackgroundColor = useStore(state => state.overlayBackgroundColor);
	const sceneColors = useStore(state => state.color);

	const vec = new THREE.Vector3();
	const targetVec = new THREE.Vector3();

	// next 2 lines create force_update-like hook
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);

	const [hovered, setHovered] = useState(false); // mouse over popup
	const [opacityRefLine, setOpacityRefLine] = useState(0); // refline
	const setHoveredPopup = useStore((state) => state.setHoveredPopup);

	// show or hide popup
	const [hidden, setHidden] = useState(true);
	const [stayOnScreen, setStayOnScreen] = useState(false);

//	const [shouldTruncate, setShouldTruncate] = useState(true);

	const scr = useStore(state => state.scrolling);  // for normal scrolling with wheel
	const scrJump = useStore(state => state.scrollingJump); // for jumps (story/forward- & back-button)
	const setJumpDistance = useStore(state => state.setJumpDistance);
	const setJumpRegion = useStore(state => state.setJumpRegion);
	const setAnimateYearJump = useStore(state => state.setAnimateYearJump);

	const closePopupState = useStore(state => state.closePopup);
	const setClosePopupState = useStore(state => state.setClosePopup);


	const selected = useStore(state => state.selected); // mouse over item
	const setSelected = useStore(state => state.setSelected); // mouse over item

	// which story
	const selectedStory = useStore(state => state.selectedStory);
	const setSelectedStory = useStore(state => state.setSelectedStory);

//	var currentStory = selectedStory && typeof selectedStory !== 'undefined' ? storyContent[selectedStory][0] : storyContent[0][0];
//	var currentContent = currentStory.content;
	const currentStory = selectedStory >= 0 ? storyContent[selectedStory][0] : null;
	const currentContent = currentStory ? currentStory.content : [];

	const [lastSelected, setLastSelected] = useState(-1); // last selected item, changes only when a valid new item is selected
	const hoveredCoords = useStore(state => state.hoveredCoords); // coords of item

	var toggleRefline = 'reflineC';        // initial key for refline, ready to be toggle to force re-render

	const popupStoryRef = useRef();
	const storyRef = useRef();
	const [initListener, setInitListener] = useState(false);          // init popup transition listener
	const [cssInTransition, setCssInTransitions] = useState(false);       // track popup transitions

	const popupStoryTitleRef = useRef();
	const popupStoryTitleRefStop = useRef();

	const refVis = useRef([]);

	const [lastVisible, setLastVisible] = useState(0);
	const [visible, setVisible] = useState(false);
	const [afterFirstLoad, setAfterFirstload] = useState(false);

//	const setScrollingJump = useStore(state => state.setScrollingJump)
//	const setUpdateScrollPosition = useStore(state => state.setUpdateScrollPosition)

	const [jump, setJump] = useState(false);

	const circleColor = "#f60060";
	const iconColor = 'white'

	const [hoverBack, setHoverBack] = useState(false);
	const [hoverFwd, setHoverFwd] = useState(false);

	const [item, setItem] = useState();

	const [init, setInit] = useState(false);
	const [initObservers, setInitObservers] = useState(false);

	const setCameraStoryAnimationStopped = useStore(useCallback(state => state.setCameraStoryAnimationStopped, [hidden])); // animation of camera
	const cameraStoryAnimationStopped = useStore(useCallback(state => state.cameraStoryAnimationStopped, [hidden])); // animation of camera

	const setShowStoryReferenceLine = useStore(useCallback(state => state.setShowStoryReferenceLine, [scr, scrJump, hovered]));
	const showStoryReferenceLine = useStore(useCallback(state => state.showStoryReferenceLine, [scr, scrJump, hovered])); // show referenceLine

	const isInView = useStore(state => state.isInView);
	const resetIsInView = useStore(state => state.resetIsInView);

	const isJumpInView = useStore(state => state.isJumpInView);
	const setIsJumpInView = useStore(state => state.setIsJumpInView);

	const [offset, setOffset] = useState(0);

	const scrolling = useStore(state => state.scrolling);
	const [hideRefonScroll, setHideRefonScroll] = useState(false);

	const [count, setCount] = useState(0);

	const [lastlastselected, setLastlastselected] = useState(0);


//
//	useEffect(() => {
//		if (count == -1) {
//			console.log('reload', popupStoryRef)
//			if (!popupStoryRef.current) {
//				setTimeout(() => {
//					console.log('reload timeout')
////					setInit(false)
////					setInitListener(false)
////					setCount(1)
//				}, 4000)
//			}
//		}
//	});

//	console.log('initstates', refVis, popupStoryTitleRef, popupStoryTitleRefStop, selected, selectedStory)
//	console.log('initstates', init, initObservers, initListener, count)
//	console.log('selected', selected)
//	console.log('inview', isInView)


	useEffect(() => {
		// console.log('selection changed ===> ', selected)
		if (selected === lastlastselected) {
			//console.log('===> toggling', scrolling)
//			alert('toggle')
			setInit(false)
//			setSelected(lastSelected)
		}
		setLastlastselected(lastSelected)

		if (refVis && refVis.current) {
			//console.log(refVis, refVis.current, refVis.current[0])
			if (!refVis.current[0] || refVis.current[0] === undefined) {
				//console.log('********* refVis recreated')
				setInit(false)
				setInitObservers(false)
			}
		}
	}, [selected])

	// The scroll listener
	const handleScroll = useCallback(() => {
//		console.log("scrolling")
		HideRefLine();
		setHideRefonScroll(true);
		setCount(0);
	}, [])

	// close popup if counter > 3000
	useEffect(() => {
//		if (count % 1 === 0) console.log("===> count: ", count);
		if (count > 2) {
			setHideRefonScroll(false)
			stopTimer()
		}

	}, [count])

	// call custom hook: counter
	useInterval(() => {
		// Your custom logic here
		if (count === -1) {
			// stop timer, do nothing
		} else {
			setCount(count + 1);
		}
	}, 700);

	function startTimer() {
		setCount(0)
	}

	function stopTimer() {
		setCount(-1)
	}


	// Attach the scroll listener to the div
	useEffect(() => {

		resetIsInView();

		const div = storyRef.current
		if (div) {
//			console.log("adding scroll listener");
			div.addEventListener("scroll", _.throttle(handleScroll, 1000))
//			div.addEventListener("scroll", handleScroll())
		}
		return () => {
//			console.log("remove scroll listener");
			if (div) {
				div.removeEventListener("scroll", handleScroll());
			}
		}
	}, [init])

	useLayoutEffect(() => {
		if (useStore.getState().scrolling) {
//			console.log('usestorestore scrolling')
			HideRefLine();
		}
	})

//	useEffect(() => {
////		const scollcontainer = document.getElementsByClassName('firstStorycontainer')
//		const scollcontainer = window;
//		const onScroll = () => setOffset(scollcontainer.scrollY);
//		// clean up code
//		scollcontainer.removeEventListener('scroll', onScroll);
//		scollcontainer.addEventListener('scroll', onScroll, { passive: true });
//		return () => scollcontainer.removeEventListener('scroll', onScroll);
//	}, []);
//
//	console.log("=== o f f s e t ==>",offset);

	useEffect(() => {
		setSelected(-1);
		setLastSelected(-1);

		var yearArray = [];
		var itemArray = [];

		currentContent.map((d) => {
			if (d[0].jumpto) {
				yearArray.push(d[0].jumpto.year)
				itemArray.push(d[0].jumpto.item)
			}
		});

		const out = {
			year: yearArray,
			id: itemArray
		};
		setItem(out);
		setInit(true);

		return (() => {
				setSelected(-1)
				setLastSelected(-1)
				setHovered(-1)
			}
		)

	}, [init])

	// reference line is hidden when camera slides or user scrolls
	useEffect(() => {
		if (overlay === "vertical") {
//			cameraStoryAnimationStopped === false || scr === true || scrJump === true || visible === false ? HideRefLine() : ShowRefLine()
//			cameraStoryAnimationStopped === false || scr === true || scrJump === true || visible === false ? HideRefLine() : ShowRefLine()
			cameraStoryAnimationStopped === false || scr === true || scrJump === true || isInView.length < 1 ? HideRefLine() : ShowRefLine()
//			cameraStoryAnimationStopped === false || scr === true || scrJump === true ? HideRefLine() : ShowRefLine()
		} else {
//			!scr && !scrJump && !hidden && !cssInTransition ? ShowRefLine() : HideRefLine()
		}
	})

	// hold current selection, even if it is not hovered anymore
	useEffect(() => {
		if (selected > -1 && selected !== lastSelected) {
			setLastSelected(selected)
//			setLastSelected(-1)
//			RestartRefline();
		}
	}, [selected, hovered])


	// event listener tracking the css animation of the popup, sets variable cssInTransition which hides the reference line
	useEffect(() => {
		if (popupStoryRef.current && !initListener) {
			popupStoryRef.current.addEventListener('transitionstart', (e) => {
				//console.log("init listener ######")
				e.stopPropagation();
				if (e.propertyName === 'transform') {
					setCssInTransitions(true);
					//console.log("start: ", e, e.propertyName, e.target)
				}
			})
			popupStoryRef.current.addEventListener('transitionend', (e) => {
				e.stopPropagation();
				if (e.propertyName === 'transform') {
					setCssInTransitions(false);
					//console.log("end: ", e, e.propertyName, e.target)
				}
				setInitListener(true);
			})
		}
	}, [initListener, hidden])


	// close popup if empty space was clicked
	useLayoutEffect(() => {
		if (closePopupState === true) {
			closePopup();
			setClosePopupState(false);
		}
	}, [closePopupState])


	// move camera, popup slide in
	let t = 0;
	useFrame((state) => {
		if (overlay === "vertical" && showTour) {
			t = t + 1;
			const step = 0.08
			targetVec.x = -Math.PI / 2 * 1.5;
			targetVec.y = isMo1 && !isLandscape ? -37 : -30;
			targetVec.z = isMo1 && !isLandscape ? 8.5 : 5;
			let animate = hidden;
			state.camera.fov = THREE.MathUtils.lerp(state.camera.fov, animate ? 30 : 32, step)
			let rr = state.camera.position.lerp(vec.set(animate ? 0 : targetVec.x, animate ? -30 : targetVec.y, animate ? 5 : targetVec.z), step)
//			state.camera.lookAt(1+Math.PI/2, 2000, 20)
			state.camera.updateProjectionMatrix()
//			light.current.position.lerp(vec.set(zoom ? 4 : 0, zoom ? 3 : 8, zoom ? 3 : 5), step)
//			if (state.camera.position.distanceTo(targetVec) < 0.01) { console.log("lerp finished", rr);}

			if (t > 60) {
				t = 0;
//				console.log("camera animation", rr.x, targetVec.x+0.1);
				if (rr.x < targetVec.x + 0.3) {
					if (cameraStoryAnimationStopped === false) {
//						console.log("#### camera animation stopped", rr.x, targetVec.x + 0.1);
						setCameraStoryAnimationStopped(true)
					}
					t = 0;
				} else {
//					console.log("back to normal", rr.x);
					setCameraStoryAnimationStopped(false)
					t = 0;
				}
			}
		}
	})

	// start timer when hovered
	useEffect(() => {
		//console.log("do not start counter:", hovered);
		setHoveredPopup(hovered);
	}, [hovered])

	useEffect(() => {
//		console.log('showtoureffect');
		if (showTour) {
//			console.log('showtoureffect1');
			setStayOnScreen(true);
			setHidden(showTour === true ? false : true)
//			setShowStoryReferenceLine(selected === -1 ? 0 : 1)
			setShowStoryReferenceLine(selected === -1 ? 0 : 1)
//			stopTimer()
		}
		// add logic here if nothing is selected
//		if (selected === -1 && !hovered) {
//			RestartRefline();
	}, [showTour])


//	// forceupdate to restart truncate after animation finished
//	useEffect(() => {
//		console.log("forceupdate started");
//		setTimeout(() => hidden ? null : forceUpdate(), 300);
//	}, [hidden, shouldTruncate])

	useLayoutEffect(() => {
		if (!hideRefonScroll) {
			ShowRefLine();
		}
	}, [hideRefonScroll]);


	function HideRefLine() {
		setShowStoryReferenceLine(false);
	}

	function ShowRefLine() {
		if (!hideRefonScroll)
			setShowStoryReferenceLine(true);
	}

	function RestartRefline() {
//		console.log("restarting")
//		HideRefLine();
		setTimeout(() => {HideRefLine()}, 1);
		setTimeout(() => {ShowRefLine()}, 2);
	}


	// open a image in lightbox
	const onImageclick = (d) => {
//		console.log("!!! story-lightbox image clicked");
		mtrack('story-lightbox image ' + d.filename1);
		setShowLightbox(true);
		setImageIndex(d);
		setImageIndexPos(0);
	}

	function HoveredCoords() {
//		console.log("hoveredCoords", hoveredCoords);
		return hoveredCoords
	}

	function ReferenceLine() {
//		console.log("refline called");

		if (popupStoryTitleRef.current) {
//			setTimeout(()=>{
			const coords = popupStoryTitleRef.current.getBoundingClientRect();
			const coordsStop = popupStoryTitleRefStop.current.getBoundingClientRect();

//			console.log("QQ: coords", coords, coordsStop)

			if (overlay === "vertical") {
				let x = coords.left + coords.width;
				let y = isMo1 && !isLandscape ?
					//					coords.bottom + (coordsStop.bottom - coords.bottom) / 1.2 :
					(coordsStop.bottom > height * 0.6 ? coords.top + 50 : coords.bottom + (coordsStop.bottom - coords.bottom) / 1.2) :
					coords.top + (coordsStop.top + coords.top) / 4;
				return (
					<svg height={height} width={width} opacity={useStore.getState().scrolling ? 0 : 0.6}>
						{(!isMo1 || isLandscape) &&
							<g>
								<filter id="shadow" x="0" y="0" width="200%" height="200%">
									<feDropShadow dx="2" dy="3" stdDeviation="2.5" floodColor="#000"
									              floodOpacity="0.5"/>
								</filter>
								<line x1={x} y1={y} x2={hoveredCoords.x} y2={hoveredCoords.y}
								      stroke="white" strokeWidth="3"
								      strokeLinejoin="round"
								      filter="url(#shadow)"
								/>
								<line x1={x - coords.width / 15} y1={y} x2={x} y2={y}
								      stroke="white" strokeWidth="3"
								      strokeLinecap="round"
								/>
							</g>
						}
						{isMo1 && !isLandscape &&
							<g>
								<filter id="shadow" x="0" y="0" width="200%" height="200%">
									<feDropShadow dx="2" dy="3" stdDeviation="2.5" floodColor="#000"
									              floodOpacity="0.5"/>
								</filter>
								<line x1={x + 5} y1={height / 100 * 60} x2={hoveredCoords.x} y2={hoveredCoords.y}
								      stroke="white" strokeWidth="3"
								      strokeLinejoin="round"
								      filter="url(#shadow)"
								/>
								<line x1={x + 5} y1={y} x2={x + 5} y2={height / 100 * 60}
								      stroke="white" strokeWidth="3"
								      strokeLinejoin="round"
									//								      filter="url(#shadow)"
								/>
								<line x1={x - coords.width / 12} y1={y} x2={x + 5} y2={y}
								      stroke="white" strokeWidth="3"
								      strokeLinecap="round"
								/>
							</g>
						}


						<circle cx={hoveredCoords.x} cy={hoveredCoords.y} r="4" stroke="white" strokeWidth="0"
						        fill="white"/>
					</svg>
				)
			} else {
				let x = coords.left + coords.width;
				let y = coords.top + coords.height + 10;
				return (
					<svg height="2000" width="3000" opacity={0.6}>
						<line x1={x} y1={y} x2={hoveredCoords.x} y2={hoveredCoords.y} stroke="white" strokeWidth="3"/>
						<line x1={x} y1={y} x2={x - coords.width} y2={y} stroke="white" strokeWidth="3"/>
						<circle cx={hoveredCoords.x} cy={hoveredCoords.y} r="4" stroke="white" strokeWidth="0"
						        fill="white"/>
					</svg>
				)
			}
//			}, 1000)
		}
		return null

	}

	function ShowHoveredCoords() {
		return (<p>coords: <br/> x: {hoveredCoords.x} <br/> y: {hoveredCoords.y}<br/> z: {hoveredCoords.z}</p>)
	}

	function closePopup() {
		setStayOnScreen(false);
		setHidden(true);
		setShowTour(false);
		setHoveredPopup(false);
		setCameraStoryAnimationStopped(false);
		setSelectedStory(-1);
		setHovered(-1);
		setSelected(-1);
		HideRefLine();
		mtrack('story closed');
	}

	useEffect(() => {
		if (selected === -1 || lastSelected === -1) {
//			console.log(">>> hide on startup <<<");
			HideRefLine();
		}
	}, [selected])


	const startjump = (d, r) => {
//		console.log("jump", d, r)
		setJumpDistance(-rescaleYears(d));
		setJumpRegion(isMo1 && !isLandscape ? r : -1);
		setTimeout(() => {
			setAnimateYearJump(true);
			mtrack('story jump: ' + d);
		}, 500)
	}


	useEffect(() => {
		// console.log("############---- visi changed", lastVisible, hidden, visible, isInView);

		if (isInView) {
			let last = isInView.length > 0 ? isInView[0] : -1;
			if (itemsList[last] && itemsList[last].yearfine) {
				startjump(itemsList[last].yearfine, itemsList[last].region)
			}
			setSelected(last);
			setLastSelected(last);
		}
		// todo: this does not hide the refline as intended
		else {
			setSelected(-1);
			setLastSelected(-1);
			HideRefLine();
		}

	}, [lastVisible, hidden, isInView])


	const [forward, setForward] = useState(false);
	const [back, setBack] = useState(false);

	// block start places scrollpoint to the start of the container
	const executeScroll = (d) => {

		if (refVis.current && refVis.current[d]) {
			refVis.current[d].scrollIntoView({behavior: 'smooth', block: 'start'})
		} else {
			// try it once more
			setTimeout(() => {
				if (refVis.current && refVis.current[d]) {
					refVis.current[d].scrollIntoView({behavior: 'smooth', block: 'start'})
				}
			}, 50)
		}
		resetIsInView();
	}


	useEffect(() => {
		if (forward) {
//			console.log("forward clicked");
			executeScroll(isJumpInView < item.id.length - 1 ? isJumpInView + 1 : isJumpInView);
			setForward(false);
		}
	}, [forward])


	useEffect(() => {
		if (back) {
//			console.log("back clicked", lastVisible);
			executeScroll(isJumpInView > 0 ? isJumpInView - 1 : isJumpInView);
			setBack(false)
		}
	}, [back])


	function handleTrackingClick(e) {
		const id = parseInt(e.target.id);
		executeScroll(id);
	}

	useEffect(() => {
		if (init) {
			refVis.current = refVis.current.slice(0, item.id.length);
		}
	});

	useEffect(() => {
		setTimeout(() => {
			setAfterFirstload(true)
		}, 2500)
	}, [afterFirstLoad]);

//	console.log("isVisible", lastVisible, hidden);
//	console.log("+++++ color ++++", useStore.getState().landscapeColor);


	const ShowItem = (d) => {
		return (
			<div>
				{overlay === "vertical" &&
					<h4>
						{d.year}
					</h4>
				}
			</div>
		)
	}


	return (
		<group>
			<Html
				calculatePosition={() => [0, 0, 0]}
				occlude
				onOcclude={null}
				zIndexRange={[2000, 1000]}
				style={{
					pointerEvents: "none",
					opacity: 1,
				}}
			>
				<div className={showStoryReferenceLine ? "screenoverlay fade-in" : "screenoverlay fade-out"}>
					{/*{showStoryReferenceLine &&*/}
					<ReferenceLine
						key={toggleRefline === "reflineC" ? "reflineD" : "reflineC"}
					>

					</ReferenceLine>
					{/*}*/}
				</div>
			</Html>
			<Html
				ref={popupStoryRef}
				calculatePosition={() => [0, 0, 0]}
				occlude
				onOcclude={setHidden}
				zIndexRange={[300, 200]}
				style={{
					transition: 'all 0.4s',
					opacity: hidden ? 0 : 1,
					transform: `scale(${hidden ? [1, 0] : [1, 1]})`
				}}
			>
				<div className='lineContainer'
				     style={{
					     top: isMo1 ? isLandscape ? '35px' : '50px' : '50px',
					     width: isMo1 ? isLandscape ? '40vw' : '95vw' : '29vw'
				     }}>
					<div className='dashedline' style={{width: '100%'}}/>
				</div>

				<div
					className='verticalStory'
					ref={storyRef}
					style={{
						opacity: 1,
						backgroundColor: hexToRgbA(overlayBackgroundColor, 0.6),
						width: isMo1 ? isLandscape ? '42vw' : '100vw' : '30vw',
						height: !isLandscape && isMo1 ? '60vh' : '100vh',
						fontSize: isMo1 ? isLandscape ? '0.7em' : '0.8em' : '0.9vw',
						overflowX: 'hidden',
					}}
					onMouseEnter={(e) => {
						setHovered(true)
					}}
					onMouseLeave={(e) => {
						setHovered(false)
					}}
					onTouchStart={(e) => {
						setHovered(true)
					}}
					onTouchEnd={(e) => {
						setHovered(false)
					}}
				>

					{init && showTour && <div className='storytrackerContainer'
					                          style={{
						                          backgroundColor: useStore.getState().landscapeColor === "#D7D7D7" ? '#808080' : '#9598A7',
						                          width: isMo1 ? isLandscape ? '40vw' : '95vw' : '29vw',
						                          height: isMo1 ? isLandscape ? '35px' : '50px' : '50px',
						                          justifyContent: isMo1 ? isLandscape ? 'space-evenly' : 'space-evenly' : 'space-evenly',
					                          }}>

						{!isMo1 && isLandscape &&
							<div className='trackicon storytrackerItem'
							     style={{width: '1vmax', margin: '0px -1vmax 0px 0px'}}>
								<svg className='umbrella1' x="0px" y="0px" width="100%" height="100%"
								     viewBox="0 0 116 255.333">
									<path fill={iconColor} d="M60.342,184.341"/>
									<path fill={iconColor} d="M57.388,185.954"/>
									<polyline fill={iconColor} points="60.365,184.341 60.365,184.312 60.342,184.341 "/>
									<line x1="60.62" y1="184.191" x2="60.62" y2="184.191"/>
									<path fill={iconColor} d="M58.334,186.334v34.311c1,17.922-6.426,19.869-9.899,19.869c-9.299,
							0-12.231-0.18-12.231-19.18H33.62h-3.417 c0,19,1.944,24.18,18.231,24.18c13.809,0,15.899-8.919,
							15.899-24.807v-34.373H58.334z"/>
									<polygon fill={iconColor} points="106.121,186.334 79.745,164.388 106.121,149.04 60.371,
							8.709 14.245,147.638 41.745,164.455 14.621,186.334 "/>
									<path fill={iconColor} d="M41.414,163.466"/>
									<path fill={iconColor} d="M60.289,163.33"/>
								</svg>
							</div>
						}

						{!isMo && item.year.length < 20 &&
							<div className='storytrackerItem tracktext'
							     style={{
								     paddingRight: isMo1 ? isLandscape ? '20px' : '10px' : '10px',
								     paddingLeft: isMo1 ? isLandscape ? '5px' : '10px' : '20px',
							     }}
							>Geschichten</div>
						}
						<div className='storytrackerItem tracklane' style={{
							flexBasis: isMo1 ? isLandscape ? '100%' : '100%' : '50%',
//							flexGrow: isMo1 ? isLandscape ? '100%' : '100%': '50%',
						}}>

						<span
							onClick={() => {setBack(true)}}
							onMouseOver={() => setHoverBack(true)}
							onMouseOut={() => setHoverBack(false)}
							style={{cursor: isJumpInView > 0 ? 'pointer' : 'default'}}
						>
							<svg height={isMo1 ? isLandscape ? '25' : '30' : "20"}
							     width={isMo1 ? isLandscape ? width / 50 : width / 20 : width / 100}
							     viewBox="0,0,20,20">
								<polygon className={'trackControlTriangle'}
								         fillOpacity={isJumpInView > 0 ? 1 : 0.2}
								         strokeOpacity={isJumpInView > 0 ? 1 : 0.2}
								         fill={hoverBack && isJumpInView > 0 ? 'red' : 'white'}
								         points="10,0 0,10 10,20 "/>
							</svg>
						</span>

							{item.year.map((d, i) => {
								let mscale = isMo1 ? isLandscape ? 1.4 : 3.8 : 1;
								return (
									<span key={'track_' + i} onClick={handleTrackingClick} style={{cursor: 'pointer'}}>
									<svg height="20"
									     width={isMo1 ? isLandscape ? (width / 65) : (width / 1000) * 35 : width / 110}
									     style={{backgroundColor: hexToRgbA('#444444', 0.01),}}>
										<circle className={'trackControlCircle'} id={i} cx={(width / 360 + mscale + 2)}
										        cy="10"
										        r={(width / 360) * mscale}
										        strokeWidth={isJumpInView === i ? 2 : 2}
										        fillOpacity={isJumpInView === i ? '100%' : "1%"}/>
										/>
									</svg>
								</span>
								);
							})}
							<span
								onClick={() => {setForward(true)}}
								onMouseOver={() => setHoverFwd(true)}
								onMouseOut={() => setHoverFwd(false)}
								style={{cursor: isJumpInView < item.id.length - 1 ? 'pointer' : 'default'}}
							>
						<svg height={isMo1 ? isLandscape ? '25' : '30' : "20"}
						     width={isMo1 ? isLandscape ? width / 50 : width / 20 : width / 100} viewBox="0,0,20,20">
								<polygon className={'trackControlTriangle'}
								         fillOpacity={isJumpInView < item.id.length - 1 ? 1 : 0.2}
								         strokeOpacity={isJumpInView < item.id.length - 1 ? 1 : 0.2}
								         fill={hoverFwd && isJumpInView < item.id.length - 1 ? 'red' : 'white'}
								         points="20,10 10,20 10,0 "/>
							</svg>
						</span>
						</div>
						<div className="closeXflex"
						     style={{marginLeft: isMo1 && !isLandscape ? '1vw' : '0.5rem', zIndex: 10000}}
						     onClick={closePopup}
						>
							&times;
						</div>
					</div>
					}


					<div className='popuptext firstStorycontainer'>

						{currentStory && storyContent &&
							<div>
								<h2>{currentStory.header}</h2>
								{currentContent.map((d, i) => {
//								console.log("iiiiiii",i);
									let itemCounter = 5;
									let content = d[0];
									let lastImage = '';
									if (content.subheader1) {
										return <h3 key={'subh' + i}>{content.subheader1}</h3>
									} else if (content.subheader) {
										return <h2 key={'subh' + i}>{content.subheader}</h2>
									} else if (content.par) {
										return <p key={'par' + i} className={'normpar'}
										          style={{
											          marginLeft: isMo1 ? isLandscape ? '0.0em' : '0.5em' : '1.4em',
											          marginRight: isMo1 ? isLandscape ? '0.0em' : '0.5em' : '1.4em',
											          fontSize: isMo1 ? isLandscape ? '1.2em' : '1.2em' : '1.1em',
										          }}
										          dangerouslySetInnerHTML={{__html: content.par}}></p>
									} else if (content.caption) {
										return <p key={'cap' + i}
										          className={'figureCaption'}
										          style={{
											          margin: isMo1 ? isLandscape ? '0px 5px 0px 5px' : '0px 5px 0px 5px' : '0px 50px 0px 50px',
											          fontSize: isMo1 ? isLandscape ? '1.1em' : '1.1em' : '0.9em'
										          }}
										>Abbildung: {content.caption}</p>
									} else if (content.extImage) {
										return <ShowExternalImages key={'eimg' + i}
										                           filename={content.extImage}></ShowExternalImages>
									} else if (content.dbImage) {
										return (
											<div key={'sdbim' + i}>
												{isInView.length > 0
													&& itemsList[isInView[0]].filename1 === content.dbImage
													&& <div ref={popupStoryTitleRef}></div>}
												<ShowDbImages
													key={'dimg' + i} filename={content.dbImage}>
												</ShowDbImages>
												{isInView.length > 0
													&& itemsList[isInView[0]].filename1 === content.dbImage
													&& <div ref={popupStoryTitleRefStop}></div>}
											</div>
										)
									} else if (content.museum) {
										return <Museum key={'smus' + i} val={content.museum}></Museum>
									} else if (content.audio) {
										return <ShowAudio key={'aud' + i} filename={content.audio}></ShowAudio>
									} else if (content.jumpto) {
										return (
											<div key={'jmp' + i} className={'jumpMark'}>
												<div ref={el => refVis.current[content.jumpto.refid] = el}
												     key={'refv_' + i} id={content.jumpto.refid}
												     style={{
													     scrollMarginTop: '50px',
													     position: 'relative',
													     marginTop: '20px',
													     top: '-20px', // Add negative offset to trigger earlier
													     marginBottom: '-20px' // Compensate for the offset
												     }}
												     width={10} height={5}
												>
												</div>
												<JumpRef
													refid={content.jumpto.refid}
													jumptext={content.jumpto.jumptext}
												>
												</JumpRef>
												{/*<ShowItem key={'si' + i} vis={content.jumpto.refid}*/}
												{/*          year={content.jumpto.jumptext}></ShowItem>*/}

											</div>
										)
									} else {
										return " =====> " + JSON.stringify(d)
									}
								})}
							</div>

						}

					</div>
					<div className="secondcontainer" style={{
						padding: 5,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						opacity: 1
					}}>
						{/*<svg height="180" width="360" style={{backgroundColor: '#444444'}}>*/}
						{/*	<circle cx="180" cy="90" r="70" stroke="black" strokeWidth="3" fill="red"/>*/}
						{/*</svg>*/}
					</div>
					{/*<ShowSelected/>*/}
					{/*<ShowHoveredCoords/>*/}
					{/*<div className="thirdcontainer" style={{padding: 5}}>*/}
					{/*	<ItemDescription*/}
					{/*		val={lastSelected > -1 ? itemsList[lastSelected].description : ''}*/}
					{/*		new={selected}*/}
					{/*	>*/}
					{/*	</ItemDescription>*/}
					{/*	<Museum val={lastSelected > -1 ? itemsList[lastSelected] : -1}></Museum>*/}
					{/*</div>*/}
				</div>
			</Html>
		</group>
	)

}

//)


function Museum(d) {
//const Museum = React.memo((d) => {


	const museumsList = useStore(state => state.museums);

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const [showMore, setShowMore] = useState(false);

	//console.log(d.val)
	const museumId = d.val === '51' ? '74' : d.val;
	const ext = "<img src=\".logo192.png\" height=13 style='verticalAlign: bottom' alt='external Link'/>";


	useEffect(() => {
		if (showMore) {
			mtrack('story museum more for id ' + d.val);
		}
	}, [showMore])

	if (museumId && museumId > -1) {
		const museum = museumsList.find((a) => a.idMandant === museumId ? a : '')
//		console.log(museum);
		return (
			<>
				<div className={'figureCaptionMuseum'}
				     style={{
					     margin: isMo1 ? isLandscape ? '0px 5px 0px 5px' : '0px 5px 0px 5px' : '0px 50px 0px 50px',
					     fontSize: isMo1 ? isLandscape ? '1.1em' : '1.1em' : '0.9em'
				     }}
				>
					Museum: {museum.bezeichnung.split(',').pop()}
					{!showMore && <span>, </span>}
					{!showMore &&
						<span className={'link'} style={{whiteSpace: 'nowrap'}} onClick={() => setShowMore(true)}>mehr anzeigen</span>}
					{showMore && <span className={'museumdesc'}>
						<div className={'museumdescelem'}>Öffnungszeiten:</div>
						{museum.open.split('\n').map((line, i, arr) => {
							let lineOut;
							if (line.search(/<a.href=/) > -1) {
								if (line.search(/>Öffnungszeiten</) > -1) {
									let out = "siehe "
										+ line.replace(/>Öffnungszeiten</, ' target=\'_blank\'>homepage<')
										+ "<img src='extlink.png' height='12' style='vertical-align: bottom'/>"
										+ " des Museums";
									lineOut = <span key={i} dangerouslySetInnerHTML={{__html: out}}></span>;
								} else {
									lineOut = <span key={i} dangerouslySetInnerHTML={{__html: line}}></span>;
								}

								return lineOut;
							} else {
								lineOut = <span key={i}>{line}</span>;
							}
							if (i === arr.length - 1) {
								return lineOut;
							} else {
								return [lineOut, <br key={i + 'br'}/>];
							}
						})}

						<div className={'museumdescelem'}
						     style={{flexDirection: 'row', justifyContent: 'space-between'}}>
						Info zum Museum:&nbsp;
							<a href={museum.homepageSlrg} target='_bank'>
							salzburg.gv.at
							<span style={{textDecorationLine: 'none'}}>
							<img src={extLink} height={13} style={{verticalAlign: 'bottom'}} alt="external Link"/>
						</span>
						</a>
					</div>
					<div className={'museumdescelem'}>
						Anfahrt:&nbsp;
						<a href={museum.directions.replace(/^\uFEFF/, '')} target='_bank'>Google Maps
							<span style={{textDecorationLine: 'none'}}>
							<img src={extLink} height={13} style={{verticalAlign: 'bottom'}} alt="external Link"/>
						</span>
						</a>
					</div>
					<div className={'link museumdescelem'} onClick={() => setShowMore(false)}> weniger anzeigen</div>
				</span>
					}
				</div>
			</>)

	} else return (<></>)
}

//)


//function ShowExternalImages(d) {
const ShowExternalImages = React.memo((d) => {


	const {height, width} = useWindowDimensions();

	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const itemsList = useStore(state => state.items);

	//		var filename = "1_64-Garten Sigl.Haus.jpg";
	const item = itemsList.find(i => i.filename1 === d.filename)

	if (d.filename) {
		let imagepath = process.env.REACT_APP_PUBLIC_PATH + "/api/assets/cache/orig/all/" + d.filename;
		let dummypath = './nopic.png';

		return (
			<div className='itemsExtImage externalImagesContainer'
				//				     onClick={() => {onImageclick(itemsList[lastSelected])}}
			>
				<img
					src={d.filename !== null ? imagepath : dummypath}
					width={isMo1 && !isLandscape ? width * 0.7 : width / 4}
				></img>
			</div>
		)
	} else return (<p/>)
})


//const ShowDbImages = (d) => {
const ShowDbImages = (d) => {


	const {height, width} = useWindowDimensions();
	const maxHeight = height * 0.8; // 80% of screen height
	
	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const itemsList = useStore(state => state.items);

	const item = itemsList.find(i => i.filename1 === d.filename)

//	const id = item && item.filename1 !== null ? parseInt(item.id) : -1;
	const id = item && item.filename1 !== null ? itemsList.findIndex(d => d.id === item.id) : -1;

	const dummypath = './nopic.png';
	const [currImg, setCurrImg] = useState(0);

	const [imgs, setImgs] = useState([]);

	const imageIndex = useStore(state => state.imageIndex); // lightbox
	const setImageIndex = useStore(state => state.setImageIndex); // lightbox
	const setImageIndexPos = useStore(state => state.setImageIndexPos); // lightbox

	const setShowLightbox = useStore(state => state.setShowLightbox);

	const isInView = useStore(state => state.isInView);
	const setIsInView = useStore(state => state.setIsInView);
	const unsetIsInView = useStore(state => state.unsetIsInView);

	const [imageHeight, setImageHeight] = useState(null);

	const {observe, unobserve, inView, scrollDirection, entry} = useInView({
		threshold: isMo ? 0.2 : 0.4, // Reduced threshold for better detection with tall images
		rootMargin: '0px 0px -25% 0px', // Add margin to trigger earlier
//		trackVisibility: true,
		delay: 300,
		onChange: ({inView, scrollDirection, entry, observe, unobserve}) => {
//			 Triggered whenever the target meets a threshold, e.g. [0.25, 0.5, ...]
			unobserve(); // To stop observing the current target element
			setTimeout(() => {
				observe(); // To re-start observing the current target element
			}, 2000)
		},
		onEnter: ({scrollDirection, entry, observe, unobserve}) => {
			// Triggered when the target enters the viewport
		},
		onLeave: ({scrollDirection, entry, observe, unobserve}) => {
			// Triggered when the target leaves the viewport
		},
		// More useful options...
	});


	useEffect(() => {
//		console.log('restarted')
//		console.log('curr', imgs, currImg, id);
	}, [])

	useEffect(() => {

//		console.log('id', id)

		if (id !== -1) {
			var imageArr = getItemsImages(id);
			setImgs(imageArr)
			if (imageArr.length < 2) {
				setCurrImg(0);
				setImageIndexPos(0);
			}
		}
	}, [id])

	useEffect(() => {
		if (inView) {
//			console.log("QQQQ: set view");
			setIsInView(id);
		} else {
			unsetIsInView(id);
		}
	}, [inView])

	useEffect(() => {
//		console.log("QQ:", isInView)
	}, [isInView])


	function handleClick(e) {
		if (id > -1 && imgs[e.target.id]) {
			// Parse the clicked id as a number
			const clickedId = parseInt(e.target.id);

			// Set new current image based on direction
			if (clickedId === 0) {
				// Back arrow clicked - show previous image
				setCurrImg(currImg === 0 ? imgs.length - 1 : currImg - 1);
			} else {
				// Forward arrow clicked - show next image
				setCurrImg(currImg === imgs.length - 1 ? 0 : currImg + 1);
			}
		}
	}

	// open a image in lightbox
	const onImageclick = (d, p) => {
//		console.log('!! show lightbox clicked', d, p)
		mtrack('story-lightbox image ' + d.filename1)
		setShowLightbox(true);
		setImageIndex(d);
		setImageIndexPos(p);
	}

	// Function to handle image load and check dimensions
	const handleImageLoad = (e) => {
		const img = e.target;
		const imgWidth = isMo1 && !isLandscape ? width * 0.7 : width / 4;
		const aspectRatio = img.naturalWidth / img.naturalHeight;
		const calculatedHeight = imgWidth / aspectRatio;

		// Only constrain height if it would exceed maxHeight
		if (calculatedHeight > maxHeight) {
			setImageHeight(maxHeight);
		} else {
			setImageHeight(null); // Use default sizing
		}
	};


	return (
		<>
			{imgs.length > 0 &&
				<div ref={observe} className='imageContainer'>
					<div id={0} className='imagefwd' onClick={handleClick}
					     style={{
						     marginLeft: isMo1 && !isLandscape ? '0.9vw' : '0vw',
						     fontSize: isMo1 && !isLandscape ? '8vw' : '2vw',
						     opacity: id > -1 && imgs.length > 1 && currImg !== 0 ? 1 : 0.01,
						     cursor: id > -1 && imgs.length > 1 && currImg !== 0 ? 'pointer' : 'default'
					     }}
					> &#10094; </div>

					<div className='itemsImage dbImagesContainer'
					     onClick={() => {onImageclick(itemsList[id], currImg)}}
					>
						<img
							src={id > -1 ? imgs[currImg].path : dummypath}
							width={id > -1 ? isMo1 && !isLandscape ? width * 0.7 : width / 4 : 320}
                            style={{
                                height: imageHeight ? `${imageHeight}px` : 'auto',
                                objectFit: imageHeight ? 'contain' : 'initial'
                            }}
                            onLoad={handleImageLoad}
						/>
					</div>

					<div id={1} className='imageback' onClick={handleClick}
					     style={{
						     marginRight: isMo1 && !isLandscape ? '0.9vw' : '0vw',
						     fontSize: isMo1 && !isLandscape ? '8vw' : '2vw',
						     opacity: id > -1 && imgs.length > 1 && currImg !== imgs.length - 1 ? 1 : 0.01,
						     cursor: id > -1 && imgs.length > 1 && currImg !== imgs.length - 1 ? 'pointer' : 'default'
					     }}
					> &#10095; </div>

					{/* Optional: Add image counter */}
					{/*<div style={{*/}
					{/*	position: 'absolute',*/}
					{/*	bottom: '10px',*/}
					{/*	width: '100%',*/}
					{/*	textAlign: 'center',*/}
					{/*	color: 'white'*/}
					{/*}}>*/}
					{/*	{imgs.length > 1 && `${currImg + 1}/${imgs.length}`}*/}
					{/*</div>*/}
				</div>
			}
		</>)
}
//)

//function JumpRef(props) {
const JumpRef = React.memo((props) => {


	const circleColor = "#f60060";

	const isJumpInView = useStore(state => state.isJumpInView);
	const setIsJumpInView = useStore(state => state.setIsJumpInView);


	const {observe, unobserve, inView, scrollDirection, entry} = useInView({
		threshold: 0.9, // Default is 0
//		trackVisibility: true,
		delay: 500,
		onChange: ({inView, scrollDirection, entry, observe, unobserve}) => {
			// Triggered whenever the target meets a threshold, e.g. [0.25, 0.5, ...]
//			console.log("Scroll direction: ", scrollDirection.vertical);

			unobserve(); // To stop observing the current target element
			setTimeout(() => {
				observe(); // To re-start observing the current target element

			}, 3000)

//			observe(); // To re-start observing the current target element
		},
		onEnter: ({scrollDirection, entry, observe, unobserve}) => {
			// Triggered when the target enters the viewport
			setIsJumpInView(props.refid);
		},
		onLeave: ({scrollDirection, entry, observe, unobserve}) => {
			// Triggered when the target leaves the viewport
		},
		// More useful options...
	});

	useEffect(() => {
//		console.log("scrollDir: ",scrollDirection ? scrollDirection.vertical : 'none');

	}, [inView])

	return (
		<div ref={observe}>
			<svg height="80" width="320"
			     style={{backgroundColor: hexToRgbA('#444444', 0.01),}}>
				<circle cx="40" cy="38" r="20" stroke="#b02b12"
				        strokeWidth={inView ? 1 : 0}
				        fill={inView ? circleColor : "gray"}/>
				<text x="70" y="50%"
				      className={"jumptext"}>{props.jumptext}</text>
			</svg>
		</div>

	)


})

export default Story;
