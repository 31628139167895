//import 'bootstrap/dist/css/bootstrap.css';
import "./index.css";
import React, {Suspense, useState, useRef, useEffect, useLayoutEffect, useCallback} from 'react'
import {Canvas, useFrame, useThree} from '@react-three/fiber'
import {Stats, useTexture, OrbitControls, Html} from '@react-three/drei'
import {useSpring, a, config} from '@react-spring/web';
import * as rdd from 'react-device-detect';
import * as qs from 'query-string';
import preval from 'preval.macro';

//	import metadata from 'metadata.json';

import {useStore} from "./components/store";
//import Effects from "./components/effects";
//import Effects from "./components/effects2";
import Scene from './components/scene'
import {Popup} from './components/popup'
import Story from './components/story'
import {TransitionControl} from './components/transitionControl'
import SwitchColors from "./components/switchColors";
import {LevaComponent} from "./components/levaGUI";
import {Sunburst} from "./components/sunburst";
import {Zsunburst} from "./components/zsunburst";
import {Gallery} from "./components/gallery";
import LightboxOverlay from "./components/lightbox";
import Search from './components/search';
import Onboarding from './components/onboarding';

import {JumpRelative} from "./components/timejump";
import {MediaOverlay} from "./components/mediaOverlay";
import {useWindowDimensions, useScreenDimensions} from "./utils/hooks";
import Select from "react-select";
import {isMobileOnly, deviceDetect, BrowserTypes, isIOS, isSafari, isMobile, deviceType, isChrome, isTablet} from 'react-device-detect';
import ReactFullscreeen from 'react-easyfullscreen';

import metadata from "./metadata.json";
import {ExitFullScreen, FullScreen, HelpButton, Umbrella} from "./components/icons";
import {useMatomo} from '@datapunt/matomo-tracker-react'


export function WrappedApp(props) {
//	console.log("------ APP -----");


	const {trackPageView, trackEvent, pushInstruction} = useMatomo();

	const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`

	const {height, width} = useWindowDimensions();

	const [isIOSChromeLandscape, setIsIOSChromeLandscape] = useState(null);
	const [isIOSChromeOrientationChange, setIsIOSOrientationChange] = useState(false);

	const [value, setValue] = useState();

	const tree = useStore(state => state.tree);
	const items = useStore(state => state.items);

	const setIsMo = useStore(state => state.setIsMobile);
	const isMo = useStore(state => state.isMobile);

	// mobile variants
	const setIsMo1 = useStore(state => state.setIsMobile1);
	const isMo1 = useStore(state => state.isMobile1);

	const setIsMo2 = useStore(state => state.setIsMobile2);
	const isMo2 = useStore(state => state.isMobile2);

	const setIsLandscape = useStore(state => state.setIsLandscape);
	const isLandscape = useStore(state => state.isLandscape);

	const [overflowed, setOverflowed] = useState(false);

	const scrolling = useStore(state => state.scrolling);
	const dragged = useStore(state => state.dragged);

	const hideStats = useStore(state => state.hideStats);
	const showSunburst = useStore(state => state.showSunburst);
	const setShowSunburst = useStore(state => state.setShowSunburst);

	const initialized3D = useStore(state => state.initialized3D);
//	const setInitialize3D = useStore(state => state.setInitialized3D);

	const showMediaOverlay = useStore(state => state.showMediaOverlay);
	const setShowMediaOverlay = useStore(state => state.setShowMediaOverlay);

	const showPopup = useStore(state => state.showPopup);

	const setShowVideo = useStore(state => state.setShowVideo);
	const showVideo = useStore(state => state.showVideo);
	const setShow3D = useStore(state => state.setShow3D);
	const show3D = useStore(state => state.show3D);
	const setShowAudio = useStore(state => state.setShowAudio);
	const showAudio = useStore(state => state.showAudio);

	const setShowInfo = useStore(state => state.setShowInfo);
	const showInfo = useStore(state => state.showInfo);

	const setShowInfo2 = useStore(state => state.setShowInfo2);
	const showInfo2 = useStore(state => state.showInfo2);

	const setShowImpressum = useStore(state => state.setShowImpressum);
	const showImpressum = useStore(state => state.showImpressum);

	const showTour = useStore(state => state.showTour);
	const setShowTour = useStore(state => state.setShowTour);

	const modalLeft = useStore(state => state.modalLeft);
	const setModalLeft = useStore(state => state.setModalLeft);

	const [showTransition, setShowTransition] = useState(true);

	const selectedStory = useStore(state => state.selectedStory);

	const setRemote = useStore(state => state.setRemote); // select remote

	const transition = useStore(state => state.transition);
//	const setTransition = useStore(state => state.setTransition);

	const setDevice = useStore(state => state.setDevice);
//	const setIsMobile = useStore(state => state.setIsMobile);

	const setQuery = useStore(state => state.setQuery);
	const debugLevel = useStore(state => state.debugLevel);
	const setDebugLevel = useStore(state => state.setDebugLevel);

	const [isFullscreen, setIsFullscreen] = useState(false);

	const isFullScreen = useStore(state => state.isFullScreen);
	const setIsFullScreen = useStore(state => state.setIsFullScreen);

	const isFullScreenAvailable = useStore(state => state.isFullScreenAvailable);

	const setColorScheme = useStore(state => state.setColorScheme);

	const museumSelectOpen = useStore(state => state.museumSelectOpen);

	const [isOverTour, setIsOverTour] = useState(false);
	const [iconColor, setIconColor] = useState('#BD0926');

	const dummyref = useRef();
	const dummyModal = useRef();
	const footerRef = useRef();

	const [moHeight, setMoHeight] = useState('100vh');
	const [moHeightOffset, setMoHeightOffset] = useState(0);

	const [isLoading, setIsLoading] = useState(true);

	const isOnboarding = useStore(state => state.isOnboarding);
	const firstModalCompleted = useStore(state => state.firstModalCompleted);


	var log = '';

	function paddy(num, padlen, padchar) {
		var pad_char = typeof padchar !== 'undefined' ? padchar : '0';
		var pad = new Array(1 + padlen).join(pad_char);
		return (pad + num).slice(-pad.length);
	}

//	var fu = paddy(14, 5); // 00014

	useEffect(() => {
		const zeroPad = (num, places) => String(num).padStart(places, '0')
		console.log('v'+metadata.version+'b'+zeroPad(metadata.build, 3), preval`module.exports = new Date().toLocaleString();`)

		trackPageView()
		let rnd = Math.floor(Math.random() * 999999999) + 1;
		pushInstruction('setUserId', 'UID' + paddy(rnd, 10))
	}, [])

	// ONLY for IOS + Chrome: refresh page on orientation change
	// reload function
	const refreshPage = () => {
		window.location.reload();
//		setValue({})
	}

	// ONLY for IOS + Chrome: refresh page on orientation change
	// detect changes (actually landscape mode is set wrong, but we are only intereste in a change and then ist is OK)
	useEffect(() => {
		if (isIOS && isChrome) {
			if (isIOSChromeLandscape === null) {
				setIsIOSChromeLandscape(window.innerWidth > window.innerHeight ? false : true)
			}
			if (isIOSChromeLandscape === false && window.innerWidth < window.innerHeight) {
				setIsIOSChromeLandscape(true);
				setIsIOSOrientationChange(true);
			} else if (isIOSChromeLandscape === true && window.innerWidth > window.innerHeight)
				setIsIOSChromeLandscape(false);
				setIsIOSOrientationChange(true);
		}
	}, [width, height])

	// ONLY for IOS + Chrome: refresh page on orientation change
	// avoid reload on startup
	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false)
			setIsIOSOrientationChange(false)
		}, 2000)
	}, [])

	// ONLY for IOS + Chrome: refresh page on orientation change
	// trigger page refresh
	useEffect(() => {
		if (isIOSChromeOrientationChange && !isLoading) {
			setTimeout(() => {
				refreshPage()
			}, 1000)
		}
	}, [isIOSChromeOrientationChange])

	useLayoutEffect(() => {
		if (initialized3D === false) {
			setIsMo1(isMobile ? true : false)
		}
	}, [])

	// store isFullscreen also as global in store
	useEffect(() => {
			setIsFullscreen(isFullScreen)
		},
		[isFullScreen])

	useEffect(() => {
		// XXX hide bar on scroll
		//  window.scrollTo(0, 1);
		if (isMo) {
//			document.body.classList.add('mobileBody');
			setShowSunburst(false)
		}
		return () => {
//			if (isMo) document.body.classList.remove('mobileBody');
		};
	}, [isMo]);

	useEffect(() => {

//			console.log("footerRef",footerRef && footerRef.current ? footerRef.current.getBoundingClientRect() : 'N/A')

			var isIChrome = isIOS && isChrome ? true : false;

			let availHeight = window.screen.availHeight;
			let availWidth = window.screen.availWidth;
			let finalHeight = window.innerHeight;

			let safariHeight, safariWidth;
			if (isIChrome) {
				setMoHeight(-1);
				setMoHeightOffset(footerRef.current.getBoundingClientRect().height + 20);
			}

			// when in portrait mode, always show adress & IOS toolbar
			setTimeout(() => {
				document.querySelector('html').scrollTop = -1;
			}, 5000)

			if (isSafari && !isIChrome) {
				// window inner height is not available in newer browsers, so try it with document.element
				// and document.body first
				if (document.documentElement && ("getBoundingClientRect" in document.documentElement)) {
					safariHeight = document.documentElement.getBoundingClientRect().height;
				} else if (("getBoundingClientRect" in document.body)) {
					safariHeight = document.body.getBoundingClientRect().height;
				} else if (("innerHeight" in window)) {
					safariHeight = window.innerHeight;
				}

				// landscape
				if (width > height) {
//					console.log("~~~ orientation changed", document.documentElement.getBoundingClientRect())
					safariHeight = window.innerHeight; // basically works, but destroys next portrait
				}
				finalHeight = safariHeight;
			}

			if (isSafari && !isIChrome) {
				setTimeout(() => {
					setMoHeight(finalHeight)
					setMoHeightOffset(footerRef.current.getBoundingClientRect().height + 20);
				}, 800)
			} else if (isMo && !isIChrome) {
				setTimeout(() => {
					setMoHeight(isFullScreen ? window.innerHeight : finalHeight)
					setMoHeightOffset(footerRef.current.getBoundingClientRect().height + 20);
				}, 1800)
			}

		if (!isMo) {
			setMoHeightOffset(footerRef.current.getBoundingClientRect().height + 20);
		}

	}, [isMo, width, height, isFullscreen, isFullScreen, isLandscape])

	// check the query
	useLayoutEffect(() => {
		// parse URL/query string parameters
		// ex: ?debug=1 switches to debugmode
		let parsed = qs.parse(window.location.search);
		setQuery({queryParams: parsed});

		if (parsed.debug && parseInt(parsed.debug) > -1 && parseInt(parsed.debug) < 2) {
//			console.log("debug level: ", parsed.debug);
			if (parseInt(parsed.debug) === 1) {
				setDebugLevel(1);
			}
		}

		if (parsed.v && parseInt(parsed.v) > 0 && parseInt(parsed.v) <= 2) {
			if (isMobile && parseInt(parsed.v) === 1) {
//				console.log("variant level1: ", parsed.v);
				setIsMo1(true);
				setIsMo2(false)
			}
			if (isMobile && parseInt(parsed.v) === 2) {
//				console.log("variant level2: ", parsed.v);
				setIsMo2(true);
				setIsMo1(false)
			}
		}

//		if (parseInt(parsed.debug) === 3) {
//			console.log("variant level3: ", parsed.v);
//			setDebugLevel(3)
//		}


		if (parsed.color && parseInt(parsed.color) > -1 && parseInt(parsed.color) < 3) {
//			console.log("set query colorScheme: ", parsed.color);

			if (parseInt(parsed.color) === 0) {
				setColorScheme('grey');
				useStore.setState({colorScheme: 'grey'});
			}
			if (parseInt(parsed.color) === 1) {
				setColorScheme('blue03');
				useStore.setState({colorScheme: 'blue03'});
			}
			if (parseInt(parsed.color) === 2) {
				useStore.setState({colorScheme: 'blue04'});
				setColorScheme('blue04');
			}
		}

		if (parsed.inv) {
			// sanitize

			var pattern = /^\d{1,4}-\d{1,5}$/;

			let ok = parsed.inv.match(pattern);
//			console.log("set query object: ", parsed.inv);
			if (ok) {
				setRemote(parsed.inv)
			} else {
				alert("Der letzte Teil der URL ist nicht korrekt, nach '?inv=' sollten zwei durch Bindestrich getrennte Zahlen stehen!")
			}
		}

	}, [])


	const {slide} = useSpring({
		from: {slide: 0,},
//		to: {slide: transition ? -width + (isMo1 ? isLandscape ? 40 : 0 : 54) : 0,},
		to: {slide: transition ? isMo1 ? isLandscape ? -width + 40 : -width : -width + 54 : 0,},
		delay: transition ? 700 : 700,
		config: config.default,
		onStart: () => { setOverflowed(transition ? true : false) }
	})

	const {slide2} = useSpring({
		from: {slide2: 0,},
//		to: {slide: transition ? -width + (isMo1 ? isLandscape ? 40 : 0 : 54) : 0,},
		to: {slide2: transition ? isLandscape ? width * 0.44 : width / 2 * 1.82 - 54 : -width,},
		delay: transition ? 700 : 0,
		config: config.default,
		onStart: () => { setOverflowed(transition ? true : false) }
	})

	const {color} = useSpring({
		from: {color: '#9A9AA2'},
		to: {color: transition ? '#FFFFFF' : '#9A9AA2'},
		delay: transition ? 1200 : 300,
		config: {duration: transition ? 1200 : 400},
//		onStart: ()=>{ setOverflowed(transition ? true : false) }
	})

	const {o} = useSpring({
		from: {o: 1},
		to: {o: initialized3D ? 0 : 1},
		delay: 800,
		config: {duration: 1000},
	})

	const {o2} = useSpring({
		from: {o2: 0},
		to: {o2: showMediaOverlay ? 1 : 0},
	})

	const handleButton = (d) => {
		setShowMediaOverlay(true);
		setShowVideo(d === 'video' ? true : false);
		setShow3D(d === '3D' ? true : false);
		setShowAudio(d === 'audio' ? true : false);
		setShowInfo(d === 'info' ? true : false);
		setShowImpressum(d === 'impressum' ? true : false);
		setShowTour(d === 'tour' ? true : false);
		setShowInfo2(d === 'info2' ? true : false);
		trackEvent({category: d + ' opened', action: 'click-event'})
	}

	const handleStoryButton = (d) => {
//		setShowMediaOverlay(false);
		setShowTour(showTour ? false : true);
//		console.log("clicked showTour")
		trackEvent({category: 'story opened', action: 'click-event'})
	}

	useEffect(() => {
		if (selectedStory > -1) {
			setShowTour(true);
		}
	}, [selectedStory])


	useEffect(() => {
		setIconColor(isOverTour ? '#F5092D' : '#BD0926');
	}, [isOverTour])

//	const iconColor='#BD0926';

	useEffect(() => {
//		console.log("CCCCCC", showTour, showPopup)
		if (isMo1 && !isLandscape) {
			setShowTransition(showPopup || showTour ? false : true)
		}
	}, [showPopup, showTour, isMo1, isLandscape])

	useEffect(() => {
		setTimeout(() => {
			setModalLeft(dummyModal.current ? dummyModal.current.offsetLeft - window.innerWidth : null)
		}, 4000)
	}, [transition, isLandscape])

//	console.log("fullscreen", isFullscreen)

	return (

		<div>
			<div style={{height: '100vh', width: '100vw'}}>
				<div ref={dummyref} className={'dummy'}/>
			</div>

			{/*{debugLevel === 3 &&*/}
			{/*	<div className={'log'} style={{*/}
			{/*		backgroundColor: 'blue',*/}
			{/*		position: 'absolute',*/}
			{/*		top: '100px',*/}
			{/*		left: '2px',*/}
			{/*		width: '200px',*/}
			{/*		height: '200px',*/}
			{/*		color: 'white',*/}
			{/*		zIndex: 10000,*/}
			{/*		opacity: 0.7,*/}
			{/*		pointerEvents: 'none',*/}
			{/*	}}>*/}
			{/*		{width}x{height} ...*/}
			{/*		<br/>*/}
			{/*		moHeight: {moHeight}*/}
			{/*		<br/>*/}
			{/*		moHeight-Offset: {moHeightOffset}*/}
			{/*		<br></br>*/}
			{/*		1.inner: {window.innerWidth} x {window.innerHeight}*/}
			{/*		<br></br>*/}
			{/*		2.avail: {window.screen.availWidth} x {window.screen.availHeight}*/}
			{/*		<br></br>*/}
			{/*		3.doc: {document.documentElement.getBoundingClientRect().width} x&nbsp;*/}
			{/*		{document.documentElement.getBoundingClientRect().height}*/}
			{/*		<br></br>*/}
			{/*		4.dummy-v: {dummyref && dummyref.current ? Math.floor(dummyref.current.getBoundingClientRect().width) : 'N/A'} x&nbsp;*/}
			{/*		{dummyref && dummyref.current ? Math.floor(dummyref.current.getBoundingClientRect().height) : 'N/A'}*/}
			{/*		<br></br>*/}
			{/*		5.dummy-perc: {dummyref2 && dummyref2.current ? Math.floor(dummyref2.current.getBoundingClientRect().width) : 'N/A'} x&nbsp;*/}
			{/*		{dummyref2 && dummyref2.current ? Math.floor(dummyref2.current.getBoundingClientRect().height) : 'N/A'}*/}
			{/*		<br></br>*/}
			{/*		<br></br>*/}
			{/*		/!*isTriggerReload: {isTriggerReload ? 'true' : 'false'}*!/*/}
			{/*		/!*<br></br>*!/*/}
			{/*		/!*isIOSChromeLandscape: {isIOSChromeLandscape ? 'true' : 'false'}*!/*/}
			{/*		/!*<br></br>*!/*/}
			{/*		/!*isIOSChromeOrientationChange: {isIOSChromeOrientationChange ? 'true' : 'false'}*!/*/}
			{/*		/!*<br></br>*!/*/}
			{/*		[{Math.random()}]*/}
			{/*	</div>*/}
			{/*}*/}
			<div className={'rootcontainer'}
			     style={{
				     height: moHeight == -1 ? '100%' : moHeight,
				     width: null
			     }}
			>
				<a.div className="splashcontainer" style={{
					opacity: o,
					pointerEvents: initialized3D ? 'none' : false,
				}}>
					<SplashScreen/>
				</a.div>
				<Itemloader></Itemloader>
				<LevaComponent/>
				{debugLevel === 1 && !hideStats &&
					<Stats/>}
				{showSunburst === true &&
					<Sunburst/>}
				<a.div className="zsunburstbackground"
				       style={{backgroundColor: color, overflow: 'hidden'}}>
				</a.div>
				<a.div className="zsunburstcontainer"
				       style={{
					       left: slide,
					       width: width * 2,
					       flexWrap: isMo1 ? isLandscape ? 'nowrap' : 'wrap' : 'default',
				       }}>
					{/*<div className={'sunburstSearchConatiner'}>*/}
					{tree && <Zsunburst/>}
					<Search></Search>
					{/*</div>*/}
					<Gallery/>
					{showTransition &&
						<TransitionControl/>
					}
					<LightboxOverlay/>
						<Onboarding
							pos={footerRef.current ? footerRef.current.offsetLeft : '0'}
							heightOffset={moHeightOffset}
						>
						</Onboarding>
					<div className='helpSunburst'
					     onClick={() => {handleButton('info2')}}
					     style={{
						     width: isMo1 ? isLandscape ? '10vw' : 'auto' : 'auto',
						     height: isMo1 ? isLandscape ? '10vh' : 'auto' : '10vh',
						     left: isMo1 ? isLandscape ? width * 1.40 : width * 1.82 - 12 : width * 1.45,
						     bottom: isMo1 ? isLandscape ? '25.5vh' : 'auto' : 2 * (height / 100),
						     top: isMo1 ? isLandscape ? 'auto' : '29.5vh' : 'auto',
						     opacity: isMo1 && !isLandscape ? museumSelectOpen ? 0 : 1 : 1,
						     pointerEvents: isMo1 && !isLandscape ? museumSelectOpen ? 'none' : 'auto' : 'auto',
					     }}
					>
						<HelpButton></HelpButton>
					</div>
					{/* this is only a dummy icon!*/}
					{isMo1 && isFullScreenAvailable &&
						<div ref={dummyModal} className={'fullscreenModal'}
						     style={{
//							 border: '1px solid red',
							     width: isLandscape ? '2.5em' : '2.5em',
							     height: isLandscape ? '2.4em' : '2.4em',
							     top: isLandscape ? (isTablet ? '57vh' : '48vh') : '22vh',
							     left: isLandscape ? (isTablet ? '141.1vw' : '141.7vw') : (isTablet ? '182vw' : '182vw'),
							     pointerEvents: 'none',
							     opacity: isMo1 && !isLandscape ? museumSelectOpen ? 0 : 1 : 1,
						     }}
						>
							{isFullscreen ?
								(<ExitFullScreen color={'gray'}/>)
								:
								(<FullScreen color={'gray'}/>)}
						</div>
					}
				</a.div>
				<a.div
					className={showInfo ? 'transparentoverlay' : showInfo2 ? 'fulloverlay2' : 'fulloverlay1'}
					onClick={() => {
//						console.log("media overlay clicked");
						if (!show3D && !showVideo && !showAudio && !showImpressum) {
							setShowMediaOverlay(false)
						}

					}}
					style={{
						left: showInfo2 ? 0 : slide,
						opacity: o2,
						pointerEvents: showMediaOverlay ? 'auto' : 'none'
					}}>
					<MediaOverlay></MediaOverlay>
				</a.div>
				<a.div className='header'
				       style={{
					       left: slide,
					       opacity: (showTour && selectedStory !== -1) || showPopup ? 0.2 : 1,
					       padding: isMo ? isLandscape ? "0.3em" : "0.4em" : "0.5em",
				       }}>
					<div className='logo'>
						<a href='https://www.salzburgermuseen.at/salzburger-museen' target='_blank'>
							<img src={'logo_text.png'} alt={'Logo Salzburger Landessammlungen und Museen'} style={{
								width: isMo1 ? isLandscape ? height / 4 : height / 6 : height * 0.2,
								margin: '-0.2em',
							}}>
							</img>
						</a>
					</div>
					<div className='headerbuttons'
					     style={{fontSize: isMo1 ? isLandscape ? '5vh' : '5.5vw' : '2.5vm'}}
					     onClick={() => {handleStoryButton('tour')}}
					     onMouseOver={() => setIsOverTour(true)}
					     onMouseOut={() => setIsOverTour(false)}
					>
						{/*<span className='headericon' style={{*/}
						{/*	margin: isMo1 ? isLandscape ? (isTablet ? '1vw 10px 10px 10px' : '2vw 10px 10px 10px')*/}
						{/*		: (isTablet ? '0.5vh 10px 10px 10px' : '1.5vh 10px 10px 10px')*/}
						{/*		: '1.5vh 10px 10px 10px',*/}
						{/*	width: isMo1 ? isLandscape ? '2.5vh' : '3vw' : '3vh',*/}
						{/*	height: isMo1 ? isLandscape ? '3vw' : '3vh' : '3vh',*/}
						{/*}}>*/}
						{/*	<Umbrella color={iconColor}></Umbrella>*/}
						{/*</span>*/}
						<div className='headeritem'
						     style={{
							     color: iconColor,
							     fontSize: isMo1 ? isLandscape ? (isTablet ? '3.5vh' : '5vh') : (isTablet ? '3vh' : '5.5vw') : '3vh',
//							      marginTop: isMo1 ? isLandscape ? (isTablet ? '0.5em' : '1.4em') : (isTablet ? '0.5em' : '-30px') : '0.5em',
							     paddingLeft: isMo1 ? isLandscape ? (isTablet ? '2.5vw' : '2.5vw') : (isTablet ? '2.5vw' : '3.5vw') : '1.5vw',
						     }}
						>
							Geschichten
						</div>
					</div>
					{showTour && selectedStory === -1 && <ShowTourSelector></ShowTourSelector>}

					{ !isMobile && !showTour &&
						<div className='headerbuttons'
						     style={{fontSize: isMo1 ? isLandscape ? '5vh' : '5.5vw' : '2.5vm'}}
						     onClick={() => {handleButton('3D')}}
						>
							<div className='headeritem'
							     style={{
								     color: iconColor,
								     fontSize: isMo1 ? isLandscape ? (isTablet ? '3.5vh' : '5vh') : (isTablet ? '3vh' : '5.5vw') : '3vh',
	//							      marginTop: isMo1 ? isLandscape ? (isTablet ? '0.5em' : '1.4em') : (isTablet ? '0.5em' : '-30px') : '0.5em',
								     paddingLeft: isMo1 ? isLandscape ? (isTablet ? '2.5vw' : '2.5vw') : (isTablet ? '2.5vw' : '3.5vw') : '1.5vw',
							     }}
							>
								3D-Modelle
							</div>
						</div>
					}
					
					
				</a.div>
				<a.div className='footer'
				       style={{
					       left: slide,
					       fontSize: isMo1 ? isLandscape ? (isTablet ? '3.5vh' : '5vh') : (isTablet ? '3vh' : '5.5vw') : '2.5vh'
				       }}>
					<span className='footeritem' ref={footerRef} onClick={() => {handleButton('info')}}
					      style={{color: isOnboarding ? '#FFB92B' : null}}
					>Hilfe</span>
					&nbsp;|&nbsp;
					<span className='footeritem'
					      onClick={() => {handleButton('impressum')}}>Impressum</span>

					{isMobile &&
						<span>
						&nbsp;|&nbsp;
						<span className='footeritem' onClick={() => {handleButton('3D')}}>3D-Modelle</span>
						</span>
					}
					
				</a.div>
				{/* XXX with height -5 hide browserbar on mobile*/}
				{/*<a.div className='threeD' style={{left: slide, height:height-5, width:width}}>*/}
				<a.div className='threeD' style={{
					height: isFullScreen ? '100vh' : '100%',
					left: slide,
//				height:height, width:width   // fixed orientation change bug
				}}>
					{items && <ThreeCanvas></ThreeCanvas>}
					<JumpRelative></JumpRelative>
				</a.div>
			</div>
		</div>
	)
}


export default function App() {

	const {trackPageView, trackEvent} = useMatomo();

	const {height, width} = useWindowDimensions();
	const {screenHeight, screenWidth} = useScreenDimensions();

	const setSeed = useStore(state => state.setSeed);

	const [firstLoad, setFirstLoaded] = useState(true);

	const firstModalCompleted = useStore(state => state.firstModalCompleted);
	const setFirstModalCompleted = useStore(state => state.setFirstModalCompleted);


	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);
	const isMoIOS = useStore(state => state.isMoIOS);

	const setIsMo = useStore(state => state.setIsMobile);
	const setIsMo1 = useStore(state => state.setIsMobile1);
	const setIsMo2 = useStore(state => state.setIsMobile2);
	const setIsLandscape = useStore(state => state.setIsLandscape);
	const setIsMoIOS = useStore(state => state.setIsMoIOS);
	const setIsSafari = useStore(state => state.setIsSafari);

	const modalLeft = useStore(state => state.modalLeft);

	const [moHeight, setMoHeight] = useState(0)

	const transition = useStore(state => state.transition);
	const [delayedTransition, setDelayedTransition] = useState(0);
	const [delay, setDelay] = useState(false);

	const [isFullscreen, setIsFullscreen] = useState(false)

	const isFullScreen = useStore(state => state.isFullScreen);
	const setIsFullScreen = useStore(state => state.setIsFullScreen);

	const isFullScreenAvailable = useStore(state => state.isFullScreenAvailable);
	const setIsFullScreenAvailable = useStore(state => state.setIsFullScreenAvailable);

	const museumSelectOpen = useStore(state => state.museumSelectOpen);

	const dummyRef = useRef();

	useLayoutEffect(() => {
		setSeed(Math.floor(Math.random() * 21 + 2))
//		setSeed(1)
	}, [])

	useLayoutEffect(() => {
//		console.log("display >>>>>>>>>> >>>>>>>>>>>>>>>>>> >>>>>>>>>>>>>>>>>>>>>>>>>>>> >>>>>>>> ");
//		console.log("display >>>>>>>>>> >>>>>>>>>>>>>>>>>> >>>>>>>>>>>>>>>>>>>>>>>>>>>> >>>>>>>> ");
//		console.log("display dimensions changed: >>>>>>>>>> ", height, width, isMobileOnly, isMobile, deviceType)
//		console.log("display landscape mode ", width > height ? 'ON' : 'OFF')
////		console.log("display dummyref ", dummyref.current ? dummyref.current.getBoundingClientRect() : '')
//		console.log("display innerwidth ", window.innerWidth, window.innerHeight)
//		console.log("display window ", window)
//		console.log("display screen ", window.screen)
//		console.log("display body ", document.querySelector('body').getClientRects())

		setIsMo(isMobile ? true : false)
		setIsLandscape(width > height ? true : false)
		if (window.navigator.standalone === false) {
			setIsFullScreenAvailable(false);
		}

		if (!isMobile) {
			setFirstModalCompleted(true)
			setFirstLoaded(false)
		}

		if (isIOS && isMobile) {
//			console.log("+++++++++++++ We are IOS +++++++++++++")
			setIsMoIOS(true);
		}

		if (isSafari) {
//			console.log("+++++++++++++ We are Safari +++++++++++++")
//			alert('safari and IOS->Chrome')
			setIsSafari(true);
		}
		if (isIOS && isChrome) {
//			alert('IOS->Chrome')
			setIsSafari(true);
		}

		setDelay(true)
		setTimeout(() => {
			setDelay(false)
		}, 2000)

	}, [height, width, isMobile])

	const {o} = useSpring({
		from: {o: 1},
		to: {o: transition ? 0 : 1},
		delay: transition ? 200 : 1000,
		config: {duration: transition ? 200 : 800},
	})

	// store isFullscreen also as global in store
	useEffect(() => {
		setIsFullScreen(isFullscreen)
	}, [isFullscreen])

	useEffect(() => {
		if (!isFullScreenAvailable) {
			setFirstModalCompleted(true)
			setFirstLoaded(false)
		}
	}, [isFullScreenAvailable])

	useEffect(() => {
		setTimeout(() => {
			setDelayedTransition(transition)
		}, 500)
	}, [transition])

//	console.log('display screenHeight',screenHeight, screenWidth)

//	useEffect(()=>{
//		if (dummyRef) {
//			setTimeout(()=>{
//				console.log('dummyRef', dummyRef.current ? dummyRef.current.getBoundingClientRect().left : '-')
//				console.log('dummyRef', dummyRef.current ? dummyRef.current.offsetLeft : '-')
//			}, 4500)
//		}
//	}, [transition, isLandscape, modalLeft])

	return (
		<>
			{!isMo || window.navigator.standalone === false ?
				(
					<WrappedApp></WrappedApp>
				) : (
					<ReactFullscreeen moHeight={moHeight}>
						{({ref, onRequest, onExit}) => (
							<div className={'fullscreenwrapper'} id={'full01'} ref={ref}>
								{/*{isMo1 &&*/}
								<a.div className={'fullscreenModal'} ref={dummyRef}
								       style={{
									       width: isLandscape ? width * 0.05 : height * 0.065,
									       height: isLandscape ? width * 0.04 : height * 0.05,
//									       border: '1px solid red',
//									       opacity: 1,
									       opacity: o,
									       overflow: 'hidden',
									       top: isLandscape ?
										       (!delayedTransition ? height * 0.015 : isTablet ? '57vh' : '47vh')
										       :
										       (!delayedTransition ? height * 0.10 : isTablet ? '22vh' : '22vh'),
									       left: isLandscape ?
										       (!delayedTransition ? '93vw' : isTablet ? modalLeft + 40 : modalLeft + 40)
										       :
										       (!delayedTransition ? '84vw' : (isTablet ? modalLeft : '82vw')),
									       pointerEvents: isMo1 && !isLandscape ? museumSelectOpen ? 'none' : 'auto' : 'auto',
								       }}
								       onClick={(e) => {
//									       console.log('fullstreen clicked')
									       trackEvent({
										       category: 'fullscreen ' + (!isFullscreen ? 'on' : 'off'),
										       action: 'click-event'
									       })
									       e.stopPropagation();
									       if (!isFullscreen) {
										       setIsFullscreen(true)
										       return onRequest()
									       } else {
										       setIsFullscreen(false)
										       return onExit()
									       }
								       }}
								>
									{isFullscreen ?
										(<ExitFullScreen
											color={isLandscape ? !delayedTransition ? 'lightgray' : 'white' : 'white'}
										/>)
										:
										(<FullScreen
											color={isLandscape ? !delayedTransition ? '#BD0926' : 'white' : 'white'}
										/>)
									}
								</a.div>
								{firstLoad &&
									<div className={'fulloverlayModal'}>
										<a.div className={'fullscreenModalFirstload'}
										       style={{
											       width: isLandscape ? '40vw' : '80vw',
											       height: isLandscape ? '40vh' : '60vw',
											       backgroundColor: 'black',
											       color: 'white',
											       opacity: o,
											       overflow: 'hidden',
											       top: isLandscape ?
												       (!delayedTransition ? '20vh' : '20vh')
												       :
												       (!delayedTransition ? '15vh' : '15vh'),
											       left: isLandscape ?
												       (!delayedTransition ? '30vw' : '30vw')
												       :
												       (!delayedTransition ? '10vw' : '10vw'),
											       pointerEvents: isMo1 && !isLandscape ? museumSelectOpen ? 'none' : 'auto' : 'auto',
										       }}
										       onClick={(e) => {
//											       console.log('fullstreen clicked')
											       trackEvent({
												       category: !isFullscreen ? 'fullscreen on' : 'fullscreen off',
												       action: 'click-event'
											       })
											       e.stopPropagation();
											       setFirstLoaded(false)
											       setIsFullscreen(true)
											       setFirstModalCompleted(true)
											       return onRequest()
										       }}
										>
											Diese Anwendung funktioniert viel besser im Vollbild-Modus.
											Wollen Sie jetzt gleich in den Vollbild-Modus wechseln?
											<p>
												<button style={{width: '4em'}}
												        onClick={(e) => {
													        e.stopPropagation();
													        trackEvent({
														        category: 'fullscreen off',
														        action: 'click-event'
													        })
//													        console.log('NO fullscreen')
													        setFirstModalCompleted(true)
													        setFirstLoaded(false)
												        }}
												>Nein
												</button>

											</p>
											<p>
												<button
													style={{width: '4em'}}
													onClick={(e) => {
//														console.log('fullstreen clicked')
														trackEvent({
															category: !isFullscreen ? 'fullscreen on' : 'fullscreen off',
															action: 'click-event'
														})
														e.stopPropagation();
														if (!isFullscreen) {
															setFirstLoaded(false)
															setIsFullscreen(true)
															setFirstModalCompleted(true)
															return onRequest()
														} else {
															setFirstLoaded(false)
															setIsFullscreen(false)
															setFirstModalCompleted(true)
															return onExit()
														}
													}}
												>Ja
												</button>
												&nbsp;(empfohlen) oder später mit&nbsp;
												<FullScreen width={'1.2em'}></FullScreen>
											</p>
										</a.div>
									</div>
								}
								{/*}*/}
								<WrappedApp></WrappedApp>
							</div>
						)}
					</ReactFullscreeen>
				)
			}
		</>
	)
}


const ThreeCanvas = () => {

		const isMo1 = useStore(state => state.isMobile1);
		const isMo2 = useStore(state => state.isMobile2);

		const showTour = useStore(state => state.showTour)
		const selectedStory = useStore(state => state.selectedStory);

//		console.log("selstory in canvas", useStore.getState().selectedStory)

		return (
			<Canvas
				//					mode="concurrent"
				linear={true}
				//				camera={{zoom: 1, fov: 30, position: [0, -30, 5], near: 2, far: 2000}}
				camera={{
					zoom: isMo2 ? 1 : 1,
					fov: isMo2 ? 80 : 30,
					position: isMo2 ? [10, 0, 2000] : [0, -30, 5],
					near: 1,
					far: 2000
				}}
				//			gl={{antialias: false, alpha: false}}
				gl={{antialias: true, alpha: false}}
				onCreated={({gl}) => {
					gl.setClearColor('#eeeeee');
				}}>
				<ambientLight regress intensity={1}/>
				<directionalLight regress intensity={0.9} position={[45, 45, 45]}/>
				<pointLight position={[-150, -150, 150]} intensity={0.135}/>
				<fog attach="fog" args={['#888888', 60, 320]}/>
				<color attach="background" args={['#666666']}/>
				{/*<OrbitControls />*/}
				<SwitchColors/>
				<Scene/>
				<Popup/>
				{showTour && selectedStory !== -1 && <Story/>}
				{/*<Effects />*/}
			</Canvas>
		)
	}
//)

const ShowTourSelector = () => {
	
	const {trackPageView, trackEvent} = useMatomo();
	
	const setSelectedStory = useStore(state => state.setSelectedStory);
	
	const setShowTour = useStore(state => state.setShowTour);
	const showTour = useStore(state => state.showTour);
	
	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);
	
	const options = [
		{val: '0', label: "Wer heilt hat Recht", extImage: "I_E.jpg"},
		{val: '1', label: "Was Steine erzählen…", extImage: "II_E.jpg"},
		{val: '2', label: "Von A nach B", extImage: "III_E1_small.jpg"},
		{val: '3', label: "…und retour!", extImage: "III_E2_small.jpg"},
		{val: '4', label: "Lieblingsstücke", extImage: "V_Titelbild_und_Einleitung.jpg"},
		{val: '5', label: "Ab in den Schnee - Ski Alpin!", extImage: "V_Titelbild_und_Einleitung.jpg"},
		//		{val: '1', label: "Story2 ", extImage: "I_E.jpg"},
	]
	
	function handleStorySelect(e) {
		trackEvent({category: 'story selected ' + (e ? e : 0), action: 'click-event'})
		//		console.log("::: e.target.id", e)
		//		setSelectedStory(e.currentTarget.id ? e.currentTarget.id : 0)
		setSelectedStory(e ? e : 0)
	}
	
	function handleClose(e) {
		trackEvent({category: 'story select closed', action: 'click-event'})
		setShowTour(false)
	}
	
	return (
		<div className={'tourContainerMeta'}
		     style = {{
			     position: isMo1 && !isLandscape ? 'absolute' : 'flex',
			     left: isMo1 && !isLandscape ? '32vw' : 'auto',
			     top: isMo1 && !isLandscape ? '7vw' : 'auto',
		     }}
		>
			<div className={'tourContainer'}
			     style={{
				     position: isMo1 && !isLandscape ? 'flex' : 'flex',
				     left: isMo1 && !isLandscape ? 'auto' : 'auto',
				     top: isMo1 && !isLandscape ? 'auto' : 'auto',
				     flexDirection: isMo1 && isLandscape ? 'column' : 'column',
				     width: isMo1 && isLandscape ? '100%' : '100%',
				     flexWrap: isMo1 && isLandscape ? 'no-wrap' : 'no-wrap',
			     }}
			>
				{options.map((d, i) => {
					return (
						<div key={'touritem' + i}>
							<div
								key={'tour' + i}
								className='storySelector'
								key={'selstory' + d.val}
								onClick={() => handleStorySelect(d.val)}
								style={{
									fontSize: isMo1 ? isLandscape ? '4vh' : '2vh' : '2vh',
									top: isMo1 ? isLandscape ? '0.5vh' : '1.5vh' : '0.5vh',
									left: isMo1 ? isLandscape ? '1vw' : '1vw' : '10px',
								}}
							>
								{d.label}
								{/*<ShowExternalImages*/}
								{/*	key={'eimg' + i}*/}
								{/*	filename={d.extImage}*/}
								{/*/>*/}
							</div>
						</div>
					)
				})}
			</div>
			<div className={'closeTour'} onClick={handleClose}
			     style={{
				     marginTop: isMo1 && !isLandscape ? '-1vh' : '-8px',
				     marginLeft: isMo1 ? !isLandscape ? '-0.1vw' : '1.8vw' : '1.1vw',
			     }}
			>&#10005;</div>
		</div>
	)
}

const ShowTourSelector2 = () => {
	const { trackPageView, trackEvent } = useMatomo();
	const containerRef = useRef(null);
	const [showScrollIndicator, setShowScrollIndicator] = useState(true);
	const [isAtBottom, setIsAtBottom] = useState(false);
	
	const setSelectedStory = useStore(state => state.setSelectedStory);
	const setShowTour = useStore(state => state.setShowTour);
	const showTour = useStore(state => state.showTour);
	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const options = [
		{val: '0', label: "Wer heilt hat Recht", extImage: "I_E.jpg"},
		{val: '1', label: "Was Steine erzählen…", extImage: "II_E.jpg"},
//		{val: '2', label: "Von A nach B", extImage: "II_E.jpg"},
//		{val: '3', label: "…und retour!", extImage: "II_E.jpg"},
		{val: '2', label: "Von A nach B", extImage: "III_E1_small.jpg"},
		{val: '3', label: "…und retour!", extImage: "III_E2_small.jpg"},
		{val: '4', label: "Lieblingsstücke", extImage: "V_Titelbild_und_Einleitung.jpg"},
		{val: '6', label: "Ab in den Schnee - Ski Alpin!", extImage: "V_Titelbild_und_Einleitung.jpg"},
//		{val: '1', label: "Story2 ", extImage: "I_E.jpg"},
	]
	
	useEffect(() => {
		if (containerRef.current) {
			const container = containerRef.current;
			setShowScrollIndicator(container.scrollHeight > container.clientHeight);
		}
	}, [options]);
	
	const handleScroll = () => {
		if (containerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
			setIsAtBottom(Math.ceil(scrollTop + clientHeight + 10) >= scrollHeight);
		}
	};
	
	const scrollContent = () => {
		if (containerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
			const scrollAmount = isAtBottom ? -1500 : 150;  // Reverse direction if at bottom
			
			containerRef.current.scrollBy({
				top: scrollAmount,
				behavior: 'smooth'
			});
		}
	};
	
	const handleStorySelect = (e) => {
		trackEvent({ category: 'story selected ' + (e ? e : 0), action: 'click-event' });
		setSelectedStory(e ? e : 0);
	};
	
	const handleClose = (e) => {
		trackEvent({ category: 'story select closed', action: 'click-event' });
		setShowTour(false);
	};
	
	return (
		<div className="tourContainerMeta"
		     style={{
			     position: isMo1 && !isLandscape ? 'absolute' : 'flex',
			     left: isMo1 && !isLandscape ? '32vw' : 'auto',
			     top: isMo1 && !isLandscape ? '7vw' : 'auto',
		     }}
		>
			<div
				style={{
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<div className="tourContainer"
				     ref={containerRef}
				     onScroll={handleScroll}
				     style={{
					     position: isMo1 && !isLandscape ? 'flex' : 'flex',
					     left: isMo1 && !isLandscape ? 'auto' : 'auto',
					     top: isMo1 && !isLandscape ? 'auto' : 'auto',
					     flexDirection: isMo1 && isLandscape ? 'row' : 'column',
					     width: isMo1 && isLandscape ? '60vw' : 'auto',
					     flexWrap: isMo1 && isLandscape ? 'wrap' : 'no-wrap',
					     maxHeight: isMo1 && !isLandscape ? '70vh' : '80vh',
					     overflowY: 'auto',
					     scrollbarWidth: 'none',
					     msOverflowStyle: 'none',
					     marginBottom: showScrollIndicator ? '20px' : '0',
					     '&::-webkit-scrollbar': {
						     display: 'none'
					     }
				     }}
				>
					{options.map((d, i) => (
						<div key={'touritem' + i}>
							<div
								className="storySelector"
								key={'selstory' + d.val}
								onClick={() => handleStorySelect(d.val)}
								style={{
									fontSize: isMo1 ? isLandscape ? '4vh' : '2vh' : '2vh',
									top: isMo1 ? isLandscape ? '0.5vh' : '1.5vh' : '0.5vh',
									left: isMo1 ? isLandscape ? '1vw' : '1vw' : '10px',
								}}
							>
								{d.label}
								<ShowExternalImages
									key={'eimg' + i}
									filename={d.extImage}
								/>
							</div>
						</div>
					))}
				</div>
				
				{showScrollIndicator && (
					<div
						className="scroll-indicator"
						onClick={scrollContent}
						style={{
							position: 'absolute',
							bottom: '-10px',
							left: '50%',
							transform: `translate(-50%, 0) rotate(${isAtBottom ? '180deg' : '0deg'})`,
							cursor: 'pointer',
							padding: '0',
							transition: 'transform 0.3s ease',
							zIndex: 10,
						}}
					>
						<svg
							width="30"
							height="18"
							viewBox="0 0 20 12"
                            className="glowing-indicator"
							style={{
								animation: 'bounce 2s infinite',
								fill: '#FFFFFF',
                filter: 'drop-shadow(0 0 5px rgba(255,255,255,0.7))',
							}}
						>
							<path d="M10 12L0 2L2 0L10 8L18 0L20 2L10 12Z" />
						</svg>
					</div>
				)}
			</div>
			
      <div className="closeTour" onClick={handleClose}
			     style={{
				     marginTop: isMo1 && !isLandscape ? '-1vh' : '-5px',
				     marginLeft: isMo1 ? !isLandscape ? '-2vw' : '-5vw' : '0.5vw',
			     }}
			>
				&#10005;
			</div>
			
			<style>
				{`
          @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(5px); }
          }
          
          @keyframes glow {
            0%, 100% {
              filter: drop-shadow(0 0 5px rgba(255,255,255,0.7));
              opacity: 0.8;
            }
            50% {
              filter: drop-shadow(0 0 9px rgba(255,255,255,0.9));
              opacity: 1;
            }
          }
          
          .glowing-indicator {
            animation: bounce 2s infinite, glow 1.5s ease-in-out infinite;
          }
          
          .tourContainer::-webkit-scrollbar {
            display: none;
          }
        `}
			</style>
		</div>
	);
};


const Itemloader = () => {

	const urlItems = process.env.REACT_APP_REST_PATH + `/cacheditems`;
	const urlMuseums = process.env.REACT_APP_REST_PATH + `/cachedmuseums`;
	const urlMedia3D = process.env.REACT_APP_REST_PATH + `/media3d`;
	const urlTree = process.env.REACT_APP_REST_PATH + `/cachedtree`;

	const setMuseums = useStore(state => state.setMuseums);
	const setItems = useStore(state => state.setItems);
	const setMedia3D = useStore(state => state.setMedia3D);
	const setTree = useStore(state => state.setTree);

	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'text/plain; charset=utf-8');
//	myHeaders.append('Content-Type','application/json; charset=UTF-8');
//	myHeaders.append('Content-Type','application/json; charset=UTF-8');

//	fetch('getrastreiojadlog?cod=10082551688295', myHeaders)

	useEffect(() => {
		const fetcher = async (url) => {
			const response = await fetch(url, myHeaders);
			if (!response.ok) {
				const message = `An error has occured: ${response.status}`;
				throw new Error(message);
			}
			const data = await response.json();
			return data
		}


		fetcher(urlItems).then(items => {
			setItems(items)
		}).catch(error => {
//			console.log("error message catched");
//			console.log(error.message);
			return error.message;
		});


		fetcher(urlMuseums).then(museums => {
			setMuseums(museums)
		}).catch(error => error.message);

		fetcher(urlMedia3D).then(media3D => {
			setMedia3D(media3D)
		}).catch(error => error.message);

		fetcher(urlTree).then(tree => {
//			console.log("+++",tree);
			setTree(tree)
		}).catch(error => error.message);


	}, []);


	return (<></>)
}

function ShowExternalImages(d) {

	const {height, width} = useWindowDimensions();

	if (d.filename) {
		let imagepath = process.env.REACT_APP_PUBLIC_PATH + "/api/assets/cache/orig/all/" + d.filename;
		let dummypath = './nopic.png';

		return (
			<img className={'storyImage'}
			     src={d.filename !== null ? imagepath : dummypath}
				//					width={width/12}
				 width={width / 9}
			></img>
		)
	} else return (<p> xxx</p>)
}

const SplashScreen = () => {

	const initialized3D = useStore(state => state.initialized3D);

	const {height, width} = useWindowDimensions();
	const isMo = useStore(state => state.isMobile);
	const isMo1 = useStore(state => state.isMobile1);
	const isMo2 = useStore(state => state.isMobile2);
	const isLandscape = useStore(state => state.isLandscape);

	const {o} = useSpring({
		from: {o: 0},
		to: {o: 30},
		config: config.molasses,
//		config: {duration: 800},
	})

	const {x} = useSpring({
		from: {x: 1},
		to: {x: initialized3D ? 0 : 1},
		config: config.gentle,
//		config: {duration: 300},
	})

	const zeroPad = (num, places) => String(num).padStart(places, '0')

	return (
		<>
			<div style={{fontSize: '0.7em', margin: '5px'}}>
				v{metadata.version}.b{zeroPad(metadata.build, 3)}, {preval`module.exports = new Date().toLocaleString();`}
			</div>
			<div style={{
				position: 'absolute',
//				width: '30vw',
				width: '100%',
//				left: '38vw',
				margin: 'auto',
//				marginRight: '50%',
				textAlign: 'center',
				top: '25vh',
				fontSize: '2em',
				fontWeight: 100,
				opacity: initialized3D ? 0 : 1,
			}}
			>
				Willkommen bei den
			</div>
			<div className='loaderlogo'
			     style={{
				     position: 'absolute',
//					 margin: 'auto',
				     width: '30vw',
				     left: isMo1 && !isLandscape ? '28vw' : '42vw',
				     top: '40vh',
				     opacity: initialized3D ? 0 : 1,
			     }}
			>
				<img src={'logo_text.png'} style={{width: isMo1 && !isLandscape ? "140%" : "50%"}}></img>

			</div>
			<div className='loader' style={{
				position: 'absolute',
				width: '30vw',
				height: '1vw',
				left: '35vw',
				top: '60vh',
				background: 'white',
				border: '1.2px solid #666666',
				opacity: initialized3D ? 0 : 1,
			}}>
			</div>
			<a.div className='loader' style={{
				position: 'absolute',
				width: o.to(o => initialized3D ? '30vw' : o + 'vw'),
				opacity: x,
				height: '1vw',
				left: '35vw',
				top: '60vh',
//			top: o.to(o => 500-o),
				background: '#666666'
			}}>
				<br></br>
				<h5 style={{
					position: 'relative',
					left: isMo1 && !isLandscape ? '9vw' : '14vw',
					opacity: 1,
					fontWeight: 400,
				}}>{initialized3D ? 'starting' : 'loading'}</h5>
			</a.div>
		</>

	)
}

